import { useNavigate } from 'react-router-dom';

import { PlaybackSettings } from '../../components/PlaybackSettings';
import { VideoReplay } from '../../components/VideoReplay/VideoReplay.kiosk';
import { BackArrowIcon } from '../../components/ui/icons/BackArrow';
import { useGlobalStore } from '../../state/globalStore';
import { typography } from '../../styles/typography.css';
import { fallbackCameraConfig } from '../../utils/fallbacks';
import * as css from './PlaybackScreen.css';
import { useUserSettingsStore } from '../../state/userSettingsStore';
import { UserSettingsContext } from '../../utils/contexts/UserSettingsContext';
import { useContext, useState } from 'react';

import { ComparisonSidebar } from '../../components/ComparisonSidebar';
import { Switch } from '../../components/Switch';
import { AnimatePresence, motion } from 'framer-motion';
import _ from 'lodash';
import { PlaybackDisplay, PlaybackProps } from './PlaybackScreen.types';

export default function Playback({ display }:PlaybackProps) {
    const navigate = useNavigate();
    const [displayMode, setDisplayMode] = useState<PlaybackDisplay>(display ?? 'playback');
    const [activityID, swings, activeSwingID, comparisonSwingID, setComparisonSwingID, allUserFavoriteSwings, activities, activeSwing, comparisonSwing] = useGlobalStore(state => [
        state.actions.getViewingActivity()?.id,
        state.swings,
        state.activeSwingID,
        state.comparisonSwingID,
        state.actions.setComparisonSwingID,
        state.allUserFavoriteSwings,
        state.activities,
        state.actions.getActiveSwing(),
        state.actions.getComparisonSwing()
    ]);

    const userSettingsContext = useContext(UserSettingsContext);

    const {
        userSettings,
        availableTags,
        activeLayout,
        playbackSettings,
    } = useUserSettingsStore();

    const activeSwingAnalysis
        = activeSwing?.fullAnalysis
        ?? activeSwing?.quickAnalysis;

    const comparisonSwingAnalysis
        = comparisonSwing?.fullAnalysis
        ?? comparisonSwing?.quickAnalysis;

    // Get the favorite swings in the current activity
    const currentActivityFavoriteSwings = swings?.filter(s => s.isFavorite);

    // Get all favorite swings that are not already in `currentActivityFavoriteSwings`
    const allFavoriteSwingsFavorites = allUserFavoriteSwings?.filter(
        s => s.isFavorite && !currentActivityFavoriteSwings.some(current => current.id === s.id)
    );

    const onBack = () => {
        userSettingsContext?.setSettingsStore({
            availableTags,
            activeLayout,
            userSettings,
            playbackSettings,
        });

        navigate('/kiosk');
    };

    return (
        <div className={css.root}>
            <div className={css.backNav}>
                <div className={css.backNavLeft}>
                    <button onClick={onBack} className={css.arrowButton}>
                        <BackArrowIcon />
                    </button>
                    <p
                        className={
                            typography({
                                size: 'h2',
                                weight: 'bold',
                                color: 'dark',
                            })
                        }
                    >
                        Playback
                    </p>
                </div>
                <Switch
                    switchKey="playback-display-switch"
                    items={[
                        {
                            title: 'Playback',
                            onClick: () => setDisplayMode('playback'),
                            isSelected: displayMode === 'playback',
                        },
                        {
                            title: 'Comparison',
                            onClick: () => setDisplayMode('comparison'),
                            isSelected: displayMode === 'comparison',
                        },
                    ]}
                />
            </div>
            <div className={css.container}>
                <div className={css.replay}>
                    {activeSwingAnalysis
                        ? (
                            <VideoReplay
                                activeSwingAnalysis={activeSwingAnalysis}
                                comparisonSwingAnalysis={comparisonSwingAnalysis}
                                cameraConfig={fallbackCameraConfig}
                                speed={playbackSettings?.playbackSpeed}
                                showComparison={displayMode === 'comparison'}
                            />
                        )
                        : (
                            <div className={css.videoPlaceholder}>
                                <p
                                    className={
                                        typography({
                                            size: 'h2',
                                            weight: 'bold',
                                            color: 'dark',
                                        })
                                    }
                                >
                                No video found
                                </p>
                            </div>
                        )}
                </div>
                <AnimatePresence mode="wait">
                    {displayMode === 'playback'
                        ? (
                            <motion.div
                                key="playbackSetting"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                style={{ width: '100%' }}
                            >
                                {/* TODO: Playback settings is not connected to anything */}
                                <PlaybackSettings />
                            </motion.div>
                        )
                        : (
                            <motion.div
                                key="comparisonSidebar"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                <ComparisonSidebar
                                    currentActivityId={activityID}
                                    currentActivityFavoriteSwings={currentActivityFavoriteSwings}
                                    otherActivitiesFavoriteSwings={allFavoriteSwingsFavorites}
                                    onNewSwingSelected={(swingId) => {
                                        setComparisonSwingID(swingId);
                                    }}
                                    activities={activities}
                                    activeSwingId={activeSwingID}
                                    initialSelectedComparisonSwingId={comparisonSwingID}
                                />
                            </motion.div>
                        )}
                </AnimatePresence>
            </div>
        </div>
    );
}