import { createContext, type PropsWithChildren, useCallback, useEffect, useState } from 'react';
import type { SupabaseClient } from '@supabase/supabase-js';

import { loginWithQrCode } from '../apiMethods';
import { useGlobalStore } from '../../state/globalStore';
import DataManager from '../../DataManager';
import { SimplifiedCameraConfig } from '../types/camera';
import { fallbackCameraConfig } from '../fallbacks';
import { useBoothSession } from '../hooks/useBoothSession';

type User = {
    id:string;
    email:string;
    shouldChangePassword?:boolean;
    name?:string;
    isAdmin?:boolean;
};

type UpdateUser = {
    email?:string;
    name?:string;
};

type SessionProviderProps = {
    supabaseClient:SupabaseClient;
};

type SessionContextType = {
    user:User | null;
    updateUser({ email, name }:UpdateUser):void;
    cameraConfig?:SimplifiedCameraConfig;
    isLoading:boolean;
    isLoggingIn:boolean;
    isLoggingOut:boolean;
    login(email:string, password:string, boothSessionId?:string):Promise<void>;
    logout():void;
    setShouldChangePassword():Promise<void>;
    changePassword(newPassword:string):Promise<void>;
    isChangingPassword:boolean;
};

export const SessionContext = createContext<SessionContextType | null>({
    user: null,
    updateUser: () => null,
    isLoading: false,
    isLoggingIn: false,
    isLoggingOut: false,
    login: async() => Promise.resolve(),
    logout: async() => Promise.resolve(null),
    setShouldChangePassword: async() => Promise.resolve(),
    changePassword: async() => Promise.resolve(),
    isChangingPassword: false,
});

// Get or create an instance of the data manager.
async function initializeDataManager(supabase:SupabaseClient, user:User, boothSessionId:number) {
    if(!user?.id || !boothSessionId) {
        return;
    }

    const dataManager = await DataManager.initialize({
        supabase,
        userId: user.id,
        boothSessionId,
    });

    if(!dataManager) {
        throw new Error('DataManager could not be initialized');
    }

    return dataManager.cameraConfig ?? fallbackCameraConfig;
}

export const SessionProvider = ({ supabaseClient, children }:PropsWithChildren<SessionProviderProps>) => {
    const [user, setUser] = useState<User | null>(null);
    const [cameraConfig, setCameraConfig] = useState<SimplifiedCameraConfig | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [isChangingPassword, setIsChangingPassword] = useState(false);
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [isLoggingOut, setIsLoggingOut] = useState(false);

    const resetSwings = useGlobalStore((state) => state.actions.resetSwings);
    const { boothSessionDetails, boothSessionUsers, endBoothSession, loginUser, updateUserSession } = useBoothSession();

    const setInitialUser = useCallback(async(checkExisting = true) => {

        if(!boothSessionDetails?.id) {
            return;
        }

        setIsLoading(true);
        const initialSession = await supabaseClient.auth.getSession();
        let userSession = initialSession?.data?.session?.user;

        if(!userSession && boothSessionUsers.length > 0) {
            // set the user session as the first user that joined the booth session
            const { accessToken: access_token, refreshToken: refresh_token } = boothSessionUsers[0]; // first user that joined the session
            const setSessionResponse = await supabaseClient.auth.setSession({ access_token, refresh_token });

            if(!setSessionResponse.error) {
                userSession = setSessionResponse.data.session?.user;
            }
        }

        if(userSession && boothSessionUsers.length === 0 && checkExisting) {
            setIsLoading(false);
            await supabaseClient.auth.signOut();
            return;
        }

        const { id: userId, email, user_metadata } = userSession ?? {};

        if(userId && email) {
            const initialUser = {
                id: userId,
                email,
                shouldChangePassword: user_metadata?.['should_change_password'],
                name: user_metadata?.['name'] ?? email?.split('@')[0],
                isAdmin: user_metadata?.['is_admin'],
            };
            setUser(initialUser);
            setCameraConfig(await initializeDataManager(supabaseClient, initialUser, boothSessionDetails.id));
        } else {
            setUser(null);
        }

        setIsLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [boothSessionDetails, boothSessionUsers]);

    useEffect(() => {
        void setInitialUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [boothSessionUsers.length]);

    useEffect(() => {
        if(isLoading) {
            return;
        }

        const {
            data: { subscription },
        } = supabaseClient.auth.onAuthStateChange(async(event, session) => {
            if(event === 'INITIAL_SESSION') {
                void setInitialUser();
            }

            if(event === 'SIGNED_OUT') {
                setUser(null);
            }

            if(event === 'USER_UPDATED') {
                const { id: userId, email, user_metadata } = session?.user ?? {};

                if(userId && email) {
                    const updatedUser = {
                        id: userId,
                        email,
                        shouldChangePassword: user_metadata?.['should_change_password'],
                        name: user_metadata?.['name'] ?? email?.split('@')[0],
                        isAdmin: user_metadata?.['is_admin'],
                    };
                    setUser(updatedUser);
                }
            }

            if(event === 'TOKEN_REFRESHED') {
                const userId = session?.user.id;

                if(userId && session) {
                    const { access_token: accessToken, refresh_token: refreshToken } = session ?? {};
                    await updateUserSession(userId, accessToken, refreshToken);
                }
            }
        });

        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [supabaseClient.auth]);

    const handleLogin = async(email:string, password:string, sessionUid?:string) => {
        setIsLoggingIn(true);

        if(sessionUid) {
            const { success: qrLoginSuccess, data: qrLoginData } = await loginWithQrCode(email, password, sessionUid);
            const { boothSessionUserId } = qrLoginData;

            if(!qrLoginSuccess || !boothSessionUserId) {
                setIsLoggingIn(false);
                throw new Error('Error logging in to session');
            }

            const { accessToken: access_token, refreshToken: refresh_token } = qrLoginData.tokens;
            await supabaseClient.auth.setSession({ access_token, refresh_token });

            return;
        }

        const { success, data, error } = (await loginUser(email, password)) ?? { success: false };

        if(!success) {
            setIsLoggingIn(false);
            throw new Error(error ?? 'Error logging in');
        }

        const { accessToken: access_token, refreshToken: refresh_token } = data.tokens;
        await supabaseClient.auth.setSession({ access_token, refresh_token });

        if(!user) {
            await setInitialUser(false);
        }

        setIsLoggingIn(false);
    };

    const handleLogout = async() => {
        setIsLoggingOut(true);

        await endBoothSession();

        DataManager.disconnect();
        resetSwings();

        await supabaseClient.auth.signOut();

        setIsLoggingOut(false);
    };

    const handleSetShouldChangePassword = async() => {
        if(!user) {
            throw new Error('User not authenticated');
        }

        const { error } = await supabaseClient.auth.updateUser({
            data: { should_change_password: true },
        });

        if(error) {
            throw new Error('Error setting should change password');
        }

        await supabaseClient.auth.signOut();
    };

    const handleChangePassword = async(newPassword:string) => {
        setIsChangingPassword(true);

        if(!user) {
            setIsChangingPassword(false);
            throw new Error('User not authenticated');
        }

        const { error: changePasswordError } = await supabaseClient.auth.updateUser({
            password: newPassword,
            data: { should_change_password: false },
        });

        if(changePasswordError) {
            setIsChangingPassword(false);
            throw new Error(changePasswordError.message ?? 'Error changing password');
        }

        setUser({ ...user, shouldChangePassword: false });
        setIsChangingPassword(false);
    };

    const handleUpdateUser = ({ email, name }:UpdateUser) => {
        void supabaseClient.auth.updateUser({ email: email ?? user?.email, data: { is_admin: user?.isAdmin, name: name ?? user?.name } });
    };

    return (
        <SessionContext.Provider
            value={{
                user,
                updateUser: handleUpdateUser,
                cameraConfig,
                isLoading,
                isLoggingIn,
                isLoggingOut,
                login: handleLogin,
                logout: handleLogout,
                setShouldChangePassword: handleSetShouldChangePassword,
                changePassword: handleChangePassword,
                isChangingPassword,
            }}
        >
            {children}
        </SessionContext.Provider>
    );
};
