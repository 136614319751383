import { useEffect, useLayoutEffect, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { SupabaseClient } from '@supabase/supabase-js';
import { shallow } from 'zustand/shallow';
import cn from 'classnames';

import Navigation from '../components/Navigation';
import { lightThemeColors } from '../styles/colors.css';
import { useUIComponentValues, useGlobalStore } from '../state/globalStore';
import UIComponentsLayout from '../components/UIComponentsLayout';
import Scrubber from '../components/Scrubber/Scrubber';
import {
    type SocketPayload,
    useSyncSocketStore,
} from '../state/syncSocketStore';
import { useCommunicationSocketStore } from '../state/communicationSocketStore';
import { useUserSettingsStore } from '../state/userSettingsStore';
import { getActivityClub, getActivityTags } from '../utils/dbFunctions';
import TopBanner from '../components/TopBanner';

import * as css from './screens.css';
import OldActivityTopBar from '../components/OldActivityTopBar';

type SyncMessage = [
    SocketPayload['activeLayout'],
    SocketPayload['userSettings'],
    SocketPayload['playbackSettings'],
    SocketPayload['activityClub'],
    SocketPayload['activityTags'],
    SocketPayload['activityId'],
];

type KioskProps = {
    supabase:SupabaseClient;
};

export default function KioskScreen({
    supabase,
}:KioskProps) {
    
    document.title = 'Kiosk';

    const actions = useGlobalStore((state) => state.actions); // TODO: stop using global store
    const activityId = useUserSettingsStore((state) => state.activityId);
    const activities = useGlobalStore(s => s.activities);
    const viewingActivityId = useGlobalStore(s => s.viewingActivityId);
    
    const [setActivityClub, setActivityTags] = useUserSettingsStore((state) => [
        state.actions.setActivityClub,
        state.actions.setActivityTags,
    ]);
    
    const [activeLayout, uiNodes] = useUserSettingsStore((state) => [state.activeLayout, state.uiNodes]);
    
    const userSettingsLoaded = activeLayout && uiNodes;
        
    const currentActivity = activities?.find((a) => a.id === activityId);

    const isViewingOldActivity = viewingActivityId !== activityId;

    useEffect(() => {
        // TODO: Move this from effect.

        const getClubAndTags = async() => {
            if(activityId) {
                const activityClub = await getActivityClub(
                    supabase,
                    activityId,
                );
                if(activityClub) {
                    setActivityClub(activityClub);
                }
                const activityTags = await getActivityTags(
                    supabase,
                    activityId,
                );
                if(activityTags) {
                    setActivityTags(activityTags);
                }
            }
        };

        getClubAndTags();
    }, [activityId, setActivityClub, setActivityTags, supabase]);

    const sendSyncMessage = useSyncSocketStore(
        (state) => state.actions.sendSyncMessage,
    );

    const ref = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {

        if(ref.current) {
            actions.setOutletElement(ref);
        }

    }, [ref, actions]);

    /**
     * Used here to be able to display the "Reconnecting... " message for the user
     */
    const isConnected = useCommunicationSocketStore(
        (state) => state.isConnected,
    );


    // Every piece of Kiosk state that should be synced with the floor.
    useEffect(() => {
        const unsubscribe = useUserSettingsStore.subscribe(
            (state) =>
                [
                    state.activeLayout,
                    state.userSettings,
                    state.playbackSettings,
                    state.activityClub,
                    state.activityTags,
                    state.activityId,
                ] as SyncMessage,
            ([
                activeLayout,
                userSettings,
                playbackSettings,
                activityClub,
                activityTags,
                activityId,
            ]:SyncMessage) => {
                if(typeof sendSyncMessage === 'function') {
                    sendSyncMessage({
                        activeLayout,
                        userSettings,
                        playbackSettings,
                        activityClub,
                        activityTags,
                        activityId,
                    });
                }
            },
            {
                fireImmediately: true,
                equalityFn: shallow,
            },
        );

        return () => {
            unsubscribe();
        };
    }, [sendSyncMessage]);

    const location = useLocation();

    // TODO: Even /kiosk/ causes this to be false
    const hasChildRoutes = location.pathname !== '/kiosk';
    
    const values$ = useUIComponentValues();

    return (
        <div className={css.root}>
            {/* Banner */}
            <TopBanner
                message={'Disconnected, trying to reconnect...'}
                isVisible={!isConnected}
            />

            {isViewingOldActivity && <OldActivityTopBar activity={currentActivity}/>}

            <div className={cn([css.screen, lightThemeColors])}>
                <Navigation/>
                {userSettingsLoaded && (
                    <UIComponentsLayout
                        uiNodeTree={uiNodes}
                        layout={activeLayout}
                        currentDevice="kiosk"
                        values$={values$}
                    />
                )}
                <Scrubber />
            </div>

            {/* Render Child routes inside an outlet, so we don't unmount this component */}
            {hasChildRoutes && (
                <div className={css.outlet} ref={ref}>
                    <Outlet />
                </div>
            )}
        </div>
    );
}
