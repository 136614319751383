import { Dialog, DialogDismiss, DialogHeading } from '@ariakit/react';
import { lightThemeColors } from '../../styles/colors.css';
import cn from 'classnames';
import * as css from './UserSettings.css';
import { AnimatePresence, motion } from 'framer-motion';
import { CircularClose } from '../../components/ui/icons/CircularClose';
import { typography } from '../../styles/typography.css';

export function PasswordModal({
    modalOpen,
    setModalOpen,
    onConfirm,
    error,
}:{
    modalOpen:boolean;
    setModalOpen:(open:boolean) => void;
    onConfirm:() => void;
    error?:string;
}) {
    return (
        <Dialog
            open={modalOpen}
            onClose={() => {
                setModalOpen(false);
            }}
            className={cn([lightThemeColors, css.modal])}
            backdrop={
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    style={{
                        backgroundColor: 'rgb(0,0,0,0.3)',
                    }}
                />
            }
            render={
                <motion.div
                    initial={{ opacity: 0, scale: 0.95 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.95 }}
                />
            }
        >
            <div className={css.modalTopBottom}>
                <DialogHeading
                    className={typography({
                        size: 'h3',
                        weight: 'bold',
                        color: 'dark',
                    })}
                >
          Change password
                </DialogHeading>
                <DialogDismiss>
                    <CircularClose width={24} />
                </DialogDismiss>
            </div>

            <p className={typography({ size: 'paragraph', weight: 'regular' })}>
        Are you sure you want to change your password?
            </p>

            <p className={typography({ size: 'paragraph', weight: 'regular' })}>
        For security reasons, you will be logged out and required to change your
        password upon logging back in.
            </p>

            <AnimatePresence>
                {error && (
                    <motion.p
                        className={typography({
                            size: 'h4',
                            weight: 'bold',
                            lineHeight: '1.2',
                            color: 'red',
                            case: 'uppercase',
                            tracking: 'positiveLarge',
                        })}
                        style={{ whiteSpace: 'nowrap' }}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        {error}
                    </motion.p>
                )}
            </AnimatePresence>

            <div className={css.modalTopBottom}>
                <DialogDismiss className={css.cancelButton}>Cancel</DialogDismiss>

                <button className={css.saveButton} onClick={onConfirm}>
          Confirm
                </button>
            </div>
        </Dialog>
    );
}
