import _ from 'lodash';
import { Lego as LegoIcon } from '@phosphor-icons/react';
import { useContext,  useMemo, useState } from 'react';

import { localize, StaticL10nID, hasChildOfType, hasParentOfType, inferSwingPosition, UINodeToggler, Layout, Mutable, Nil, SwingPosition, UINodeRelation, UINodeTree, UIParameter, UISwingFoundations, swingPositionToPositionNumber, getLocalizedSwingPositionName } from '@common';
import { Button, Modal, colors, typography } from '@common/ui';
import { useUIComponentValues } from '../../../state/globalStore';
import { Parameter } from '../../../components/UIComponents/implementations/Parameter/Parameter';
import { UserSettingsContext } from '../../../utils/contexts';

import * as css from './KeyParameterEditor.css';

//! REFACTOR functionality shared between SwingFoundationsEditor, CustomModuleEditor and KeyParameterEditor

interface KeyParameterEditorProps {
	layout:Layout;
    uiNodeTree:UINodeTree|Nil;
    isOpen:boolean;
    onClose:() => void;
}

export function KeyParameterEditor({
    layout,
    uiNodeTree,
    isOpen,
    onClose,
}:KeyParameterEditorProps) {
    const initialParameterNodeID = useMemo(() => _.filter(
        layout.customizations,
        c => hasParentOfType(c, 'activity_navigation')
        && hasChildOfType(c, 'parameter')
    )?.[0].child_ui_node_id ?? '', [layout.customizations]);

    const { updateUserLayout } = useContext(UserSettingsContext);

    const [parameterNodeID, setParameterNodeID] = useState(initialParameterNodeID);

    const parameterTogglerGroups = useMemo(() => {
        const swingFoundationNodes = _(uiNodeTree?.nodes ?? [])
            .filter(c => c.type === 'swing_foundations')
            .keyBy(n => inferSwingPosition(n.id) || 'all')
            .value() as { [key in SwingPosition|'all']:UISwingFoundations };

        const parameters = _(uiNodeTree?.relations ?? [])
            .concat((layout?.customizations as UINodeRelation[]) ?? [])
            .filter(r => _.some(swingFoundationNodes, n => n.id === r.parent_ui_node_id))
            .uniqBy(r => r.child_ui_node_id)
            .map(r => _.find(uiNodeTree?.nodes, n => n.id === r.child_ui_node_id))
            .filter(n => n?.type === 'parameter')
            .map(node => {
                const position = inferSwingPosition(node?.id) ?? 'p1';

                const togglers = _(
                    [
                        swingFoundationNodes[position],
                        swingFoundationNodes.all,
                    ])
                    .compact()
                    .map(parentNode =>
                ({
                    layout,
                    parentNode,
                    childNode: node,
                    currentDevices: [...parentNode.show_on],
                    defaultChildMetadata: {},
                    isToggledOnByDefault: true,
                }) as UINodeToggler
                    )
                    .value();

                return togglers.length
                    ? { ...node, position } as UIParameter & {position:string}
                    : null;
            })
            .compact()
            .value();

        // Group by position name
        const positionGroups = _(parameters)
            .groupBy(p => p.position)
            .map((positionParameters, position) => ({
                position,
                positionNumber: swingPositionToPositionNumber(position as SwingPosition),
                parameters: _(positionParameters)
                    .sortBy(p => p.name?.value)
                    .value()
            }))
            .sortBy(p => p.positionNumber)
            .value();

        return positionGroups;
    }, [layout.id]); // eslint-disable-line react-hooks/exhaustive-deps

    const getCategorizedParameters = (
        parameters:UIParameter[],
        groupBy:keyof UIParameter['categories'],
    ) => _(parameters)
        .groupBy(node => node.categories[groupBy])
        .map((params, categoryID) => ({
            category: _.find(uiNodeTree?.categories, c => c.id === categoryID) || null,
            parameters: params
        }))
        .sortBy(group => group.category?.display_order ?? Infinity)
        .value();

    const handleSave = async() => {
        const layoutBeingEdited = _.cloneDeep(layout) as Mutable<Layout>;

        const [customization] = _.filter(
            layoutBeingEdited.customizations,
            c => hasParentOfType(c, 'activity_navigation')
                && hasChildOfType(c, 'parameter')
        );

        if(customization) {
            layoutBeingEdited.customizations = _.map(
                layoutBeingEdited.customizations,
                c => c === customization
                    ? { ...customization, child_ui_node_id: parameterNodeID }
                    : c
            );
        }

        await updateUserLayout(layoutBeingEdited);
        onClose();
    };

    const values$ = useUIComponentValues();

    if(!isOpen) {
        return null;
    }

    return (
        <Modal
            title="Edit Key Parameter"
            onDismiss={onClose}
            footer={{
                primary: (
                    <Button
                        variant="primary"
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                ),
                secondary: (
                    <Button
                        variant="secondary"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                ),
            }}
        >

            {parameterTogglerGroups && _.map(parameterTogglerGroups, ({position, parameters}) => (
                <div key={position} className={css.position}>
                    <div className={css.positionTop}>
                        <div className={css.positionTitle}>
                            <LegoIcon size={24} color={colors.blue[600]} />
                            <p
                                className={typography({
                                    variant: 'h3',
                                })}
                            >
                                {getLocalizedSwingPositionName(position as SwingPosition)}
                            </p>
                        </div>
                    </div>

                    {_.map(getCategorizedParameters(parameters, 'swing_foundation_group'), ({ category, parameters: categorizedParams }) => (
                        <div key={category?.id || 'other'} className={css.group}>
                            <h4 className={typography({variant:'h3'})}>
                                {category?.name?.value || 'Other'}
                            </h4>
                
                            <div className={css.parameters}>
                                {_.map(categorizedParams, (node) => (
                                    uiNodeTree && (
                                        <Parameter
                                            key={node.id}
                                            node={node}
                                            values$={values$}
                                            currentDevice="kiosk"
                                            children={[]}
                                            uiNodeTree={uiNodeTree}
                                            onClick={() => setParameterNodeID(node.id)}
                                            isSelected={node.id === parameterNodeID}
                                        />
                                    )
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </Modal>
    );

}
