/* eslint-disable no-restricted-syntax */
import {
    motion,
    useMotionValue,
    useTransform,
    useMotionValueEvent,
} from 'framer-motion';
import {
    useCallback,
    useMemo,
    useRef,
    useState,
    useLayoutEffect,
} from 'react';

import { useResizeObserver } from '../../utils/hooks/useResizeObserver';
import { useGlobalStore } from '../../state/globalStore';
import { ScrubberHandle } from '../ui/icons/ScrubberHandle';
import { calculateFrameRatios } from '../../utils/calculateFrameRatios';

import * as css from './PlaybackScrubber.css';
import { CaretLeft } from '../ui/icons/CaretLeft';
import { PlayPausePaused, PlayPausePlaying } from '../ui/icons/PlayPause';
import { CaretRight } from '../ui/icons/CaretRight';
import _ from 'lodash';
import { PropTypes } from './PlaybackScrubber.types';
import { findCommonPPositions, findNextPPosition, generatePositionTicks, getSwingFramesInfo } from './helpers';

const CAMERA_FRAMERATE = 250;

export default function PlaybackScrubber({
    speed,
    activeSwingAnalysis,
    comparisonSwingAnalysis
}:PropTypes) {
    // Determines if the videos are playing
    const [isPlaying, setIsPlaying] = useState(false);
    const actions = useGlobalStore((state) => state.actions);
    
    const rafRef = useRef<number | null>(null);
    const ref = useRef<HTMLDivElement>(null);
    const initialRender = useRef(true);
    const startTimeRef = useRef<number | null>(null);
    const isProgrammaticChange = useRef(false);
    
    const handleX = useMotionValue(0);
    
    const { width = 920 } = useResizeObserver({
        ref,
        box: 'border-box',
    });

    // Hold the current state of scrubber as a val from 0 to 1
    const currentScrubberRatio = _.clamp(handleX.get() / width, 0, 1);

    const activeSwingInfo = getSwingFramesInfo(activeSwingAnalysis);
    const comparisonSwingInfo = getSwingFramesInfo(comparisonSwingAnalysis);

    const animate = useCallback(
        (time:number) => {
            if(startTimeRef.current === null) {
                startTimeRef.current = time;
            }
    
            const elapsed = time - startTimeRef.current;
    
            // Retrieve totalFrames and frameRate
            const totalFrames = activeSwingInfo?.totalFrames || 1;
    
            const totalDuration = (totalFrames / CAMERA_FRAMERATE) * 1000; // Total duration in ms
            const adjustedDuration = totalDuration / speed;
    
            let currentProgress = elapsed / adjustedDuration;
    
            if(currentProgress >= 1) {
                currentProgress = 0;
                startTimeRef.current = time;
            }
    
            handleX.set(currentProgress * width);
    
            rafRef.current = requestAnimationFrame(animate);
        },
        [handleX, width, speed, activeSwingInfo]
    );

    // Find common P positions only if comparison swing info is available
    const commonPPositions = findCommonPPositions(
        activeSwingInfo?.availablePPositions,
        comparisonSwingInfo?.availablePPositions
    );

    const playbackTrackProgress = useTransform(handleX, [0, width], [0, 1]);

    const activeSwingFrameRatios = useMemo(() => {
        const totalFrames = activeSwingInfo?.totalFrames;
        const segmentation = Object.values(activeSwingInfo?.segmentation ?? {});
        return totalFrames
            ? calculateFrameRatios(segmentation, totalFrames)
            : null;
    }, [activeSwingInfo?.segmentation, activeSwingInfo?.totalFrames]);
    
    const comparisonSwingFrameRatios = useMemo(() => {
        if(!comparisonSwingInfo) return null;
        const totalFrames = comparisonSwingInfo?.totalFrames;
        const segmentation = Object.values(comparisonSwingInfo?.segmentation ?? {});
    
        return totalFrames
            ? calculateFrameRatios(segmentation, totalFrames)
            : null;
    }, [comparisonSwingInfo?.segmentation, comparisonSwingInfo?.totalFrames]);

    /**
     * Colored ticks on the scrubber
     */
    const ticks = generatePositionTicks(activeSwingFrameRatios ?? {}, comparisonSwingFrameRatios);

    
    /**
     * Plays the videos from the current scrubber position
     */
    const play = useCallback(() => {
        if(isPlaying) return;
        
        const totalFrames = activeSwingInfo?.totalFrames || 1;
        
        const totalDuration = (totalFrames / CAMERA_FRAMERATE) * 1000; // Total duration in ms
        const elapsed = currentScrubberRatio * totalDuration;  // Calculate elapsed time based on scrubber position

        // Set start time based on scrubber position (after dragging)
        startTimeRef.current = performance.now() - elapsed;
        
        rafRef.current = requestAnimationFrame(animate);  // Start animation
        
        setIsPlaying(true);
    }, [animate, currentScrubberRatio, speed, isPlaying]);

    /**
     * Pauses the videos
     */
    const pause = useCallback(() => {
        if(!isPlaying) return;
        
        if(rafRef.current !== null) {
            cancelAnimationFrame(rafRef.current);
        }

        setIsPlaying(false);
    }, [isPlaying]);

    /**
     * This should jump to the P position of both swings
     */
    const jumpToSpecificPPosition = (position:number) => {
        const activeSwingPPositionFrame = activeSwingInfo?.numericSegmentationMap?.[position];
        const comparisonSwingPPositionFrame = comparisonSwingInfo?.numericSegmentationMap?.[position];
        const activeSwingTotalFrames = activeSwingInfo?.totalFrames ?? 1;
        
        const comparisonSwingTotalFrames = comparisonSwingInfo?.totalFrames ?? 1;

        // Set the progress for the active swing if available
        if(activeSwingPPositionFrame != null) {
            const activeSwingProgress = activeSwingPPositionFrame / activeSwingTotalFrames;
            actions.setReplayProgress(activeSwingProgress);
            
            // Indicate that we are changing the scrubber programmatically
            isProgrammaticChange.current = true;
            handleX.set(activeSwingProgress * width);
            isProgrammaticChange.current = false;
        }

        if(comparisonSwingPPositionFrame != null) {
            actions.setComparisonProgress(~~comparisonSwingPPositionFrame / comparisonSwingTotalFrames);
        }
    };

    const jupUpOrDown = (direction:'up'|'down') => {
        if(activeSwingFrameRatios) {
            const nextPositionDown = findNextPPosition(commonPPositions, currentScrubberRatio, activeSwingFrameRatios, direction);
            if(nextPositionDown) {
                jumpToSpecificPPosition(nextPositionDown);
            }
        }
    };

    // Called when user scrubs the scrubber
    const handleScrub = useCallback(() => {
        if(isProgrammaticChange.current) return;
        // 1. Calculate the current frame of the active swing
        const activeSwingTotalFrames = activeSwingInfo?.totalFrames || 0;
        const activeSwingCurrentFrame = Math.floor(currentScrubberRatio * activeSwingTotalFrames);
        actions.setReplayProgress(~~activeSwingCurrentFrame / activeSwingTotalFrames);
        
        // 2. Calculate the current frame of the comparison swing
        const comparisonSwingTotalFrames = comparisonSwingInfo?.totalFrames || 0;
        const comparisonSwingCurrentFrame = Math.floor(currentScrubberRatio * comparisonSwingTotalFrames);
        actions.setComparisonProgress(~~comparisonSwingCurrentFrame / comparisonSwingTotalFrames);
    },
    [actions, comparisonSwingInfo]
    );

    // Monitor when user scrubs the scrubber
    useMotionValueEvent(handleX, 'change', handleScrub);

    useLayoutEffect(() => {
        initialRender.current = false;
        return () => {
            initialRender.current = true;
            if(rafRef.current !== null) {
                cancelAnimationFrame(rafRef.current);
            }
        };
    }, []);

    return (
        <div className={css.root}>
            <div className={css.buttonBar}>
                <div className={css.controls}>
                    <button onClick={() => jupUpOrDown('down')} className={css.whiteCircle}>
                        <CaretLeft />
                    </button>
                    <button
                        onClick={isPlaying
                            ? pause
                            : play}
                        className={css.whiteCircle}
                    >
                        {isPlaying
                            ? <PlayPausePlaying />
                            : <PlayPausePaused />}
                    </button>
                    <button onClick={() => jupUpOrDown('up')} className={css.whiteCircle}>
                        <CaretRight />
                    </button>
                </div>
                <div className={css.controls}>
                    {_.map(commonPPositions, (position, index) => (
                        <button key={index} onClick={() => jumpToSpecificPPosition(position)} className={css.whiteCircle}>
                            {`P${position}`}
                        </button>
                    ))}
                </div>
            </div>
            <div className={css.scrubberWrap}>
                <div className={css.positioner} ref={ref}>
                    <motion.div
                        className={css.handle}
                        drag="x"
                        dragConstraints={{ left: 0, right: width }}
                        dragElastic={false}
                        dragMomentum={false}
                        dragDirectionLock
                        style={{ x: handleX }}
                    >
                        <ScrubberHandle />
                    </motion.div>
                    <div className={css.scrubber}>
                        <div className={css.scrubberContent}>
                            {ticks}
                        </div>
                    </div>
                    <div className={css.progressMask}>
                        <motion.div
                            className={css.progress}
                            style={{ scaleX: playbackTrackProgress }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}