import _ from 'lodash';
import { Corridor } from '@common';
import { SupabaseService } from './services/supabase.service';

export class CorridorsEndpointPlaceholder {

    constructor(
        private readonly _supabase:SupabaseService
    ) { }

    /**
     * Endpoint for getting all publicly available corridors
     */
    async getAllCorridors():Promise<Corridor[]> {

        return [
            {
                id: 1,
                name: {
                    'en-US': 'Default Corridor',
                    value: 'Default Corridor',
                },
                parameter_margins: _.mapValues(
                    {
                        feet_alignment: [0, 8],
                        knees_alignment: [0, 8],
                        pelvis_rotation_p1: [0, 8],
                        shoulder_mid_rotation_p1: [2, 16],
                        pelvis_bend_p1: [26, 40],
                        head_bend_p1: [35, 55],
                        right_elbow_angle_p1: [170, 180],
                        right_knee_angle_p1: [120, 160],
                        pelvis_tilt_p1: [0, 3],
                        shoulder_mid_tilt_p1: [7, 18],
                        head_tilt_p1: [-3, 10],
                        feet_distance_from_ball_p1: [50, 85],
                        feet_ball_position_ratio_p1: [50, 65],
                        arm_hang_p1: [0, 10],
                        distance_between_feet_p1: [30, 60],
                        shoulder_mid_rotation_p2: [-40, -15],
                        pelvis_rotation_p2: [-30, -10],
                        head_rotation_p2: [-15, -5],
                        pelvis_sway_p2: [-5, 0],
                        pelvis_thrust_p2: [-2, 1],
                        pelvis_lift_p2: [-2, 0],
                        shoulder_mid_sway_p2: [-5, 0],
                        shoulder_mid_thrust_p2: [-2, 3],
                        shoulder_mid_lift_p2: [-3, 3],
                        head_sway_p2: [-3, 0],
                        head_thrust_p2: [-2, 2],
                        head_lift_p2: [-2, 1],
                        right_wrist_sway_p2: [-50, -20],
                        right_wrist_thrust_p2: [-10, 0],
                        right_wrist_lift_p2: [2, 13],
                        shoulder_mid_rotation_p4: [-85, -100],
                        pelvis_rotation_p4: [-30, -48],
                        head_rotation_p4: [-20, -40],
                        pelvis_sway_p4: [-1.5, 1.5],
                        pelvis_thrust_p4: [-5, 5],
                        pelvis_lift_p4: [-3, 0],
                        shoulder_mid_sway_p4: [-10, 3],
                        shoulder_mid_thrust_p4: [0, 10],
                        shoulder_mid_lift_p4: [-10, 0],
                        head_sway_p4: [-7, -2],
                        head_thrust_p4: [-2, 2],
                        head_lift_p4: [-2.5, 1],
                        right_wrist_sway_p4: [-50, -10],
                        right_wrist_thrust_p4: [-35, -15],
                        right_wrist_lift_p4: [65, 90],
                        shoulder_mid_rotation_p7: [30, 55],
                        pelvis_rotation_p7: [35, 55],
                        head_rotation_p7: [10, 40],
                        pelvis_sway_p7: [11, 14],
                        pelvis_thrust_p7: [-2, 2],
                        pelvis_lift_p7: [-5, 5],
                        shoulder_mid_sway_p7: [2, 9],
                        shoulder_mid_thrust_p7: [-5, 5],
                        shoulder_mid_lift_p7: [-3, 3],
                        head_sway_p7: [-2, 3],
                        head_thrust_p7: [-2, 2],
                        head_lift_p7: [-8, 1],
                    },
                    (([min, max]) => ({
                        outer_min: min,
                        inner_min: min,
                        inner_max: max,
                        outer_max: max,
                    }))
                ),
            },
        ];
    }
}
