import { ReactNode } from 'react';

import { L10n, Nil } from '@common';
import { typography } from '@common/ui';

import * as css from './ModuleBase.floor.css';

// This file shouldn't even exist, it should be part of UIComponents/implementations/Module.tsx file
export default function ModuleBase({ title, subtitle, children }:{ title?:L10n|Nil; subtitle?:L10n|Nil; children:ReactNode }) {
    return (
        <div className={css.root}>
            <div className={css.top}>
                <h3
                    className={typography({
                        variant: 'h2'
                    })}
                >
                    {title?.value ?? 'Module'}
                </h3>
                {subtitle && (
                    <p className={typography({variant: 'h3'})}>{subtitle?.value}</p>
                )}
            </div>
            <div className={css.body}>{children}</div>
        </div>
    );
}
