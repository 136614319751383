import _ from 'lodash';
import { map, distinctUntilChanged } from 'rxjs/operators';

import { deepComparison, removeSwingPosition, Swing, UIParameter, Unit } from '@common';
import { ParameterRenderer } from '@common/ui';
import { ImplementationOf, UIComponentValues } from '../../UIComponent.types';
import { useObservable } from '../../../../utils/hooks/useObservable';

import * as floorCSS from './Parameter.floor.css';

export function getParameterValue(analysis:Swing | undefined, parameterID:string):number | undefined {
    return analysis?.fullAnalysis?.data?.analysis?.parameter_values?.[parameterID]?.value
        ?? analysis?.quickAnalysis?.data?.analysis?.parameter_values?.[parameterID]?.value
        ?? undefined;
}

function calculateParameterMean(allAnalyses:Swing[], parameterID:string):number {
    return _.meanBy(allAnalyses, analysis => getParameterValue(analysis, parameterID) ?? 0);
}

function calculateParameterStandardDeviation(allAnalyses:Swing[], parameterID:string):number {
    const values = _.map(allAnalyses, analysis => getParameterValue(analysis, parameterID) ?? 0);
    const mean = _.mean(values);

    const squaredDifferences = _.map(values, value => {
        const difference = value - mean;
        return difference * difference;
    });

    // since it's population standard deviation
    // and not sample standard deviation
    // variance = (sum / N) instead of (sum / n-1)
    const variance = _.mean(squaredDifferences);

    return Math.sqrt(variance);
}

export type ParameterExtraProps = {
    onClick?:(node:UIParameter) => void;
    isSelected?:boolean;
};

export const Parameter:ImplementationOf<'parameter', ParameterExtraProps>
= ({ node, values$, currentDevice, onClick, isSelected }) => {
    const parameterName = node.name?.value ?? node.id;
    const parameterID = node.parameter.id;
    const parameterUnit = node?.parameter?.unit as Unit;
    let mean = 0;
    let std = 0; // Standard deviation

    const title = node.metadata.hide_position_in_name
        ? removeSwingPosition(parameterName)
        : parameterName;

    // Get the latest swing or the active swing depending on which device this is.
    const getSwing = (values:UIComponentValues) => currentDevice === 'floor' && typeof values.allSwings !== 'undefined'
        ? values.allSwings[values.allSwings.length - 1]
        : values?.swing;

    const [allAnalyses, parameterValue, comparisonParameterValue] = useObservable(
        values$,
        map(values => [
            values.allSwings,
            getParameterValue(getSwing(values), parameterID),
            getParameterValue(values?.comparisonSwing, parameterID),
        ] as const),
        distinctUntilChanged(deepComparison)
    );

    const parameterCorridorMargins = useObservable(
        values$,
        map(values => {
            const parameterMarginsMap = values?.corridorPerDevice?.[currentDevice]?.parameter_margins;

            if(!parameterMarginsMap) {
                return undefined;
            }

            return parameterMarginsMap[parameterID] || null;
        }),
        distinctUntilChanged(deepComparison)
    );

    if(!node.parameter) {
        return <> </>;
    }

    // Don't render if no unit
    if(_.isNil(parameterUnit)) {
        return <> </>;
    }
        
    if(allAnalyses) {
        mean = calculateParameterMean(allAnalyses, parameterID);
        std = calculateParameterStandardDeviation(allAnalyses, parameterID);
    }

    return (
        <ParameterRenderer
            corridorParameterMargins={parameterCorridorMargins}
            value={parameterValue}
            title={title}
            unit={parameterUnit}
            statistics={{ mean, std }}
            isFixedWidth={currentDevice === 'floor'}
            cssOverrides={currentDevice === 'floor'
                ? floorCSS
                : undefined
            }
            onClick={onClick
                ? () => onClick(node)
                : undefined
            }
            isSelected={isSelected}
            comparisonValue={comparisonParameterValue}
        />
    );
};

