/* eslint-disable no-restricted-syntax */
import { useContext, useEffect, useState } from 'react';

import { BackNavigation } from '../../components/Navigation/BackNavigation';
import { typography } from '../../styles/typography.css';
import { Language, useUserSettingsStore } from '../../state/userSettingsStore';
import { UserSettingsContext } from '../../utils/contexts';
import { Menu, MenuItem } from '../../components/Menu/Menu';
import { AnimatePresence } from 'framer-motion';
import { PasswordModal } from './PasswordModal';
import { ClubTable } from '../../components/ClubTable';
import { useGlobalStore } from '../../state/globalStore';
import { useSession } from '../../utils/hooks/useSession';

import * as css from './UserSettings.css';
import { Club } from '@common';

const languages:Language[] = ['English', 'Íslenska', 'Deutch'];

export default function UserSettings() {
    const { userSettings, actions, availableTags, activeLayout, playbackSettings } = useUserSettingsStore();
    const userSettingsContext = useContext(UserSettingsContext);

    const { setShouldChangePassword, user, updateUser } = useSession();

    // user info before changes
    const [modalOpen, setModalOpen] = useState(false);

    // input values
    const [name, setName] = useState(user?.name || '');
    const [email, setEmail] = useState(user?.email || '');
    const [changePasswordError, setChangePasswordError] = useState<string | undefined>(undefined);
    const clubs = useGlobalStore((state) => state.clubs);

    const [selectedLanguage, setSelectedLanguage] = useState<Language>(userSettings.language);
    const [selectedClubs, setSelectedClubs] = useState<Club[]>([]);

    useEffect(() => {
        setSelectedClubs(userSettings.golfBag);
    }, [userSettings.golfBag]);

    const handleSetShouldChangePassword = async() => {
        setChangePasswordError(undefined);

        try {
            await setShouldChangePassword();
        } catch(e) {
            setChangePasswordError(e as string);
        }
    };

    const onSave = () => {
        actions.setUserSettings({
            language: selectedLanguage,
            golfBag: selectedClubs,
        });

        if(name !== user?.name || email !== user?.email) {
            updateUser({ name, email });
        }

        userSettingsContext?.setSettingsStore({
            availableTags,
            activeLayout,
            userSettings: {
                language: selectedLanguage,
                golfBag: selectedClubs,
            },
            playbackSettings,
        });
    };

    return (
        <div className={css.root}>
            <BackNavigation screenTitle="User Settings" onSave={onSave} />
            <div className={css.content}>
                <div className={css.settingsGroupsWrapper}>
                    <div className={css.accountSettings}>
                        <div className={css.inputField}>
                            <p
                                className={typography({
                                    size: 'h4',
                                    weight: 'bold',
                                    color: 'dark',
                                    case: 'uppercase',
                                    tracking: 'positiveLarge',
                                    lineHeight: '1.2',
                                })}
                            >
                                Name
                            </p>
                            <input className={css.input} value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div className={css.inputField}>
                            <p
                                className={typography({
                                    size: 'h4',
                                    weight: 'bold',
                                    color: 'dark',
                                    case: 'uppercase',
                                    tracking: 'positiveLarge',
                                    lineHeight: '1.2',
                                })}
                            >
                                Your email
                            </p>
                            <input className={css.input} type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>

                        <button className={css.passwordButton} onClick={() => setModalOpen(true)}>
                            Change password
                        </button>
                    </div>
                    <div className={css.languageSettings}>
                        <p
                            className={typography({
                                size: 'h4',
                                weight: 'bold',
                                lineHeight: '1.2',
                                color: 'dark',
                                case: 'uppercase',
                                tracking: 'positiveLarge',
                            })}
                        >
                            Language
                        </p>
                        <div className={css.languageButton}>
                            <p
                                className={typography({
                                    size: 'h3',
                                    color: 'dark',
                                    weight: 'bold',
                                    case: 'capitalize',
                                })}
                            >
                                {selectedLanguage}
                            </p>
                            <Menu label="" align="left" buttonSize={24} menuType="main" className={css.menu}>
                                {languages.map((language) => {
                                    return (
                                        <MenuItem key={language} onClick={() => setSelectedLanguage(language)} style={{ minWidth: css.MENU_WIDTH + 10 }}>
                                            <p
                                                className={typography({
                                                    size: 'h3',
                                                    weight: 'bold',
                                                })}
                                            >
                                                {language}
                                            </p>
                                        </MenuItem>
                                    );
                                })}
                            </Menu>
                        </div>
                    </div>
                </div>
                <div className={css.bagSettings}>
                    <p
                        className={typography({
                            size: 'h2',
                            weight: 'bold',
                            color: 'dark',
                            lineHeight: '1.2',
                        })}
                    >
                        My golf bag
                    </p>
                    <ClubTable
                        clubs={clubs}
                        selectedClubs={selectedClubs}
                        onClubClick={(club) => setSelectedClubs(() => (selectedClubs.find((c) => c.id === club.id)
                            ? selectedClubs.filter((c) => c.id !== club.id)
                            : [...selectedClubs, club]))}
                    />
                </div>
            </div>
            <AnimatePresence>{modalOpen && <PasswordModal modalOpen={modalOpen} setModalOpen={setModalOpen} onConfirm={handleSetShouldChangePassword} error={changePasswordError} />}</AnimatePresence>
        </div>
    );
}
