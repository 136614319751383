import { Activity } from './activity.types';
import { Swing } from './swing.types';
import { SupabaseClient } from '@supabase/supabase-js';
export const fetchAllSwingsForActivity = async(supabase:SupabaseClient, activityId:number):Promise<Swing[]> => {
    const { data, error } = await supabase
        .rpc('get_user_swings_for_activity', {
            activity_id_param: activityId,
        }).returns<Swing[]>();

    if(error) {
        throw error;
    }

    return data;
};

export const fetchAllUserFavoriteSwings = async(supabase:SupabaseClient, userId:string) => {

    const { data, error } = await supabase
        .rpc('get_user_favorite_swings', {
            user_id_param: userId
        });

    if(error) {
        throw error;
    }
    
    return data;
};

export const fetchAllUserActivites = async(supabase:SupabaseClient, userId:string):Promise<Activity[]> => {
    const { data, error } = await supabase.rpc('get_user_activities', {
        p_user_id: userId,
        p_limit: 200,
        p_offset: 0
    }).returns<Activity[]>();

    if(error) {
        throw error;
    }

    return data;
};