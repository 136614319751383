export const SCRUBBER_HEIGHT = 50;
export const NAVIGATION_HEIGHT = 84;
export const BACK_NAV_HEIGHT = 60;

// Limit the use of zIndex to these.
export const ZINDEX = {
    ABOVE1: 1,
    ABOVE2: 2,
    ABOVE3: 3,
};

export const CAMERA_FPS = 255;

export const IS_DEV = import.meta.env['NODE_ENV'] === 'development';
