import { useRef } from 'react';
import cn from 'classnames';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';
import '../styles/fonts.css';
import '../styles/reset.css';
import { lightThemeColors } from '../styles/colors.css';
import ConnectionPendingScreen from '../screens/ConnectionPendingScreen';
import KioskScreen from '../screens/KioskScreen';
import FloorScreen from '../screens/FloorScreen';
import Playback from '../screens/PlaybackScreen';
import FloorConnectionPendingScreen from '../screens/FloorConnectionPendingScreen';
import ResetPasswordScreen from '../screens/ResetPasswordScreen';
import EmailSentScreen from '../screens/EmailSentScreen';
import MessageOverlay from '../components/MessageOverlay/MessageOverlay';
import { AuthProtectedRoute } from './protectedRoutes';
import { EDeviceType } from '../utils/types/environment';
import { useUserSettingsStore } from '../state/userSettingsStore';
import { UserSettingsProvider } from '../utils/contexts/UserSettingsContext';
import ClubSelection from '../screens/ClubSelection/ClubSelection';
import TagSelection from '../screens/TagSelection/TagSelection';
import UserSettings from '../screens/UserSettings/UserSettings';
import { SessionProvider } from '../utils/contexts/SessionContext';
import { useSession } from '../utils/hooks/useSession';
import { useGlobalStore } from '../state/globalStore';
import { ActivityOverview } from '../screens/ActivityOverview';
import { BoothLoginScreen } from '../screens/BoothLoginScreen/BoothLoginScreen';
import Spinner, { SpinnerWrapper } from '../components/Spinner/Spinner';
import * as css from './app.css';
import { ChangePasswordScreen } from '../screens/ChangePasswordScreen';
import { BoothSessionProvider } from '../utils/contexts/BoothSessionContext';
import { useBoothSession } from '../utils/hooks/useBoothSession';
import { GlobalContextProvider } from '../utils/contexts/GlobalContext';
import LayoutEditor from '../screens/LayoutEditor';
import _ from 'lodash';
import SwingFoundationsEditor from '../screens/SwingFoundationsEditor';
import PlaybackScreen from '../screens/PlaybackScreen';

const supabase = createClient(import.meta.env['VITE_PUBLIC_SUPABASE_URL'], import.meta.env['VITE_PUBLIC_SUPABASE_ANON_KEY'], {
    auth: {
        storageKey: 'elva-user.session',
    },
});

const supabaseBoothClient = createClient(import.meta.env['VITE_PUBLIC_SUPABASE_URL'], import.meta.env['VITE_PUBLIC_SUPABASE_ANON_KEY'], {
    auth: {
        storageKey: 'elva-booth.session',
    },
});

export default function App() {
    return (
        <BoothSessionProvider supabaseBoothClient={supabaseBoothClient}>
            <GlobalContextProvider supabase={supabase}>
                <div className={cn([lightThemeColors, css.app])}>
                    <AppWithUserSession />
                </div>
            </GlobalContextProvider>
        </BoothSessionProvider>
    );
}

export function AppWithUserSession() {
    const { boothSessionDetails, isLoading } = useBoothSession();
    const location = useLocation();

    const isQrLogin = location.pathname === '/login' && _.includes(location.search, 'uid=');
    const currentDeviceType = EDeviceType.KIOSK; //import.meta.env['VITE_PUBLIC_DEVICE_TYPE'];
    const returnTo = currentDeviceType === EDeviceType.KIOSK
        ? '/kiosk'
        : '/floor';

    if(isLoading) {
        return (
            <SpinnerWrapper>
                <Spinner size="l" />
            </SpinnerWrapper>
        );
    }

    if(!boothSessionDetails && !isQrLogin) {
        return <BoothLoginScreen returnTo={returnTo} />;
    }

    return (
        <SessionProvider supabaseClient={supabase}>
            <GlobalContextProvider supabase={supabase}>
                <BoothApp returnTo={returnTo} />
            </GlobalContextProvider>
        </SessionProvider>
    );
}

function BoothApp({ returnTo }:{ returnTo:string }) {
    const location = useLocation();
    const { isLoggingOut, isLoading, user, cameraConfig } = useSession();

    const state = location.state as { backgroundLocation?:Location };
    const userSettingsStore = useRef(useUserSettingsStore()).current;
    const showSpinner = useGlobalStore(state => state.loadingSpinnerVisible);

    if(isLoading) {
        return (
            <SpinnerWrapper>
                <Spinner size="l" />
            </SpinnerWrapper>
        );
    }

    if(user?.shouldChangePassword && location.pathname !== '/reset-password') {
        return <ChangePasswordScreen subtitle="In order to continue, please update your password." />;
    }

    return (
        <>
            {isLoggingOut && <MessageOverlay message="Ending session..." />}
            {showSpinner && <MessageOverlay message="Loading activity..." />}
            <UserSettingsProvider
                store={userSettingsStore}
                supabaseClient={supabase}
            >
                <Routes location={state?.backgroundLocation || location}>
                    <Route path="/floor" element={user
                        ? <FloorScreen />
                        : <FloorConnectionPendingScreen />} />
                    <Route
                        index
                        path="/kiosk"
                        element={
                            user
                                ? (
                                    cameraConfig
                                        ? (
                                            <KioskScreen supabase={supabase} />
                                        )
                                        : (
                                            <SpinnerWrapper>
                                                <Spinner size="l" />
                                            </SpinnerWrapper>
                                        )
                                )
                                : (
                                    <ConnectionPendingScreen />
                                )
                        }
                    />

                    <Route path="kiosk" element={<AuthProtectedRoute redirectPath="/" />}>
                        <Route path="playback">
                            <Route index element={<PlaybackScreen display='playback' />} />
                            <Route path="comparison" element={<PlaybackScreen display='comparison' />} />
                        </Route>
                        <Route path="layout-editor" element={<LayoutEditor />} />
                        <Route index path="user-settings" element={<UserSettings />} />
                        <Route index path="clubs" element={<ClubSelection />} />
                        <Route index path="tags" element={<TagSelection supabase={supabase} />} />
                        <Route index path="swing-foundations-editor" element={<SwingFoundationsEditor />} />
                        {/* Temp placement, will be moved to be home screen for the home experience */}
                        <Route index path="activity-overview" element={<ActivityOverview />} />
                    </Route>

                    <Route path="/login" element={user
                        ? <Navigate to="/" />
                        : <ConnectionPendingScreen />} />

                    <Route path="/reset-password" element={<ResetPasswordScreen supabase={supabase} />} />

                    <Route path="/email-sent" element={<EmailSentScreen />} />

                    <Route path="*" element={<Navigate to={returnTo} />} />
                </Routes>
            </UserSettingsProvider>
        </>
    );
}
