import { motion } from 'framer-motion';

import { typography } from '../../typography';

import * as css from './Tabs.css';
import _ from 'lodash';

interface TabsProps {
    items:string[];
    activeItem:string;
    onClick:(activeItem:string) => void;
    getTabLabel?:(tab:string) => string;
}

export function Tabs({ items, activeItem, onClick, getTabLabel }:TabsProps) {
    return (
        <div className={css.tabs}>
            {_.map(items, item => {
                const isActive = item === activeItem;

                return (
                    <div
                        key={item}
                        className={css.item({ active: isActive })}
                        role="button"
                        onClick={() => onClick(item)}
                    >
                        <h3 key={item} className={typography({ variant: 'h3' })}>
                            {getTabLabel
                                ? getTabLabel(item)
                                : item
                            }
                        </h3>
                        {isActive && (
                            <motion.div
                                className={css.underline({ active: true })}
                                layoutId="activeUnderline"
                                transition={{ duration: 0.2, ease: 'circOut' }}
                            />
                        )}
                        <div className={css.underline({ active: false })} />
                    </div>
                );
            })}
        </div>
    );
}
