import { useCallback, useEffect,  useMemo,  useState } from 'react';
import _ from 'lodash';
import { CaretRight as CaretRightIcon } from '@phosphor-icons/react';

import { localize, StaticL10nID, ALL_DEVICES, Axis, inferSwingPosition, isType, Layout, Nil, PositionNumber,  rhsOfLast,  setToggle,  SwingPosition, swingPositionToPositionNumber, UIGraphLine, UIModule,  UIParameter, UISwingFoundations, UINodeRelation, UINodeToggler, UINodeTree, rhsOfFirst, AnyCategoryID, getCategorizations, filterByCategory, CategoryID, getLocalizedSwingPositionName } from '@common';
import { useUIComponentValues } from '../../../state/globalStore';
import { Parameter } from '../../../components/UIComponents/implementations/Parameter/Parameter';
import {
    Button,
    typography,
    ToggleButton,
    Panel,
    Modal,
    colors,
    ToolPanel,
} from '@common/ui';

import * as css from './CustomModuleEditor.css';

//! REFACTOR functionality shared between SwingFoundationsEditor, CustomModuleEditor and KeyParameterEditor

interface CustomModuleEditorProps {
    layout:Layout;
    uiNodeTree:UINodeTree | Nil;
    moduleBeingEdited:UIModule | Nil;
    onClose:() => void;
}

export function CustomModuleEditor({
    layout,
    uiNodeTree,
    moduleBeingEdited,
    onClose,
}:CustomModuleEditorProps) {
    const customModuleID = moduleBeingEdited?.id;
    const timeSeriesGraphs = _(uiNodeTree?.nodes ?? [])
        // filter out non-time series graphs
        .filter(node =>
            node?.type === 'graph'
            && node.categories.graph_type !== 'graph_type.key'
        )
        // map to node and first child graph line
        .map(graph => ({
            graph,
            childID: _.find(
                uiNodeTree?.relations,
                r => r.parent_ui_node_id === graph.id && r.child_ui_node_id.startsWith('graph_line.')
            )?.child_ui_node_id
        }))
        .filter(({ childID }) => !!childID)
        // map to time series of graph_line nodes
        .map(({ graph, childID }) => {
            const graphLine = _.find(uiNodeTree?.nodes, n => n.id === childID) as UIGraphLine;
            return {
                graph,
                timeSeriesID: isType(graphLine, 'graph_line')
                    ? graphLine?.time_series?.id
                    : null,
            };
        })
        .filter(({ timeSeriesID }) => !!timeSeriesID)
        .value();

    const swingFoundationsGroups = useMemo(() => {
        const swingFoundationNodes = _(uiNodeTree?.nodes ?? [])
            .filter(c => c.type === 'swing_foundations')
            .keyBy(n => inferSwingPosition(n.id) || 'all')
            .value() as { [key in SwingPosition|'all']:UISwingFoundations };
    
        const parameters = _(uiNodeTree?.relations ?? [])
            .concat((layout?.customizations as UINodeRelation[]) ?? [])
            .filter(r => _.some(swingFoundationNodes, n => n.id === r.parent_ui_node_id))
            .uniqBy(r => r.child_ui_node_id)
            .map(r => _.find(uiNodeTree?.nodes, n => n.id === r.child_ui_node_id))
            .filter(n => n?.type === 'parameter')
            .map(node => {
                const position = inferSwingPosition(node?.id) ?? 'p1';

                const togglers = _(
                    [
                        swingFoundationNodes[position],
                        swingFoundationNodes.all,
                    ])
                    .compact()
                    .map(parentNode =>
                    ({
                        layout,
                        parentNode,
                        childNode: node,
                        currentDevices: [...parentNode.show_on],
                        defaultChildMetadata: {},
                        isToggledOnByDefault: true,
                    }) as UINodeToggler
                    )
                    .value();

                return togglers.length
                    ? { ...node, position } as UIParameter & {position:string}
                    : null;
            })
            .compact()
            .value();

        const positionGroups = _(parameters)
            .groupBy(p => p.position)
            .map((positionParameters, position) => ({
                position,
                positionNumber: swingPositionToPositionNumber(position as SwingPosition),
                parameters: _(positionParameters)
                    .sortBy(p => p.name?.value)
                    .value()
            }))
            .sortBy(p => p.positionNumber)
            .value();

        return positionGroups;
    }, [layout.id]); // eslint-disable-line react-hooks/exhaustive-deps

    const getCategorizedParameters = (
        parameters:UIParameter[],
        groupBy:keyof UIParameter['categories'],
    ) => _(parameters)
        .groupBy(node => node.categories[groupBy])
        .map((params, categoryID) => ({
            category: _.find(uiNodeTree?.categories, c => c.id === categoryID) || null,
            parameters: params
        }))
        .sortBy(group => group.category?.display_order ?? Infinity)
        .value();

    const parameterNodes = _.filter(
        uiNodeTree?.nodes ?? [],
        node => node.type === 'parameter'
    ) as UIParameter[];

    const timeSeriesParametersByCategory = _(timeSeriesGraphs)
        .map(({ graph, timeSeriesID }) => {
            return {
                graph,
                axes: _(parameterNodes)
                    .filter(p => p.parameter.time_series_id === timeSeriesID)
                    .sortBy(p => inferSwingPosition(p.id))
                    .groupBy(p => p.parameter.axis || 'x')
                    .value() as Record<Axis, UIParameter[]>
            };
        })
        .groupBy(({ axes }) => _(axes).values().flatten().map(p => p.categories.metric).find(x => !!x) || 'other')
        .map((timeSeries, categoryID) => ({ categoryID, timeSeries }))
        .sortBy(({ categoryID }) => _.find(uiNodeTree?.categories, c => c.id === categoryID)?.display_order ?? Infinity)
        .value();

    const orderedCategoryIDs = _(uiNodeTree?.categories)
        // .filter(c => c.id.startsWith('body_part.') || c.id.startsWith('swing_foundation_group.'))
        // .sortBy(c => [c.id.startsWith('body_part'), c.display_order])
        .filter(c => c.id.startsWith('body_part.'))
        .sortBy(c => c.display_order)
        .map(c => c.id)
        .value() as AnyCategoryID[];
    
    const [categoryFilter, setCategoryFilter] = useState<Set<AnyCategoryID>>(new Set());
    const categorizations = useMemo(
        () => {
            const map = uiNodeTree && getCategorizations(uiNodeTree);
            if(!map) return null;
            //! HACK, use categoryIDs already assigned to graphs and assign them to parameters in the same time series
            for(const parameterNode of parameterNodes) {
                const timeSeriesID = parameterNode.parameter.time_series_id;
                const graph = _.find(timeSeriesGraphs, g => g.timeSeriesID === timeSeriesID)?.graph;
                const graphCategories = graph?.id && map[graph.id];
                if(graphCategories)
                    (map[parameterNode.id] || []).push(...graphCategories);
            }
            return map;
        },
        [uiNodeTree, parameterNodes, timeSeriesGraphs]
    );
    const visibleUINodeIDs = useMemo(
        () => categorizations
            && categoryFilter
            && filterByCategory(categorizations, categoryFilter)
            || 'all_is_visible',
        [categorizations, categoryFilter]
    );

    const [selectedParameterIDs, setSelectedParameterIDs] = useState<Set<string>>(new Set());
    useEffect(
        () => {
            setSelectedParameterIDs(new Set(customModuleID && _(layout.customizations)
                .filter(c => c.parent_ui_node_id === customModuleID)
                .map(c => c.child_ui_node_id)
                .value() || []
            ));
            setCategoryFilter(new Set());
        }, [customModuleID] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const hasSelectedParameters = selectedParameterIDs.size > 0;
    const showSwingFoundations = uiNodeTree && categoryFilter.size === 0;

    const values$ = useUIComponentValues();

    if(!moduleBeingEdited)
        return null;

    return (
        <Modal
            title="Edit Custom Module"
            onDismiss={onClose}
            footer={{
                primary: (
                    <Button
                        variant="primary"
                        isDisabled={false}
                        onClick={() => {
                            if(!customModuleID) return;
                            const customModuleNumber = rhsOfLast(customModuleID, '_');
                            const title = `My Custom Module #${Number(customModuleNumber)}`;

                            const rootToggler:UINodeToggler = {
                                layout,
                                parentNode: null,
                                childNode: moduleBeingEdited,
                                currentDevices: [],
                                defaultChildMetadata: {
                                    title,
                                }
                            };

                            const oldChildTogglers = _(layout.customizations)
                                .filter(c => c.parent_ui_node_id === customModuleID)
                                .map(c => ({
                                    layout,
                                    parentNode: moduleBeingEdited,
                                    childNode: _.find(uiNodeTree?.nodes, n => n.id === c.child_ui_node_id),
                                    currentDevices: ALL_DEVICES,
                                }) as UINodeToggler)
                                .value();

                            for(const oldChild of oldChildTogglers)
                                setToggle(oldChild, false);

                            const newChildTogglers = _.map(
                                [...selectedParameterIDs],
                                id => ({
                                    layout,
                                    parentNode: moduleBeingEdited,
                                    childNode: _.find(uiNodeTree?.nodes, n => n.id === id),
                                    currentDevices: ALL_DEVICES,
                                }) as UINodeToggler
                            );

                            setToggle(rootToggler, true);
                            for(const newChild of newChildTogglers)
                                setToggle(newChild, true);

                            onClose();
                        }}
                    >
                        {localize('layout_editor.custom_module.save_button')}
                    </Button>
                ),
                secondary: (
                    <Button
                        variant="secondary"
                        onClick={onClose}
                    >
                        {localize('layout_editor.custom_module.cancel_button')}
                    </Button>
                ),
            }}
        >
            <div className={css.root}>
                <aside className={css.aside}>
                    <ToolPanel title="Filter">
                        <div className={css.filterGroup}>
                            <h4 className={typography({ variant: 'h4' })}>Body Parts</h4>
                            {_.map(orderedCategoryIDs, (categoryID) => {
                                const title
                                    = _.find(
                                        uiNodeTree?.categories,
                                        c => c.id === categoryID
                                    )?.name?.value
                                    || _.startCase(categoryID);
                                return (
                                    <ToggleButton
                                        key={categoryID}
                                        icon="chart-line"
                                        maxLetters={36}
                                        isActive={categoryFilter.has(categoryID)}
                                        onChange={(isActive) => {
                                            if(isActive) {
                                                setCategoryFilter(prevFilter => new Set(prevFilter).add(categoryID));
                                            } else {
                                                setCategoryFilter(prevFilter => {
                                                    const newFilter = new Set(prevFilter);
                                                    newFilter.delete(categoryID);
                                                    return newFilter;
                                                });
                                            }
                                        }}
                                    >
                                        {title}
                                    </ToggleButton>
                                );
                            })}

                        </div>
                    </ToolPanel>
                </aside>
                <div className={css.main}>
                    <Panel
                        title="Selected Parameters"
                        collapsible={hasSelectedParameters}
                        countChildren
                        initialState={hasSelectedParameters
                            ? 'open'
                            : 'closed'
                        }
                    >
                        {_.map([...selectedParameterIDs], parameterID => {
                            const node = _(uiNodeTree?.nodes).find(n => n.id === parameterID) as UIParameter;

                            if(!node) {
                                return null;
                            }

                            return uiNodeTree && (
                                <Parameter
                                    key={node.id}
                                    node={node as UIParameter}
                                    values$={values$}
                                    currentDevice="kiosk"
                                    children={[]}
                                    uiNodeTree={uiNodeTree}
                                    onClick={() => {
                                        setSelectedParameterIDs(prev => {
                                            const newSet = new Set(prev);
                                            newSet.delete(parameterID);
                                            return newSet;
                                        });
                                    }}
                                    isSelected={true}
                                />
                            );
                        })}
                    </Panel>
                    {showSwingFoundations && (
                        <Panel key="swing_foundations" title={localize('layout_editor.panel.swing_foundations')} initialState="open" collapsible={false}>
                            {_.map(swingFoundationsGroups, ({position, parameters}) => {
                                const title = getLocalizedSwingPositionName(position as SwingPosition);
                                return (
                                    <Panel key={position} icon="lego" title={title} initialState="closed" collapsible>
                                        <div className={css.parameters}>
                                            {_.map(getCategorizedParameters(parameters, 'swing_foundation_group'), ({ category, parameters: categorizedParams }) => (
                                                <div key={category?.id || 'other'} className={css.group}>
                                                    <h4 className={typography({variant:'h3'})}>
                                                        {category?.name?.value || 'Other'}
                                                    </h4>
                
                                                    <div className={css.parameters}>
                                                        {_.map(categorizedParams, (node) => (
                                                            uiNodeTree && (visibleUINodeIDs === 'all_is_visible' || visibleUINodeIDs.has(node.id)) &&
                                                            (
                                                                <Parameter
                                                                    key={node.id}
                                                                    node={node as UIParameter}
                                                                    values$={values$}
                                                                    currentDevice="kiosk"
                                                                    children={[]}
                                                                    uiNodeTree={uiNodeTree}
                                                                    onClick={() => {
                                                                        setSelectedParameterIDs(prev => {
                                                                            const newSet = new Set(prev);
                                                                            const exists = newSet.has(node.id);
                                                                            if(exists) {
                                                                                newSet.delete(node.id);
                                                                            } else {
                                                                                newSet.add(node.id);
                                                                            }
                                                                            return newSet;
                                                                        });
                                                                    }}
                                                                    isSelected={selectedParameterIDs.has(node.id)}
                                                                />
                                                            )
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </Panel>
                                );
                            })}
                        </Panel>
                    )}


                    {_.map(timeSeriesParametersByCategory, ({ timeSeries, categoryID }) => {
                        return (
                            <Panel
                                key={categoryID}
                                title={
                                    _.find(
                                        uiNodeTree?.categories,
                                        c => c.id === categoryID
                                    )?.name?.value
                                    || _.startCase(categoryID)
                                }
                                countChildren={false}
                                collapsible={true}
                                initialState="open"
                            >
                                {_.map(timeSeries, ({ graph, axes }) => {
                                    if(visibleUINodeIDs !== 'all_is_visible' && !visibleUINodeIDs.has(graph.id))
                                        return null;

                                    const positionsPerAxis = _.mapValues(
                                        axes,
                                        axis => _.keyBy(axis, p => inferSwingPosition(p.id))
                                    );

                                    const handleAdd = (state:ParameterSelectorState) => {
                                        setSelectedParameterIDs(prev => {
                                            if(!state.selectedId) {
                                                return prev;
                                            }

                                            const newSet = new Set(prev);
                                            newSet.add(state.selectedId);
                                            return newSet;
                                        });
                                    };

                                    const name = graph.name?.value ?? graph.short_name?.value ?? _.startCase(graph.id);

                                    return (
                                        <ParameterSelector
                                            key={name}
                                            name={name}
                                            positionsPerAxis={positionsPerAxis as Record<Axis, Record<PositionNumber, UIParameter>>}
                                            onAdd={handleAdd}
                                        />
                                    );
                                })}
                            </Panel>
                        );
                    })}
                </div>
            </div>
        </Modal>
    );
}


 type ParameterSelectorState = {
    selectedId:string | null
    selectedPosition:string | null;
    selectedAxis:Axis | null
    validSelection:boolean;
};

function ParameterSelector({
    name,
    positionsPerAxis,
    onAdd = () => { },
}:{
    positionsPerAxis:Record<Axis, Record<PositionNumber, UIParameter>>,
    name:string
    onAdd?:(state:NonNullable<ParameterSelectorState>) => void,
}) {
    const axes = _.keys(positionsPerAxis);
    const xAxisAutoSelected = axes.length <= 1;
    const initialState = Object.freeze({
        selectedId: null,
        selectedPosition: null,
        selectedAxis: xAxisAutoSelected
            ? 'x'
            : null,
        validSelection: false,
    });

    const [state, setState] = useState<ParameterSelectorState>(initialState);

    const positions = positionsPerAxis[(state.selectedAxis || 'x') as Axis];

    const updateState = useCallback(
        (updates:Partial<ParameterSelectorState>) => {
            const newState = {
                ...state,
                ...updates,
            };

            // Compute validSelection
            newState.validSelection =
                newState.selectedId !== null && newState.selectedPosition !== null && newState.selectedAxis !== null;

            setState(newState);
        },
        [state],
    );


    const handleAdd = useCallback(() => {
        if(state.validSelection) {
            onAdd(state);
            setState(initialState);
        }

    }, [state, initialState, onAdd]);

    const orderedPositions = _(positions)
        .toPairs()
        .sortBy(([position]) => swingPositionToPositionNumber(position as SwingPosition))
        .fromPairs()
        .value();

    return (
        <div className={css.parameterSelectorLayout}>
            <div className={css.parameterSelector}>
                <div className={css.parameterSelectorTitle}>
                    <h3 className={typography({ variant: 'h3' })}>{name}</h3>
                </div>
                <div className={css.parameterSelectorButtons}>
                    {_.map(orderedPositions, (parameterNode, position) => {
                        const isActive = state.selectedPosition === position;
                        const shouldExpand = isActive && !xAxisAutoSelected;
                        return (
                            <div className={css.toggleGroup({
                                isActive: shouldExpand
                            })}>
                                <ToggleButton
                                    key={position}
                                    variant="darker"
                                    isActive={isActive}
                                    onChange={(isActive) => {
                                        updateState({
                                            selectedPosition: isActive
                                                ? position
                                                : null,
                                            selectedId: parameterNode.id,
                                        });
                                    }}
                                >
                                    {position}
                                </ToggleButton>
                                {shouldExpand && <CaretRightIcon weight="regular" color={colors.bluegray[600]} />}
                                {shouldExpand && _.map(axes, axis => (
                                    <ToggleButton
                                        key={axis}
                                        variant="darker"
                                        isActive={state.selectedAxis === axis}
                                        onChange={(isActive) => {
                                            updateState({
                                                selectedAxis: isActive
                                                    ? (axis as Axis)
                                                    : null,
                                            });
                                        }}
                                    >
                                        {axis}
                                    </ToggleButton>
                                ))}
                            </div>
                        );
                    })}
                </div>
            </div>

            <Button
                variant="secondary"
                size="small"
                isDisabled={!state.validSelection}
                onClick={handleAdd}
            >
                Add
            </Button>
        </div>
    );

}


