import { useNavigate } from 'react-router-dom';

import ActionButton from '../ui/ActionButton/ActionButton';
import Tag from '../ui/Tag';
import { Menu, MenuItem } from '../Menu/Menu';
import { typography } from '../../styles/typography.css';
import { LogOut } from '../ui/icons/LogOut';
import { SwingInsight } from '../ui/icons/SwingInsight';
import { ArrowsClockwise } from '../ui/icons/ArrowsClockwise';
import { useUserSettingsStore } from '../../state/userSettingsStore';

import * as css from './Navigation.css';
import ElvaLogoName from '../ui/Logo/ElvaLogoName';
import { User } from '../ui/icons/User';
import { useRef, useState } from 'react';
import { motion, useMotionValueEvent, useScroll } from 'framer-motion';
import { Club } from '../ui/icons/Club';
import { useSession } from '../../utils/hooks/useSession';
import { useBoothSession } from '../../utils/hooks/useBoothSession';
import _ from 'lodash';
import { useGlobalStore } from '../../state/globalStore';

export const NavigationMenu = () => {
    const navigate = useNavigate();
    const { user, logout } = useSession();
    const { logout: logoutBooth } = useBoothSession();

    return (
        <Menu label="Actions" align="left" buttonSize={24} menuType="main" className={css.menu}>
            <MenuItem onClick={() => navigate('/kiosk/playback')} style={{ justifyContent: 'flex-start' }}>
                <SwingInsight />
                <p
                    className={typography({
                        size: 'h3',
                        weight: 'bold',
                    })}
                >
                    Swing Insight
                </p>
            </MenuItem>
            <MenuItem onClick={() => navigate('/kiosk/user-settings')} style={{ justifyContent: 'flex-start' }}>
                <User />
                <p
                    className={typography({
                        size: 'h3',
                        weight: 'bold',
                    })}
                >
                    User Settings
                </p>
            </MenuItem>
            <MenuItem onClick={() => navigate('/kiosk/activity-overview')} style={{ justifyContent: 'flex-start' }}>
                <Club />
                <p
                    className={typography({
                        size: 'h3',
                        weight: 'bold',
                    })}
                >
                    Activity overview
                </p>
            </MenuItem>

            <MenuItem onClick={logout} style={{ justifyContent: 'flex-start' }}>
                <LogOut />
                <p
                    className={typography({
                        size: 'h3',
                        weight: 'bold',
                    })}
                >
                    End session
                </p>
            </MenuItem>

            {user?.isAdmin && (
                <MenuItem
                    onClick={() => {
                        logoutBooth();
                        logout();
                    }}
                    style={{ justifyContent: 'flex-start' }}
                >
                    <LogOut /> {/* todo: change icon */}
                    <p
                        className={typography({
                            size: 'h3',
                            weight: 'bold',
                        })}
                    >
                        End booth session
                    </p>
                </MenuItem>
            )}

            <MenuItem onClick={() => window.location.reload()} style={{ justifyContent: 'flex-start' }}>
                <ArrowsClockwise />
                <p
                    className={typography({
                        size: 'h3',
                        weight: 'bold',
                    })}
                >
                    Reboot
                </p>
            </MenuItem>
        </Menu>
    );
};
export default function Navigation() {
    const navigate = useNavigate();
    const tagsRef = useRef<HTMLDivElement>(null);
    const { user } = useSession();
    const { activeLayout, activityClub, activityTags, userSettings } = useUserSettingsStore(({ activeLayout, activityClub, activityTags, userSettings }) => ({ activeLayout, activityClub, activityTags, userSettings }));
    const activeLayoutName = activeLayout?.name ?? 'No Layout';
    const { scrollXProgress } = useScroll({ container: tagsRef });
    const [isScrollEnd, setIsScrollEnd] = useState<0 | 1 | undefined>(0);
    const myGolfBag = userSettings.golfBag;
    const comparisonSwingId = useGlobalStore(state => state.comparisonSwingID);
    const comparisonSwing = useGlobalStore(state => state.comparisonSwing);

    useMotionValueEvent(scrollXProgress, 'change', (latest) => {
        if(latest === 1) {
            setIsScrollEnd(1);
        } else if(latest === 0) {
            setIsScrollEnd(0);
        } else {
            setIsScrollEnd(undefined);
        }
    });

    return (
        <div className={css.root}>
            <ElvaLogoName />

            <div className={css.actionGroupLayout}>
                
                <button
                    onClick={() => navigate(
                        `/kiosk/clubs?display=${
                            _.isEmpty(myGolfBag)
                                ? 'all'
                                : 'bag'
                        }`
                    )}
                    className={css.tagsLayout}
                >
                    <p className={css.tagsGroup}>Club</p>
                    <Tag value={activityClub?.short_name || 'Select'} />
                </button>
                
                <button className={css.tagsLayout} onClick={() => navigate('/kiosk/tags')}>
                    <p className={css.tagsGroup}>Tags</p>
                    <div className={css.tagsWrap}>
                        <div className={css.tags} ref={tagsRef}>
                            {activityTags?.length
                                ? _.map(activityTags, (tag) => <Tag key={tag.id} value={tag.name} />)
                                : <Tag key="Select" value="Select" />}
                        </div>
                        {tagsRef.current && tagsRef.current?.clientWidth < tagsRef?.current.scrollWidth && (
                            <>
                                <motion.div
                                    className={css.scrollShadow({ direction: 'left' })}
                                    initial={{ opacity: 0 }}
                                    animate={{
                                        opacity: isScrollEnd === 0
                                            ? 0
                                            : 1,
                                    }}
                                    exit={{ opacity: 0 }}
                                />
                                <motion.div
                                    key="right-shadow"
                                    className={css.scrollShadow({ direction: 'right' })}
                                    initial={{ opacity: 0 }}
                                    animate={{
                                        opacity: isScrollEnd === 1
                                            ? 0
                                            : 1,
                                    }}
                                    exit={{ opacity: 0 }}
                                />
                            </>
                        )}
                    </div>
                </button>

                {comparisonSwingId && (<button
                    onClick={() => {
                        navigate('/kiosk/playback/comparison');
                    }}
                    className={css.tagsLayout}>
                    <p className={css.tagsGroup}>Comp.</p>
                    <Tag value={comparisonSwing?.name ?? `Swing #${comparisonSwingId}`} />
                </button>
                )}
            </div>
            <nav>
                <ul className={css.actionButtons}>
                    <li className={css.action}>
                        <ActionButton
                            icon="layout"
                            text={activeLayout
                                ? 'Layout: '
                                : 'Layout'}
                            secondaryText={activeLayoutName}
                            onClick={() => {
                                // TODO: We need to add the layout's ID to the route
                                navigate('/kiosk/layout-editor');
                            }}
                        />
                    </li>

                    <li className={css.action}>
                        <ActionButton
                            text="Playback"
                            icon="camera"
                            onClick={() => {
                                navigate('/kiosk/playback');
                            }}
                        />
                    </li>

                    <li>
                        <div className={css.actionsButton}>
                            <p
                                className={typography({
                                    size: 'h3',
                                    color: 'dark',
                                    weight: 'bold',
                                    case: 'capitalize',
                                })}
                            >
                                {user?.name ?? 'Actions'}
                            </p>
                            <NavigationMenu />
                        </div>
                    </li>
                </ul>
            </nav>
        </div>
    );
}
