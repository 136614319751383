
import { useContext, useMemo, useState } from 'react';
import {useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useForceUpdate } from 'framer-motion';
import { Screen, Button, colors, typography, ToolPanel, ToggleButton, Panel, ParameterSelector } from '@common/ui';
import { UserSettingsContext } from '../../utils/contexts';
import { useUserSettingsStore } from '../../state/userSettingsStore';
import { localize, AnyCategoryID, applyDisplayOrder, deferOnChange, filterByCategory, getCategorizations, getDisplayOrder, getTogglerTitle, isToggledOn, lhsOfFirst, Mutable, rhsOfFirst, toggleOn, UIModule, UINodeRelation, type AnyUINode, type Device, type Layout, type Nil, type UINodeToggler, type UINodeTree } from '@common';
import { LayoutEditorTabs } from './LayoutEditorTabs/LayoutEditorTabs';
import { KeyParameterSelection, } from './KeyParameterSelection/KeyParameterSelection';
import { CustomModuleEditor } from './CustomModuleEditor/CustomModuleEditor';
import { CaretRight } from '../../components/ui/icons/CaretRight';
import * as css from './LayoutEditor.css'; import { KeyParameterEditor } from './KeyParameterEditor/KeyParameterEditor';

type PanelType
    = 'swing_foundations'
    | 'module'
    | 'key'
    | 'linear_position'
    | 'angular_position'
    | 'linear_displacement_from_p1'
    | 'angular_displacement_from_p1'
    | 'linear_velocity'
    | 'angular_velocity'
    | 'linear_acceleration'
    | 'angular_acceleration'
    | 'angle'
    | 'distance'
    | 'speed'
    | 'ratio';

export default function LayoutEditor() {
    const navigate = useNavigate();
    const location = useLocation();
    const [panelFilter, setPanelFilter] = useState<Set<PanelType>>(new Set());
    const [categoryFilter, setCategoryFilter] = useState<Set<AnyCategoryID>>(new Set());
    const [[device, parentNode], setTarget] = useState<[Device, AnyUINode | null]>(['kiosk', null]);
    const userSettingsContext = useContext(UserSettingsContext);
    const [originalLayout, uiNodeTree] = useUserSettingsStore((state) => [
        state.activeLayout,
        state.uiNodes,
    ]);
    const layoutBeingEdited = useMemo(() => _.cloneDeep(originalLayout) as Mutable<Layout>, [originalLayout]);
    const [isEditingKeyParameter, setIsEditingKeyParameter] = useState(location.state?.isEditingKeyParameter ?? false);
    const [moduleBeingEdited, setModuleBeingEdited] = useState<UIModule|null>(null);

    const [update] = useForceUpdate();

    const categorizations = useMemo(() => uiNodeTree && getCategorizations(uiNodeTree), [uiNodeTree]);
    const visibleUINodeIDs = useMemo(
        () => categorizations
            && categoryFilter
            && filterByCategory(categorizations, categoryFilter)
            || 'all_is_visible',
        [categorizations, categoryFilter]
    );

    const togglers = useMemo(
        () => {
            if(!layoutBeingEdited || !uiNodeTree) return [];
            const list = createTogglersForDevice(device, layoutBeingEdited, uiNodeTree, update, parentNode);

            // Apply initial values
            deferOnChange(
                list,
                t => t.isToggledOnByDefault && toggleOn(t),
                _.noop // we don't actually want to trigger an update here
            );

            return list;
        },
        [device, layoutBeingEdited?.id, moduleBeingEdited, !uiNodeTree, parentNode] // eslint-disable-line react-hooks/exhaustive-deps
    );


    // when editing the floor screen, we are in fact editing its pages,
    // meaning we need to select the first page as our target if none is selected
    if(device === 'floor' && !parentNode) {
        const firstPage = _.find(togglers, t => t.childNode.type === 'page')?.childNode ?? null;
        if(firstPage)
            setTarget(['floor', firstPage]);
        else
            console.warn('No pages found for floor device');
    }

    const orderableTogglers = _(togglers)
        .filter(t => isToggledOn(t))
        .sortBy(t => [getDisplayOrder(t), t.childNode.name?.value ?? t.childNode.id])
        .value();

    const orderedPanelTypes = _(uiNodeTree?.categories || [])
        .filter(c => c.id.startsWith('graph_type.'))
        .sortBy(c => c.display_order)
        .map(c => rhsOfFirst(c.id, '.'))
        .unshift('swing_foundations', 'module')
        .value() as PanelType[];

    const availableTogglersByPanelType = _(togglers)
        .filter(t => visibleUINodeIDs === 'all_is_visible' || visibleUINodeIDs.has(t.childNode.id))
        .groupBy(t => t.childNode.type === 'graph'
            ? rhsOfFirst(t.childNode.categories.graph_type, '.')
            : t.childNode.type
        )
        .pickBy((_, type) => panelFilter.size === 0 || panelFilter.has(type as PanelType))
        .map((togglers, type) => [type as PanelType, togglers] as const)
        .orderBy(([type]) => _.indexOf(orderedPanelTypes, type))
        .value();

    return (
        <div className={css.root}>
            <Screen.Root
                title={localize('layout_editor.title')}
                header={{
                    static: {
                        start: (
                            <Button
                                size="medium"
                                variant="tertiary"
                                icon="pencil"
                                iconSide="after"
                                iconColor={colors.blue[500]}
                                onClick={() => {
                                    console.log('Open a modal, properly.');
                                }}
                            >
                                {layoutBeingEdited?.name || 'Layout'}
                            </Button>
                        ),
                        end: (
                            <>
                                <Button variant="secondary" onClick={() => navigate(-1)}>
                                    {localize('layout_editor.cancel_button')}
                                </Button>
                                <Button variant="primary" onClick={() => {
                                    console.log('Trying to save:', layoutBeingEdited);
                                    if(!layoutBeingEdited)
                                        return;
                                    userSettingsContext?.updateUserLayout(layoutBeingEdited);
                                    navigate('/kiosk');
                                }}>
                                    {localize('layout_editor.save_button')}
                                </Button>
                            </>
                        )
                    }
                }}
            >
                <Screen.Column span="4/20">
                    <ToolPanel title="Key parameter">
                        <KeyParameterSelection
                            layout={layoutBeingEdited}
                            uiNodeTree={uiNodeTree}
                            onClick={() => setIsEditingKeyParameter(!isEditingKeyParameter)}
                        />
                        <KeyParameterEditor
                            layout={layoutBeingEdited}
                            uiNodeTree={uiNodeTree}
                            isOpen={isEditingKeyParameter}
                            onClose={() => setIsEditingKeyParameter(false)}
                        />
                    </ToolPanel>
                    <ToolPanel title="Filter">
                        <div className={css.filterGroup}>
                            <h4 className={typography({ variant: 'h4' })}>Types</h4>
                            {_.map(orderedPanelTypes, type => (
                                <ToggleButton
                                    key={type}
                                    icon="chart-line"
                                    maxLetters={36}
                                    isActive={panelFilter.has(type)}
                                    onChange={(isActive) => {
                                        if(isActive) {
                                            setPanelFilter(prevFilter => new Set(prevFilter).add(type));
                                        } else {
                                            setPanelFilter(prevFilter => {
                                                const newFilter = new Set(prevFilter);
                                                newFilter.delete(type);
                                                return newFilter;
                                            });
                                        }
                                    }}
                                >
                                    {/* The reason why we can't simply use the localized name
                                    of the node/graph type is because we need the label to be plural */}
                                    {localize(`layout_editor.panel.${type}`)}
                                </ToggleButton>
                            ))}
                        </div>
                        <div className={css.filterGroup}>
                            <h4 className={typography({ variant: 'h4' })}>Body Parts</h4>
                            {_(uiNodeTree?.categories)
                                .filter(c => c.id.startsWith('body_part.'))
                                .sortBy(c => c.display_order)
                                .map(c =>
                                    <ToggleButton
                                        key={c.id}
                                        icon="chart-line"
                                        maxLetters={36}
                                        isActive={categoryFilter.has(c.id)}
                                        onChange={(isActive) => {
                                            if(isActive) {
                                                setCategoryFilter(prevFilter => new Set(prevFilter).add(c.id));
                                            } else {
                                                setCategoryFilter(prevFilter => {
                                                    const newFilter = new Set(prevFilter);
                                                    newFilter.delete(c.id);
                                                    return newFilter;
                                                });
                                            }
                                        }}
                                    >
                                        {c.name?.value || _.startCase(rhsOfFirst(c.id, '.'))}
                                    </ToggleButton>
                                )
                                .value()
                            }
                        </div>
                    </ToolPanel>
                </Screen.Column>
                <Screen.Column span="8/20">
                    {_.map(availableTogglersByPanelType, ([type, togglers]) => {
                        return (
                            <Panel
                                key={`panel_${type}`}
                                title={localize(`layout_editor.panel.${type}`)}
                                initialState="open"
                                contentDirection="column"
                                headerContent={type === 'module' && (
                                // CREATE button
                                    <Button
                                        variant="secondary"
                                        size="tiny"
                                        icon="plus"
                                        iconColor={css.createCustomModuleIconColor}
                                        onClick={() => {
                                            if(moduleBeingEdited) return;
                                            const nextUnusedNode
                                                = _.find(
                                                    uiNodeTree?.nodes,
                                                    n => n.type === 'module'
                                                        && !_.some(layoutBeingEdited?.customizations, r => r.child_ui_node_id === n.id)
                                                ) as UIModule;
                                            if(!nextUnusedNode) return;
                                            setModuleBeingEdited(nextUnusedNode);
                                        }}
                                    >
                                        {localize('layout_editor.create_module_button')}
                                    </Button>
                                )}
                            >
                                {_.map(togglers, toggler => {
                                    // render each toggler
                                    const isOn = isToggledOn(toggler);
                                    const handleClick = type === 'module'
                                        ? () => setModuleBeingEdited(toggler.childNode as UIModule)
                                        : undefined;

                                    return (
                                        <div className={css.togglerLayout}>
                                            <div
                                                key={toggler.childNode.id}
                                                className={css.toggler}
                                            >
                                                <ParameterSelector
                                                    onClick={handleClick}
                                                    isActive={isOn}
                                                    title={getTogglerTitle(toggler, uiNodeTree)}
                                                />
                                                <Button
                                                    variant="secondary"
                                                    size="tiny"
                                                    onClick={() => {
                                                        if(!isOn) {
                                                            const newOrder = [...orderableTogglers, toggler];
                                                            deferOnChange(
                                                                newOrder,
                                                                () => {
                                                                    toggleOn(toggler);
                                                                    applyDisplayOrder(newOrder);
                                                                }
                                                            );
                                                        }
                                                    }}
                                                    isDisabled={isOn}
                                                >
                                                    <CaretRight />
                                                </Button>
                                            </div>
                                        </div>
                                    );
                                })}
                            </Panel>
                        );
                    })}
                </Screen.Column>
                <Screen.Column span="8/20" sticky>{uiNodeTree &&
                    <LayoutEditorTabs
                        tabs={['kiosk', 'floor']}
                        selectedTab={device}
                        getTabLabel={d => localize(`device.${d as Device}`, d)}
                        onSelectTab={d => setTarget([d as Device, null])}
                        togglers={orderableTogglers}
                        uiNodeTree={uiNodeTree}
                        maxLength={getMaxTogglers(device)}
                    />
                }</Screen.Column>
            </Screen.Root>
            <CustomModuleEditor
                layout={layoutBeingEdited}
                uiNodeTree={uiNodeTree}
                moduleBeingEdited={moduleBeingEdited}
                onClose={() => setModuleBeingEdited(null)}
            />
        </div>
    );
}

function getMaxTogglers(device:Device) {
    switch(device) {
        case 'kiosk': return 10;
        case 'floor': return 4;
        default: return 0;
    }
}

function createTogglersForDevice(device:Device, ...params:Parameters<typeof createTogglersForKiosk>):UINodeToggler[] {
    switch(device) {
        case 'kiosk':
            return createTogglersForKiosk(...params);
        case 'floor':
            return createTogglersForFloor(...params);
    }
}

function createTogglersForKiosk(layout:Layout, uiNodeTree:UINodeTree, onChange:() => void, parentNode?:AnyUINode | Nil):UINodeToggler[] {
    const nodeForDevice = getNodesForDevice(uiNodeTree, parentNode, layout, 'kiosk');

    const swingFoundations = _.compact([_.find(nodeForDevice, n => n.type === 'swing_foundations')]);
    const graphs = _(nodeForDevice).filter(n => n.type === 'graph').sortBy(n => n.categories.graph_type).value();
    const modules = getModules(layout, nodeForDevice);

    return _.map(
        [
            ...swingFoundations,
            ...graphs,
            ...modules,
        ],
        n => ({
            layout,
            childNode: n,
            currentDevices: ['kiosk'],
            isToggledOnByDefault: n.type === 'swing_foundations',
            onChange,
        }) as UINodeToggler
    );

}

function createTogglersForFloor(layout:Layout, uiNodeTree:UINodeTree, onChange:() => void, parentNode?:AnyUINode | Nil):UINodeToggler[] {
    const nodesForDevice = getNodesForDevice(uiNodeTree, parentNode, layout, 'floor');

    if(!parentNode) {
        // currently we only support pages as the root ui_node for the floor device
        const pages = _.take(_.filter(nodesForDevice, n => n.type === 'page'), 1); // we only want the first page, for now
        return _.map(
            pages,
            (n, index) => ({
                layout,
                childNode: n,
                currentDevices: ['floor'],
                isToggledOnByDefault: index === 0,
                onChange,
            }) as UINodeToggler
        );
    }

    if(parentNode.type !== 'page') {
        console.warn('createTogglersForFloor: unsupported parent node type', parentNode);
        return [];
    }

    const swingFoundations = _.filter(nodesForDevice, n => n.type === 'swing_foundations');
    const graphs = _.filter(nodesForDevice, n => n.type === 'graph');
    const modules = getModules(layout, nodesForDevice);

    return _.map(
        [
            ...swingFoundations,
            ...graphs,
            ...modules,
        ],
        n => ({
            layout,
            parentNode,
            childNode: n,
            currentDevices: ['floor'],
            isToggledOnByDefault: false,
            onChange,
        }) as UINodeToggler
    );
}

function getNodesForDevice(uiNodeTree:UINodeTree, parentNode:AnyUINode | Nil, layout:Layout, device:Device):AnyUINode[] {
    return _(uiNodeTree.nodes)
        .filter(n => _.includes(n.show_on, device))
        .concat(parentNode
            ? _(uiNodeTree.relations)
                .concat(layout.customizations as UINodeRelation[])
                .filter(r => r.parent_ui_node_id === parentNode?.id && _.includes(r.show_child_on, device))
                .map(r => _.find(uiNodeTree.nodes, n => n.id === r.child_ui_node_id))
                .compact()
                .value()
            : []
        )
        .uniqBy(n => n.id)
        .orderBy(n => n.name?.value ?? n.id)
        .value();
}

function getModules(layout:Layout, nodeForDevice:AnyUINode[]):UIModule[] {
    // we might want to load up modules from all of the user's layouts, not just the current one.
    // for now, the current layout's modules will have to suffice
    return _(layout.customizations)
        .filter(c => lhsOfFirst(c.child_ui_node_id, '.') === 'module')
        .uniqBy(c => c.child_ui_node_id)
        .map(c => _.find(nodeForDevice, n => n.id === c.child_ui_node_id) as UIModule | undefined)
        .compact()
        .value();
}
