import { createWithEqualityFn } from 'zustand/traditional';
import { subscribeWithSelector } from 'zustand/middleware';
import { FavoriteSwing, GlobalStoreState } from './globalStore.types';
import { ANALYSIS_TYPES, PositionNumber, IVideo, Analysis, SwingPosition, Nil, isFiniteNumber, Device, Swing, Activity } from '@common';
import { UIComponentValues } from '../../components/UIComponents/UIComponent.types';
import { useCallback, useEffect, useMemo } from 'react';
import { BehaviorSubject } from 'rxjs';
import _ from 'lodash';
import { useUserSettingsStore } from '../userSettingsStore';
import { Corridor } from '@common';
import { SimplifiedCameraConfig } from '../../utils/types/camera';

const initialReplay = {
    activeId: null,
    videos: [],
    /* eslint-disable @typescript-eslint/no-empty-function */
    onReplayEnded: () => { },
};

export const useGlobalStore = createWithEqualityFn<GlobalStoreState>()(
    subscribeWithSelector((set, get) => ({
        activeSwingID: null,
        comparisonSwingID: null,
        viewingActivityId: null,
        swings: [], // The swings in the current activity
        allUserFavoriteSwings: [],
        loadingSpinnerVisible: false,
        cameraConfig: null,
        activities: [],
        isViewingOldActivity: false, // Would be cool to change this to a computed property
        activePosition: 1,
        currentFrame: 0,
        activityStats: [],
        activeAnnotation: null,
        scrubberProgress: 0,
        replayProgress: 0,
        comparisonProgress: 0,
        showComparison: false,
        forceHideSkeleton: false,
        lastBeastStatus: 'IDLE',
        instantReplay: initialReplay,
        outletElement: undefined,
        clubs: [],
        corridors: [],
        actions: {
            /**
             * Getters
             */
            getNumberOfFavoriteSwings: () => {
                const { swings, allUserFavoriteSwings } = get();
                const favoriteSwingsCount = swings?.filter((s) => s.isFavorite).length || 0;
                const allFavoriteSwingsCount = allUserFavoriteSwings?.filter((s) => s.isFavorite).length || 0;
                return favoriteSwingsCount + allFavoriteSwingsCount;
            },
    
            getComparisonSwing: () => {
                const { swings, allUserFavoriteSwings, comparisonSwingID } = get();
                return [...swings, ...allUserFavoriteSwings].find((swing) => swing.id === comparisonSwingID) || null;
            },
    
            getViewingActivity: () => {
                const { activities, viewingActivityId } = get();
                return activities.find((activity) => activity.id === viewingActivityId) || null;
            },
    
            getActiveSwing: () => {
                const { swings, activeSwingID } = get();
                return swings.find((swing) => swing.id === activeSwingID) || null;
            },
            setSwings:(swings:Swing[]) => {
                set({swings: swings});
            },
            /**
             * Setters
             */
            resetPlaybackProgres:() => {
                set({
                    replayProgress: 0,
                    comparisonProgress: 0,
                });
            },
            toggleSwingFavorite:(val:boolean, id:number, name:string|null = null) => {
                const { swings, allUserFavoriteSwings } = get();
                
                const potentialSwingInMainList = swings.find(s => s.id === id);
                const potentialSwingInFavorites = allUserFavoriteSwings.find(s => s.id === id);

                if(potentialSwingInMainList) {
                    const updatedSwing:Swing = {...potentialSwingInMainList, name: name, isFavorite: val};
                    set({swings: swings.map(s => s.id === id
                        ? updatedSwing
                        : s)});
                }

                if(potentialSwingInFavorites) {
                    const updatedSwing:Swing = {...potentialSwingInFavorites, name: name, isFavorite: val};
                    set({allUserFavoriteSwings: allUserFavoriteSwings.map(s => s.id === id
                        ? updatedSwing
                        : s)});
                }
            },
            setAllUserFavoriteSwings:(swings:Swing[]) => {
                set({allUserFavoriteSwings:swings});
            },
            setActiveSwingID:(id:number) => {
                if(get().activeSwingID !== id) {
                    set({
                        replayProgress: 0,
                        scrubberProgress: 0,
                        currentFrame: 0,
                        activePosition: 1,
                        activeSwingID: id,
                    });
                }
            },
            clearActivities:() => {
                set({
                    activities: []
                });
            },
            /*
            Used to select the latest analysis from the list
            */
            selectTheLatestSwing: () => {
                const { swings } = get();

                // Find the analysis with the highest 'id'
                const idOfLatestSwing = _.maxBy(swings, s => s.id)?.id ?? -1;

                // Update the state
                set({
                    activeSwingID: idOfLatestSwing,
                });
            },
            addVideoToAnalysis: (swingID:number, videos:IVideo[]) => {
                const { swings } = get();

                // Find the analysis that matches the swingId
                const existingAnalysis = _.find(swings, s => s.id === swingID);

                if(existingAnalysis) {
                    // Create a copy of the analyses to modify
                    const swingCopies = [...swings];

                    // Find the index of the analysis to update
                    const swingIndex = _.findIndex(swingCopies, s => s.id === swingID);

                    if(swingIndex !== -1) {
                        const swingToUpdate = swingCopies[swingIndex];

                        // Determine whether to add videos to fullAnalysis or quickAnalysis
                        if(swingToUpdate.fullAnalysis) {
                            // Full analysis exists, add videos to fullAnalysis
                            swingToUpdate.fullAnalysis = {
                                ...swingToUpdate.fullAnalysis,
                                data: {
                                    ...swingToUpdate.fullAnalysis.data,
                                    videos: videos,
                                }
                            };
                        } else if(swingToUpdate.quickAnalysis) {
                            // Quick analysis exists, add videos to quickAnalysis
                            swingToUpdate.quickAnalysis = {
                                ...swingToUpdate.quickAnalysis,
                                data: {
                                    ...swingToUpdate.quickAnalysis.data,
                                    videos: videos,
                                }
                            };
                        }

                        // Update the lastUpdated timestamp
                        swingToUpdate.lastUpdated = Date.now();

                        // Set the updated swingAnalyses state
                        set({ swings: swingCopies });
                    }
                }
            },
            setCameraConfig: (data:SimplifiedCameraConfig) => {
                set({
                    cameraConfig: data
                });
            },
            setActivities: (activities:Activity[]) => {
                set({
                    activities: activities
                });
            },
            setViewingActivityID: (activityID:number) => {
                set({viewingActivityId: activityID});
                const { activities } = get();
                // Find the activity with the given ID
                const viewingActivity = _.find(activities, (activity) => activity.id === activityID);
                
                if(!viewingActivity) {
                    // Handle the case where the activity is not found
                    // console.error(`Activity with ID ${activityID} not found.`);
                    return;
                }
                
                // Determine if the selected activity is the newest
                // Assuming higher IDs correspond to newer activities
                const newestActivity = _.reduce(
                    activities,
                    (latest, activity) =>
                        activity.id > latest.id
                            ? activity
                            : latest, activities[0]
                );

                const isViewingOldActivity = activityID !== newestActivity.id;

                // Update the state
                set({
                    viewingActivity,
                    isViewingOldActivity,
                });
            },
            setIsViewingOldActivity: (val:boolean) => {
                set({
                    isViewingOldActivity: val
                });
            },
            resetSwings: () => {
                set({
                    activeSwingID: -1,
                    swings: [],
                });
            },
            setBeastStatus: (beastStatus) => {
                set({
                    lastBeastStatus: beastStatus,
                });
            },
            triggerReplay: (id, videos) => {
                const resetReplay = () => {
                    set({
                        instantReplay: initialReplay,
                    });
                };

                set({
                    instantReplay: {
                        activeId: id,
                        videos,
                        onReplayEnded: resetReplay,
                    },
                });
            },
            addAnalysisToSwing: (swingID:number, type:ANALYSIS_TYPES, analysis:Analysis) => {
                const exists = _.find(
                    get().swings,
                    (swing) => swing.id === swingID,
                );

                const isQuickAnalysis = type === ANALYSIS_TYPES.QUICK_ANALYSIS;

                if(exists) {
                    // Update the existing analysis
                    set((state) => ({
                        swings: _.map(
                            state.swings,
                            (swingAnalysis) =>
                                swingAnalysis.id === swingID
                                    ? {
                                        ...swingAnalysis,
                                        id: swingID,
                                        activityID: analysis.activityID,
                                        lastUpdated: Date.now(),
                                        quickAnalysis: isQuickAnalysis
                                            ? analysis
                                            : swingAnalysis.quickAnalysis,
                                        fullAnalysis: isQuickAnalysis
                                            ? swingAnalysis.fullAnalysis
                                            : analysis,
                                        isFavorite: false,
                                        name: null,
                                    } as Swing
                                    : swingAnalysis,
                        ),
                    }));
                } else {
                    // Add a new analysis
                    set((state) => ({
                        swings: [
                            ...state.swings,
                            {
                                id: swingID,
                                activityID: analysis.activityID,
                                lastUpdated: Date.now(),
                                quickAnalysis: isQuickAnalysis
                                    ? analysis
                                    : null,
                                fullAnalysis: isQuickAnalysis
                                    ? null
                                    : analysis,
                                isFavorite: false,
                                name: null,
                            } as Swing,
                        ],
                    }));
                }

                return;
            },
            replaceActivityStats: (activityStats) => set({ activityStats }),
            setActivePosition: (position) =>
                set({
                    activePosition: position,
                }),
            setCurrentFrame: (currentFrame) =>
                set({
                    currentFrame,
                }),
            setScrubberProgress: (scrubberProgress) =>
                set({
                    scrubberProgress,
                }),
            setReplayProgress: (progress:number) => {
                set({ replayProgress: progress });
            },
            setComparisonProgress: (progress:number) => {
                set({ comparisonProgress: progress });
            },
            setActiveAnnotation: (annotation) => {
                const currentAnnotation = get().activeAnnotation?.annotation;
                const incomingAnnotation = annotation?.annotation;

                set({
                    activeAnnotation:
                        currentAnnotation === incomingAnnotation
                            ? null
                            : annotation,
                });
            },
            setComparisonSwingID: (id:number|Nil) => {
                const {swings, allUserFavoriteSwings} = get();

                if(!isFiniteNumber(id) || id < 0)
                    set({ showComparison: false, comparisonSwingID: null });

                const comparison = _.find(
                    [...swings, ...allUserFavoriteSwings],
                    s => s.id === id,
                );

                if(!comparison)
                    set({ showComparison: false, comparisonSwingID: null });
                else
                    set({ showComparison: true, comparisonSwingID: id });
            },
            setShowComparison: (showComparison) =>
                set({
                    showComparison,
                }),
            setOutletElement: (element) => {
                set({
                    outletElement: element,
                });
            },
            setClubs(clubs) {
                set({ clubs });
            },
            setCorridors(corridors:Corridor[]) {
                set({ corridors });
            },
            setFavoriteSwings(favorites:FavoriteSwing[]) {
                const favoriteNamesBySwingID = _(favorites)
                    .keyBy(f => f.id)
                    .mapValues(f => f.name)
                    .pickBy(name => !!name)
                    .value();
                const swings = _.map(
                    get().swings,
                    s => {
                        const favoriteName = favoriteNamesBySwingID[s.id];
                        return {
                            ...s,
                            isFavorite: !!favoriteName,
                            name: favoriteName || null,
                        } as Swing;
                    }
                );
                set({ swings });
            }
        },
    })),
);

export const useUIComponentValues = () => {

    //* How to embrace observables
    //* Step 1) Read this: https://rxjs.dev/guide/overview
    //* Step 2) GIFLENS-https://i.imgflip.com/96p51t.jpg
    //* Step 3) Repeat step 1 properly this time
    //* Step 4) GIFLENS-https://media2.giphy.com/media/UusiGY5fm6V3UwNUeW/200.gif

    const [corridor, userSettings] = useUserSettingsStore(
        state => [state.activeCorridor, state.userSettings],
        (prev, next) =>
            (prev[0]?.id || null) === (next[0]?.id || null)
            && _.isEqual(prev[1]?.showCorridorOn, next[1]?.showCorridorOn),
    );

    const [
        swings,
        activeSwingID, comparisonSwingID, showComparison, pPosition,
        activityID,
        setActiveSwingID, setComparisonSwingID, setActivePosition,
    ] = useGlobalStore(
        (state) =>
            [
                state.swings,
                state.activeSwingID,
                state.comparisonSwingID,
                state.showComparison,
                state.activePosition,
                state.viewingActivityId,
                state.actions.setActiveSwingID,
                state.actions.setComparisonSwingID,
                state.actions.setActivePosition,
            ] as const,
        (prev, next) =>
            prev[0]?.length === next[0]?.length
            && prev[1] === next[1]
            && prev[2] === next[2]
            && prev[3] === next[3]
            && prev[5] === next[5]
            && _(prev[0])
                .zip(next[0])
                .every(([a, b]) =>
                    a?.id === b?.id
                    && a?.lastUpdated === b?.lastUpdated
                    && a?.isFavorite === b?.isFavorite
                ),
    );
    
    const setActive = useCallback(
        (values:Partial<UIComponentValues>) => {
            if(values.swing?.id)
                setActiveSwingID(values.swing.id);
            if(values.comparisonSwing?.id)
                setComparisonSwingID(values.comparisonSwing.id);
            if(values.swingPosition) {
                const pPos = parseInt(values.swingPosition.slice(1));
                if(pPos > 0 && pPos < 11)
                    setActivePosition(pPos);
            }
        },
        [setActiveSwingID, setComparisonSwingID, setActivePosition],
    );

    // $ postfix is a naming convention for observables
    const values$ = useMemo(() => {
        // Later on, subject$ should be derived from other observables
        const subject$ = new BehaviorSubject<UIComponentValues>({});
        const setValues = (values:UIComponentValues) => {
            subject$.next({ ...subject$.value, ...values });
            setActive(values);
        };
        subject$.next({ setValues });
        return subject$;
    }, [setActive]);

    useEffect(() => {
        const swingID
            = isFiniteNumber(activeSwingID) && activeSwingID > 0
                ? activeSwingID
                : values$.value.swing?.id
                    ?? swings[swings.length - 1]?.id; // select latest swing by default

        if(!swingID)
            return;

        const swing
            = _.find(swings, (a) => a.id === swingID);

        if(!swing)
            return;
        
        const comparisonSwingIDToFind
            = showComparison && isFiniteNumber(comparisonSwingID) && comparisonSwingID > 0
                ? comparisonSwingID
                : values$.value.comparisonSwing?.id || null;
        const comparisonSwing
            = showComparison && comparisonSwingIDToFind
                ? _.find(swings, (a) => a.id === comparisonSwingIDToFind)
                : undefined;

        const swingPosition:SwingPosition = pPosition
            ? `p${_.clamp(pPosition, 1, 10) as PositionNumber}`
            : values$.value.swingPosition ?? 'p1';

        const showCorridorOn = Array.from(new Set(['floor', ...userSettings.showCorridorOn ?? []] as Device[]));

        const newValues:UIComponentValues = {
            ...values$.value,
            allSwings: swings,
            swing,
            comparisonSwing,
            swingPosition,
            corridorPerDevice: corridor
                ? _.transform(
                    showCorridorOn,
                    (acc, device) => {
                        acc[device] = corridor;
                    },
                    {} as { [device in Device]:Corridor }
                )
                : undefined,
        };
        
        if(_.isEqual(newValues, values$.value))
            return;

        setActive({ swing, comparisonSwing, swingPosition });

        values$.next(newValues);

    },
    [
        swings,
        activeSwingID,
        comparisonSwingID,
        showComparison,
        pPosition,
        values$,
        corridor,
        userSettings,
        activityID,
        setActive
    ]);

    return values$;
};

export const useSelectedAnalysis = (latest=false) => {
    //! Will be deprecated soon
    const [swings, swingID] = useGlobalStore((state) => [state.swings, state.activeSwingID]);

    // Either get the latest (newest) swing, or the active swing.
    const activeSwing = latest
        ? swings[swings.length - 1]
        : _.find(
            swings,
            s => s.id === swingID,
        );

    if(!activeSwing)
        return null;

    return activeSwing?.fullAnalysis
        ?? activeSwing?.quickAnalysis;
};
