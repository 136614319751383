import { typography } from '../../styles/typography.css';
import * as css from './PlaybackSettings.css';
import { motion } from 'framer-motion';
import { ThreeD } from '../ui/icons/ThreeD';
import {
    UserSettingsStoreState,
    useUserSettingsStore,
} from '../../state/userSettingsStore';

export function PlaybackSettings() {
    const swingOptions:UserSettingsStoreState['playbackSettings']['playbackAngle'][] =
    ['Face-on', 'Quarter', 'Back', 'Down the line'];

    const playbackSettings = useUserSettingsStore(
        (state) => state.playbackSettings,
    );
    const setPlaybackSettings = useUserSettingsStore(
        (state) => state.actions.setPlaybackSettings,
    );

    const playbackAngle = playbackSettings?.playbackAngle;
    const playbackSpeed = playbackSettings?.playbackSpeed;
    const instantReplay = playbackSettings?.instantReplay;
    const skeletonOverlay = playbackSettings?.skeletonOverlay;

    return (
        <div className={css.root}>
            <div className={css.headings}>
                <p className={typography({ size: 'h2', weight: 'bold', color: 'dark' })}>
                    Playback Settings
                </p>
                <p
                    className={typography({
                        size: 'paragraph',
                        color: 'dark',
                        lineHeight: '1.2',
                    })}
                >
                    Changes will be reflected on floor monitor
                </p>
            </div>

            <div className={css.setting}>
                <div className={css.instantReplay}>
                    <p
                        className={typography({
                            size: 'h4',
                            color: 'dark',
                            weight: 'bold',
                            tracking: 'positiveLarge',
                            case: 'uppercase',
                        })}
                    >
                        Floor screen instant replay
                    </p>
                    <button
                        className={css.toggle({ isOn: instantReplay })}
                        onClick={() =>
                            setPlaybackSettings({
                                ...playbackSettings,
                                instantReplay: !instantReplay,
                            })
                        }
                    >
                        <motion.div
                            className={css.toggleHandle({ isOn: instantReplay })}
                            layout
                            transition={{
                                type: 'spring',
                                stiffness: 700,
                                damping: 30,
                            }}
                        />
                    </button>
                </div>
                <div className={css.swingReplay}>
                    {swingOptions.map((option, index) => (
                        <button
                            key={option}
                            className={css.swingReplayOption({
                                selected: playbackAngle === option,
                            })}
                            onClick={() => {
                                if(playbackAngle === option) {
                                    setPlaybackSettings({
                                        ...playbackSettings,
                                        playbackAngle: undefined,
                                    });
                                } else {
                                    setPlaybackSettings({
                                        ...playbackSettings,
                                        playbackAngle: option,
                                    });
                                }
                            }}
                        >
                            <p
                                className={css.swingReplayOptionIcon({
                                    selected: playbackAngle === option,
                                })}
                            >
                                {index + 1}
                            </p>
                            <p
                                className={typography({
                                    size: 'h3',
                                    weight: 'bold',
                                })}
                            >
                                {option}
                            </p>
                        </button>
                    ))}
                </div>
            </div>

            <div className={css.setting}>
                <p
                    className={typography({
                        size: 'h4',
                        color: 'dark',
                        weight: 'bold',
                        tracking: 'positiveLarge',
                        case: 'uppercase',
                    })}
                >
                    Playback options
                </p>
                <div className={css.speedSettings}>
                    <button
                        className={css.speedOption({ selected: playbackSpeed === 0.25 })}
                        onClick={() =>
                            setPlaybackSettings({
                                ...playbackSettings,
                                playbackSpeed: 0.25,
                            })
                        }
                    >
                        <p
                            className={typography({
                                size: 'paragraph',
                                weight: 'bold',
                            })}
                        >
                            0.25x
                        </p>
                    </button>
                    <button
                        className={css.speedOption({ selected: playbackSpeed === 0.5 })}
                        onClick={() =>
                            setPlaybackSettings({
                                ...playbackSettings,
                                playbackSpeed: 0.5,
                            })
                        }
                    >
                        <p
                            className={typography({
                                size: 'paragraph',
                                weight: 'bold',
                            })}
                        >
                            0.5x
                        </p>
                    </button>
                    <button
                        className={css.speedOption({ selected: playbackSpeed === 1 })}
                        onClick={() =>
                            setPlaybackSettings({
                                ...playbackSettings,
                                playbackSpeed: 1,
                            })
                        }
                    >
                        <p
                            className={typography({
                                size: 'paragraph',
                                weight: 'bold',
                            })}
                        >
                            1x
                        </p>
                    </button>
                </div>
            </div>
        </div>
    );
}
