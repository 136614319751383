/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';

export const deepComparison:<T>(a:T, b:T) => boolean = _.isEqual;


export const cameraAngleToText = (cameraAngle:string) => {
    switch(cameraAngle) {
        case 'back': {
            return 'Back';
        }
        case 'face_on': {
            return 'Face-on';
        }
        case 'down_the_line': {
            return 'Down the line';
        }
        case 'trail_front': {
            return 'Trail Front';
        }
        default: {
            return 'Unknown';
        }
    }
};