import _ from 'lodash';
import UIComponent from '../../UIComponent';
import ModuleBaseKiosk from '../../../ModuleBase/ModuleBase.kiosk';
import ModuleBaseFloor from '../../../ModuleBase/ModuleBase.floor';
import { localize } from '@common';
import { ImplementationOf } from '../../UIComponent.types';

import * as css from './Module.css';

export const Module:ImplementationOf<'module'>
= ({ node, children, currentDevice }) => {
    if(children.length === 0) {
        console.error('[UIComponent(\'module\')]: No children');
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
    }

    const title = {
        value: node.metadata?.title
            || node.name?.value
            || node.short_name?.value
            || _.startCase(node.id),
    };

    const subtitle = {
        value: localize('layout_editor.panel.module')
    };

    switch(currentDevice) {
        case 'kiosk':
            return (
                <ModuleBaseKiosk title={title}>
                    <div className={css.layout({device: currentDevice})}>
                        {_.map(children, (props) => (
                            <UIComponent key={props.currentNode.id} {...props} />
                        ))}
                    </div>
                </ModuleBaseKiosk>
            );
        case 'floor':
            return (
                <ModuleBaseFloor title={title} subtitle={subtitle}>
                    <div className={css.layout({device: currentDevice})}>
                        {_.take(children, 12).map((props) => (
                            <UIComponent key={props.currentNode.id} {...props} />
                        ))}
                    </div>
                </ModuleBaseFloor>
            );
    }
};
