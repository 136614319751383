import _ from 'lodash';
import { Suspense,  useEffect, useLayoutEffect } from 'react';
import { Canvas, useLoader, useThree } from '@react-three/fiber';
import { Flex, Box } from '@react-three/flex';
import { TextureLoader } from 'three';
import { Plane, Text, OrthographicCamera } from '@react-three/drei';
import useMeasure from 'react-use-measure';

import ComparisonIcon from './comparison_icon.svg';
import { useVideoTexture } from '../../utils/hooks/useVideoTexture';
import { useGlobalStore } from '../../state/globalStore';
import {
    type CameraAngles,
    SimplifiedCameraConfig,
} from '../../utils/types/camera';
import PlaybackScrubber from '../PlaybackScrubber/PlaybackScrubber';
import { Analysis, IVideo, Nil, cameraAngleToText } from '@common';
import { NoSwingSelectedProps } from './VideoReplay.types';

import * as css from './VideoReplay.kiosk.css';

const NoSwingSelected = ({ width, height }:NoSwingSelectedProps) => {
    return (
        <mesh>
            <planeGeometry args={[width, height]} />
            <meshBasicMaterial color="#040810" />
            <Text
                fontWeight="bold"
                color="#94A3B8"
                fontSize={12}
            >
                No swing selected
            </Text>
        </mesh>
    );
};


type SceneProps = {
    textureUrl:string;
    cameraConfig:SimplifiedCameraConfig
    scale?:number;
    cameraAngle?:CameraAngles;
    isComparison?:boolean;
}

function Scene({
    textureUrl,
    scale = 1,
    cameraAngle = 'face_on',
    cameraConfig,
    isComparison
}:SceneProps) {
    const { height: videoHeight, width: videoWidth } = cameraConfig.intrinsics[cameraAngle] ?? {};
    const videoTexture = useVideoTexture(textureUrl as string, {
        // Pause video
        start: false,
        // Unsuspend when enough has been buffered
        unsuspend: 'canplaythrough',
        // Force a full fetch of the video before playing
        mode: 'fetch'
    });

    const svgTexture = useLoader(TextureLoader, ComparisonIcon);


    useLayoutEffect(() => {
        videoTexture.source.data.currentTime = 0;
        const unsubscribe = useGlobalStore.subscribe(
            (state) => [state.comparisonProgress, state.replayProgress],
            ([comparisonProgress, replayProgress]) => {
                const progress = isComparison
                    ? comparisonProgress
                    : replayProgress;

                if(!videoTexture.source?.dataReady) {
                    return;
                }
  
                const duration = videoTexture.source.data.duration;
                videoTexture.source.data.currentTime = progress * duration;
            }
        );
        return () => {
            videoTexture.source.data.currentTime = 0;
            unsubscribe();
        };
    }, [videoTexture, isComparison]);


    if(!videoTexture?.source?.dataReady) {
        return null;
    }

    return (
        <group scale={scale}>
            <Plane args={[videoWidth, videoHeight, 1, 1]} position={[0, 0, 0]}>
                <Text
                    fontWeight='bold'
                    color="#fff"
                    anchorX="left"
                    anchorY="top"
                    fontSize={55}
                    position={[-videoWidth / 2 + 100, videoHeight / 2 - 100, 0]}
                >
                    {isComparison
                        ? 'COMPARISON'
                        : 'CURRENT'
                    }
                </Text>
                <Text
                    fontWeight='bold'
                    color="#fff"
                    anchorX="left"
                    anchorY="top"
                    fontSize={80}
                    position={[-videoWidth / 2 + 100, videoHeight / 2 - 180, 0]}
                >
                    {cameraAngleToText(cameraAngle)}
                </Text>
                <meshBasicMaterial map={videoTexture} />
            </Plane>
            {/* Render the SVG icon */}
            {
                isComparison && (
                    <Plane args={[100, 100]} position={[videoWidth / 2 - 150, videoHeight / 2 - 150, 0]}>
                        <meshBasicMaterial map={svgTexture} />
                    </Plane>)
            }
        </group>
    );
}

type RendererProps = {
  videos:IVideo[];
  comparisonVideos:IVideo[] | Nil,
  scale:number;
  cameraConfig:SimplifiedCameraConfig;
  showComparison:boolean;
};

function Renderer({
    videos,
    comparisonVideos,
    scale = 1,
    cameraConfig,
    showComparison,
}:RendererProps) {
    const { height: canvasHeight, width: canvasWidth } = useThree((state) => state.viewport);

    const cameraAngles:CameraAngles[] = showComparison
        ? ['face_on', 'face_on', 'down_the_line', 'down_the_line']
        : ['back', 'down_the_line', 'face_on', 'trail_front'];

    return (
        <Flex
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="space-evenly"
            alignItems="flex-start"
            height={canvasHeight}
            width={canvasWidth}
            position={[-canvasWidth / 2, canvasHeight / 2, -1000]}
            plane="xy"
        >
            {_.map(cameraAngles, (angle, index) => {
                const isComparison = showComparison && index % 2 === 1;
                const videosForAngle = isComparison
                    ? comparisonVideos
                    : videos;
                const isValid = Boolean(videosForAngle?.[index]);
                const textureUrl = _.find(videosForAngle!, (video) => video.name === angle)?.url ?? '';

                return (
                    <Box
                        key={index}
                        height={canvasHeight / 2}
                        width={canvasWidth / 2}
                        centerAnchor
                    >
                        {isValid
                            ? (
                                <Scene
                                    textureUrl={textureUrl}
                                    cameraAngle={angle}
                                    cameraConfig={cameraConfig}
                                    scale={scale}
                                    isComparison={isComparison}
                                />
                            )
                            : (
                                <NoSwingSelected height={canvasHeight / 2} width={canvasWidth / 2} />
                            )}
                    </Box>
                );
            })}
        </Flex>
    );
}

const VIDEO_DIMENSIONS = {
    width: 510,
    height: 428,
    sourceWidth: 2464,
    sourceHeight: 2064,
};

type VideoReplayProps = {
  activeSwingAnalysis:Analysis;
  comparisonSwingAnalysis?:Analysis | Nil;
  cameraConfig?:SimplifiedCameraConfig;
  speed:0.25 | 0.5 | 1;
  showComparison:boolean;
}
export function VideoReplay({
    activeSwingAnalysis,
    comparisonSwingAnalysis,
    cameraConfig,
    speed,
    showComparison
}:VideoReplayProps) {

    const [ref, bounds] = useMeasure();
    const videosPerRow = 2;
    const totalWidthNeeded = VIDEO_DIMENSIONS.sourceWidth * videosPerRow;

    const scale = bounds.width / totalWidthNeeded;

    const heightRatio =
    VIDEO_DIMENSIONS.sourceHeight / VIDEO_DIMENSIONS.sourceWidth;

    // 73vh is the most height we can allow for the videos so the controls are still visible
    // width is calculated based on the video aspect ratio and the height of 73
    const domHeight = '73vh';
    const domWidth = `${heightRatio * 73}vw`;
    const idealWidth = 920;

    useEffect(() => {
        const { actions }= useGlobalStore.getState();
        actions.resetPlaybackProgres();
    }, []);

    if(!activeSwingAnalysis.data.videos || !cameraConfig) {
        return <div className={css.emptyState}></div>;
    }

    return (
        <Suspense fallback={null}>
            <Canvas
                ref={ref}
                flat
                dpr={[1, 2]}
                shadows={false}
                style={{
                    borderRadius: 8,
                    position: 'relative',
                    width: `min(${domWidth}, ${idealWidth}px)`,
                    minWidth: idealWidth,
                    height: domHeight,
                }}
            >
                <OrthographicCamera makeDefault far={100000} near={0.00001} zoom={1}>
                    <Renderer
                        comparisonVideos={comparisonSwingAnalysis?.data.videos}
                        videos={activeSwingAnalysis.data.videos}
                        scale={scale}
                        cameraConfig={cameraConfig}
                        showComparison={showComparison}
                    />
                </OrthographicCamera>
            </Canvas>
            <PlaybackScrubber speed={speed} activeSwingAnalysis={activeSwingAnalysis} comparisonSwingAnalysis={comparisonSwingAnalysis} />
        </Suspense>
    );
}
