import { Unit } from './ui-node.types';

/** This interface shouldn't be used. Supabase CLI can generate types from the database. */
export interface ParameterValue {
    id:number;
    name:string;
    display_name:string;
    unit:Unit;
    value:number;
    activityStats?:{ mean:number; std:number };
}

export interface IMeasurement {
    measurement_id:number;
    measurement_name:string | null;
    measurement_description:string | null;
    track_relation:{
        keypoint_id:number;
    };
    type_id:number;
    type_name:string;
    type_display_name:string;
    type_description:string;
    axis_names:string[];
    unit_notation:string;
    value:number[][];
}

export interface IVideo {
    url:string;
    name:string;
    metadata:{
        totalFrames:number | null;
        sourceWidth:number;
        sourceHeight:number;
    };
}


export enum ANALYSIS_TYPES {
    QUICK_ANALYSIS = 1, // <-- GIFLENS-https://media2.giphy.com/media/l378giAZgxPw3eO52/200.gif
    FULL_ANALYSIS = 2, // <-- GIFLENS-https://media4.giphy.com/media/65zUoOU09HL3uUKokQ/200.gif
}

export enum CommunicationMessageType {
    /**
     *  When the beast finishes processing a video
     */
    SWING_VIDEOS_AVAILABLE = 'SWING_VIDEOS_AVAILABLE',
    /**
     * When new analysis id is available for a swing
     */
    ANALYSIS_AVAILABLE = 'ANALYSIS_AVAILABLE',
    /**
     *
     */
    ANALYSIS_STATE = 'ANALYSIS_STATE',
    /**
     * Sent from beast to kiosk indicating that the ball is ready to be shot
     */
    BEAST_STATUS = 'BEAST_STATUS',
    /**
     * Activity id sent to beast to link the swings with the correct activity id
     */
    CURRENT_ACTIVITY_ID = 'CURRENT_ACTIVITY_ID',

    INSTRUCTION = 'BEAST_INSTRUCTION',

    SWING_ANALYSIS_FAILED = 'SWING_ANALYSIS_FAILED',
}

/** This type is using uppercase letters and should be redefined like this:`
 *   export type Segmentation = { [p in SwingPosition]:number };
 * `
 */
export type Segmentation = {
    P1:number;
    P2:number;
    P3:number;
    P4:number;
    P5:number;
    P6:number;
    P7:number;
    P8:number;
    P9:number;
    P10:number;
};

export type Analysis = {
    id:number;
    activityID:number;
    data:{
        analysis?:{
            timestamp:number;
            isQuickAnalysis:boolean;
            frames:unknown[];
            measurements:IMeasurement[];
            parameter_values:{ [key:string]:ParameterValue };
            skeleton:{ [boneId:string]:[number[], number[], number[]] } | null;
            bones:{ start_point_id:number; end_point_id:number }[];
            segmentation:Segmentation;
        },
        videos?:IVideo[] | null;
    };
};


/** Avoid using this. Use `SwingPosition` instead. */
export type PositionNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
export type SwingPosition = `p${PositionNumber}`;

export type MeasurementAxis = 'x' | 'y' | 'z' | 'w';

export type SwingFoundationsSequence = {
    subtitle:string;
    parameters:SwingFoundationsSequenceParameter[];
};

export type SwingFoundationsParameterData = {
    title:string;
    parameter_id:string;
    data:{
        title?:string;
        // TODO: Replace number | string with number | null and make it work for empty states
        value?:number | string | null;
        corridor?:number[];
        // should be unit, not type
        type?:'deg' | 'cm' | 'percent' | 'cm/s' | 'cm/s^2' | 'deg/s' | '?' | null;
        annotation?:string | null;
        cameraAngles?:string[];
        comparisonValue?:number | string;
        activityStats?:{
            mean:number;
            std:number;
        };
    };
};

export type SwingFoundationsSequenceParameter = {
    key:string;
    title:string;
    data:SwingFoundationsParameterData[];
};

export type AnalysisCloudStatus = 'completed' | 'in_progress' | 'error';
