import { type ReactNode } from 'react';
import { DotsSix as DotsSixIcon } from '@phosphor-icons/react';
import {
    motion,
    Reorder,
    useDragControls,
    useMotionValue,
} from 'framer-motion';

import * as css from './DisplayOrderEditor.css';
import { ActionButton } from '../buttons/ActionButton/ActionButton';
import _ from 'lodash';

export const displayOrderEditorCSS = css;

export interface DisplayOrderEditorProps<T> {
	items:T[];
    getItemProps:(item:T) => { displayOrder:number, id:string; content?:ReactNode };
    onReorder:(reorderedItems:T[]) => void;
    onClickOnItem?:(item:T) => void;
    onDeleteItem?:(item:T) => void;
}

export function DisplayOrderEditor<T>({
    items,
    getItemProps,
    onReorder,
    onDeleteItem,
    onClickOnItem,
}:DisplayOrderEditorProps<T>) {

    const orderedItems = _(items)
        .map((item:T) => ({ item, ...getItemProps(item) }))
        .sortBy(x => x.displayOrder)
        .value();
    
    return (
        <Reorder.Group
            className={css.root}
            axis="y"
            onReorder={(newOrderIDs) => {
                const newOrder = _(newOrderIDs)
                    .map(id => _.find(orderedItems, x => x.id === id)?.item)
                    .compact()
                    .value();
                onReorder(newOrder);
            }}
            values={_.map(orderedItems, item => item.id)}
        >
            {_.map(orderedItems, ({ item, id, content }) => {
                return (
                    <Reorderable<T>
                        key={id}
                        id={id}
                        item={item}
                        content={content}
                        onClick={onClickOnItem}
                        onRemove={onDeleteItem}
                    />
                );
            })}
        </Reorder.Group>
    );
}

interface ReorderableProps<T> {
	id:string;
	item:T;
    content:ReactNode,
	onClick?:(item:T) => void;
    onRemove?:(item:T) => void;
}

function Reorderable<T>({
    id,
    item,
    content,
    onClick,
    onRemove,
}:ReorderableProps<T>) {
    const y = useMotionValue(0);
    const dragControls = useDragControls();
    const isClickable = typeof onClick === 'function';
    const isRemovable = typeof onRemove === 'function';

    return (
        <li className={css.item}>
            <Reorder.Item
                as="div"
                className={css.reorderable}
                value={id}
                style={{ y }}
                dragListener={false}
                dragControls={dragControls}
                onClick={isClickable
                    ? () => onClick(item)
                    : undefined
                }
            >
                {content &&
                    <div className={css.content}>
                        {content}
                    </div>
                }
                <motion.div
                    className={css.reorderableIcon}
                    onPointerDown={(event) => dragControls.start(event)}
                >
                    <DotsSixIcon width={24} height={24} />
                </motion.div>
            </Reorder.Item>
            {isRemovable && (
                <motion.div layoutId={id}>
                    <ActionButton icon="minus" buttonSize="tiny" onClick={() => onRemove(item)} />
                </motion.div>
            )}
        </li>
    );
}
