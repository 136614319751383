import _ from 'lodash';
import { SupabaseClient } from '@supabase/supabase-js';

import { transformSkeletonToFramesFirst } from '../utils/skeletonUtils';
import { convertToSimplifiedConfig } from '../utils/hooks/useSupabaseCameraConfig/utils';
import { CameraConfig, SimplifiedCameraConfig } from '../utils/types/camera';
import {
    ANALYSIS_TYPES,
    AnalysisCloudStatus,
    CommunicationMessageType,
    type IVideo,
    type Analysis,
    fetchAllSwingsForActivity,
    Supabase,
    isFiniteNumber,
    fetchAllUserActivites,
} from '@common';
import { useCommunicationSocketStore } from '../state/communicationSocketStore';
import { useGlobalStore } from '../state/globalStore';
import {
    createNewActivity,
} from '../utils/dbFunctions';
import { useUserSettingsStore } from '../state/userSettingsStore';
import {
    AnalysisFailedMessage,
    AnalysisMessage,
    AnalysisResponse,
    AnalysisStateMessage,
    BeastState,
    BeastStatusMessage,
    ManagerParams,
    VideoResponse,
    VideosMessage,
} from './DataManager.types';
import { SocketPayload } from '../state/syncSocketStore';

const CAMERA_NAMES = ['back', 'down_the_line', 'face_on', 'trail_front'];

// TODO #_#
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type PartialData = any;

//! GIFLENS-https://media3.giphy.com/media/a9xhxAxaqOfQs/200.gif
//! WHY is this the ONLY class in the entire codebase!
class DataManager {
    private static instance:DataManager | null = null;

    /* We use this to remember the connection state of the beast,
      ensuring that we only send the activity ID and activation message
      when transitioning from disconnected to connected. */
    private isConnected = false;

    private supabase:Supabase;
    private userId:string;
    private boothSessionId:number;
    private activityId:number;
    private partialAnalysesCache:{
        [key:string]:{
            analysisData:{
                id:number;
                data:PartialData;
            } | null;
            videoData:{
                id:number;
                data:PartialData;
            } | null;
        };
    } = {};

    private communicationSubscription:(() => void) | null = null;

    public cameraConfig?:SimplifiedCameraConfig = undefined;

    private constructor({ supabase }:ManagerParams) {
        this.supabase = supabase;
        this.userId = '';
        this.boothSessionId = -1;
        this.activityId = -1;
        this.partialAnalysesCache = {};
    }

    private async init(
        supabase:SupabaseClient,
        userId:string,
        boothSessionId:number,
    ) {
        this.userId = userId;
        this.boothSessionId = boothSessionId;
        
        const activityId = await this.getActivityId(
            boothSessionId,
            (payload:unknown) => {
                if(_.isPlainObject(payload))
                    useCommunicationSocketStore.getState().actions.sendCommunicationMessage(payload as SocketPayload);
            },
        );

        
        const { setActivityId } = useUserSettingsStore.getState().actions;
        setActivityId(activityId);

        this.cameraConfig = await this.getCameraConfig();
        this.supabase = supabase;

        this.communicationSubscription = useCommunicationSocketStore.subscribe(
            // @ts-expect-error bound
            this.handleBeastCommunication.bind(this),
        );
        
        if(isFiniteNumber(activityId))
            this.activityId = activityId;
        this.subscribeToFullAnalysis();
        
        // We don't want to wait for this, just let it run in the background
        void this.loadActivity(activityId);

        // We don't want to wait for this, just let it run in the background
        void fetchAllUserActivites(supabase, userId).then(data => {
            if(data) {
                useGlobalStore.getState().actions.setActivities(data);
            }
        });
    }

    /**
     * MARK: - Public methods
     */

    public static getInstance() {
        return this.instance;
    }

    public static disconnect() {
        if(!this.instance) {
            return;
        }

        // Unsub
        if(typeof this.instance.communicationSubscription === 'function') {
            this.instance.communicationSubscription();
        }

        // Tell beast to go in IDLE mode.
        useCommunicationSocketStore.getState().actions.sendCommunicationMessage({
            //! TODO: What is this? 'type' does not exist!
            type: CommunicationMessageType.INSTRUCTION,
            content: {
                action: 'GO_IDLE',
            },
        });
    }

    /**
     * Loads an activity
     */
    public async loadActivity(activityID:number) {
        try {
            // Show a loading screen while swings are loading
            useGlobalStore.setState({
                loadingSpinnerVisible: true,
            });

            // Don't need to load an activity you're already viewing

            // Empty the list of swings
            useGlobalStore.getState().actions.resetSwings();

            useGlobalStore.getState().actions.setViewingActivityID(activityID);

            const swings = await fetchAllSwingsForActivity(this.supabase, activityID);
           
            useGlobalStore.getState().actions.setSwings(swings);

            // Select the newest swing
            useGlobalStore.getState().actions.selectTheLatestSwing();
        } catch{
            // no-op
        } finally {
            // Loading done - hide the loader
            useGlobalStore.setState({
                loadingSpinnerVisible: false,
            });
        }
    }

    public static async initialize({
        supabase,
        userId,
        boothSessionId,
    }:ManagerParams):Promise<DataManager | null> {
        // Initialize singleton
        if(
            this.instance === null ||
            boothSessionId !== this.instance?.boothSessionId
        ) {
            try {
                const {
                    data: { user },
                } = await supabase.auth.getUser();

                if(user) {
                    this.instance = new DataManager({
                        supabase,
                        userId,
                        boothSessionId,
                    });
                    await this.instance.init(supabase, userId, boothSessionId);
                } else {
                    // TODO: Handle auth redirection here?
                    // Perhaps from authRedirectionCallback param.
                    console.warn('No user logged in. DataManager instance not created.');
                    return null;
                }
            } catch(e) {
                console.log('Error initializing DataManager:', e);
                return null;
            }
        }

        // Return singleton
        return this.instance;
    }

    /**
     * MARK: - Private methods
     */

    private async getAnalysisJsonDumpById(id:number) {
        const { data, error } = await this.supabase.rpc('get_analysis_json_dump', {
            _analysis_id: id,
        });

        if(error) {
            return null;
        }
        return data;
    }

    private subscribeToFullAnalysis() {
        type SupabaseChangePayload = {
            commit_timestamp:string;
            eventType:'UPDATE';
            new:{
                swing_id:number;
                type_id:1 | 2; // 1 = quick analysis, 2 = full analysis
                created_at:string;
                status:AnalysisCloudStatus;
            };
        };

        const onChange = async(payload:SupabaseChangePayload) => {
            const id = payload.new.swing_id;
            const status = payload.new.status;
            const typeId = payload.new.type_id;

            if(status !== 'completed') {
                return;
            }

            // We are only interested in completed full analysis data
            if(typeId === ANALYSIS_TYPES['QUICK_ANALYSIS']) {
                return;
            }

            const existingSwingAnalysis = _.find(
                useGlobalStore.getState().swings,
                (analysis) => analysis.id === id,
            );

            // Bail early if analysis doesn't exist and there's nothing to update.
            if(!existingSwingAnalysis) {
                return;
            }

            // Bail early if we already have this swing's full analysis
            if(existingSwingAnalysis.fullAnalysis) {
                return;
            }

            const analysisId = await this.getAnalysisId(id);

            // At this stage we have a quick analysis that's ready to be upgraded.
            const analysisJsonResponse =
                await this.getAnalysisJsonDumpById(analysisId);

            if(!analysisJsonResponse) {
                return;
            }

            const { skeleton, bones, measurements, parameter_values, segmentation } =
                analysisJsonResponse;

            // Keep using existing videos from the Quick Analysis if they exist.
            const existingVideos = existingSwingAnalysis.quickAnalysis?.data.videos;

            const timestamp = new Date(payload.new.created_at).getTime();
            const frames = transformSkeletonToFramesFirst(skeleton);
            const videos = existingVideos ?? (await this.getVideos(id));

            const analysis:Analysis = {
                id,
                activityID: this.activityId,
                data: {
                    analysis: {
                        timestamp,
                        isQuickAnalysis: false,
                        frames,
                        bones,
                        measurements,
                        parameter_values,
                        segmentation,
                        skeleton,
                    },
                    videos,
                },
            };

            // Save to store
            this.saveSwingToStore(id, payload.new.type_id, analysis);
        };

        return this.supabase
            .channel('supabase_realtime')
            .on(
                'postgres_changes',
                { event: 'UPDATE', schema: 'public', table: 'full_analysis_job_queue' },

                async(payload) =>
                    await onChange(payload as unknown as SupabaseChangePayload),
            )
            .subscribe();
    }

    private async saveSwingToStore(
        swingId:number,
        type:ANALYSIS_TYPES,
        swing:Analysis,
    ) {
        return useGlobalStore.getState().actions.addAnalysisToSwing(swingId, type, swing);
    }

    private async processPartialSwing(
        id:number,
        type:'VIDEOS' | 'ANALYSIS',
        partialSwing:VideoResponse | AnalysisResponse,
    ) {
        // Initialize a partial analysis if none exists.
        if(!this.partialAnalysesCache[id]) {
            this.partialAnalysesCache[id] = {
                videoData: null,
                analysisData: null,
            };
        }

        switch(type) {
            case 'VIDEOS': {
                const { total_frames, urls, dimensions } =
                    partialSwing as VideoResponse;

                const videos = _.map(CAMERA_NAMES, (name) => ({
                    name,
                    url: urls[name],
                    metadata: {
                        totalFrames: total_frames,
                        sourceWidth: dimensions?.sourceWidth ?? 2464,
                        sourceHeight: dimensions?.sourceHeight ?? 2064,
                    },
                }));

                this.partialAnalysesCache[id].videoData = {
                    id,
                    data: {
                        videos,
                        analysis:
                            this.partialAnalysesCache[id].analysisData?.data.analysis || null,
                    },
                };

                this.triggerInstantReplay(id, videos);

                break;
            }
            case 'ANALYSIS': {
                const { analysis_url } = partialSwing as AnalysisResponse;

                const data = await fetch(analysis_url);
                const json = await data.json();

                const timestamp = Date.now();
                const frames = transformSkeletonToFramesFirst(json.skeleton);

                this.partialAnalysesCache[id].analysisData = {
                    id,
                    data: {
                        analysis: {
                            timestamp,
                            isQuickAnalysis: frames.length <= 10,
                            frames,
                            bones: json.bones,
                            measurements: json.measurements,
                            parameter_values: json.parameter_values,
                            segmentation: json.segmentation,
                            skeleton: json.skeleton,
                        },
                        videos:
                            this.partialAnalysesCache[id].videoData?.data.videos || null,
                    },
                };
            }
        }

        const { videoData, analysisData } = this.partialAnalysesCache[id];

        // Once we have both analysis and videos, save to store
        if(videoData && analysisData) {
            // A complete swing
            const analysis:Analysis = {
                id,
                activityID: this.activityId,
                data: {
                    videos: videoData.data.videos,
                    analysis: analysisData.data.analysis,
                },
            };

            console.log(
                'WEBSOCKET: %c%s',
                'color: white; background: rebeccapurple;',
                `Adding swing ${id} from WS.`,
            );
            this.saveSwingToStore(id, ANALYSIS_TYPES['QUICK_ANALYSIS'], analysis);

            delete this.partialAnalysesCache[id];
        }
    }

    private async triggerInstantReplay(id:number, videos:IVideo[]) {
        return useGlobalStore.getState().actions.triggerReplay(id, videos);
    }

    private async updateActivityStats(lastSwingId:number) {
        const maxRetries = 5;
        const delay = 2000;

        if(typeof lastSwingId !== 'number') {
            return;
        }

        const retry = async(attempt:number):Promise<void> => {
            if(attempt > maxRetries) {
                console.error('Max retries reached. Data is not up to date.');
                return;
            }

            const { data, error } = await this.supabase
                .from('measured_parameter_activity_stats')
                .select('parameter_id, mean, std, newest_swing_id')
                .eq('activity_id', this.activityId);

            if(error) {
                return;
            }

            if(!Array.isArray(data)) {
                return;
            }

            // TODO(for Robert): Having to dig into data[0] for this info is bad.
            const dataIsUpToDate = data[0]?.newest_swing_id === lastSwingId;

            if(dataIsUpToDate) {
                useGlobalStore.getState().actions.replaceActivityStats(data);
            } else {
                setTimeout(() => retry(attempt + 1), delay);
            }
        };

        await retry(1);
    }

    private reflectBeastStatus(status:string) {
        if(
            // Statuses we want reflected
            status === 'WAITING_FOR_BALL' ||
            status === 'ANALYZING_SWING' ||
            status === 'BALL_DETECTED' ||
            status === 'BALL_STEADY' ||
            status === 'SWING_ANALYSIS_FAILED' ||
            status === 'IDLE' ||
            status === 'FINALIZING'
        ) {
            useGlobalStore.getState().actions.setBeastStatus(status);
        }
    }

    private async handleBeastCommunication(state:BeastState):Promise<void> {
        // We use this.isConnected to avoid sending activity ID and ACTIVATE message on every state change,
        // only send them when transitioning from disconnected to connected.
        if(state.isConnected && !this.isConnected) {
            console.log('Sending ACTIVATE and activity id: ', this.activityId);
            state.actions.sendCommunicationMessage({
                //! TODO: What is this? 'type' does not exist!
                type: CommunicationMessageType.CURRENT_ACTIVITY_ID,
                content: {
                    activity_id: this.activityId,
                },
            });
            state.actions.sendCommunicationMessage({
                //! TODO: What is this? 'type' does not exist!
                type: CommunicationMessageType.INSTRUCTION,
                content: {
                    action: 'ACTIVATE',
                },
            });
        }

        // Update this.isConnected to reflect the current connection state of the beast
        this.isConnected = state.isConnected;

        if(state.payload) {
            const { type } = state.payload;
            switch(type) {
                case 'BEAST_STATUS': {
                    const content = state.payload.content as BeastStatusMessage;
                    this.reflectBeastStatus(content.status);
                    break;
                }
                case 'SWING_VIDEOS_AVAILABLE': {
                    const content = state.payload.content as VideosMessage;
                    const { swing_id, ...rest } = content;
                    await this.processPartialSwing(swing_id, 'VIDEOS', rest);
                    break;
                }
                case 'ANALYSIS_AVAILABLE': {
                    const content = state.payload.content as AnalysisMessage;
                    const { swing_id, ...rest } = content;
                    await this.processPartialSwing(swing_id, 'ANALYSIS', rest);
                    break;
                }
                case 'ANALYSIS_STATE': {
                    const content = state.payload.content as AnalysisStateMessage;
                    const { state: analysisState } = content;
                    console.log('TODO: Handle analysis state', analysisState);
                    break;
                }
                case 'SWING_ANALYSIS_FAILED': {
                    const content = state.payload.content as AnalysisFailedMessage;
                    this.reflectBeastStatus('SWING_ANALYSIS_FAILED');
                    const { swing_id } = content;
                    if(swing_id === null)
                        return;
                    break;
                }
            }
        }
    }

    private async getCameraConfig() {
        const { data, error } = await this.supabase
            .from('booth_sessions')
            .select('camera_calibrations (*)')
            .eq('id', this.boothSessionId)
            .order('id', { ascending: false })
            .limit(1)
            .single();

        if(error) {
            throw error;
        }

        return convertToSimplifiedConfig(
            (
                data.camera_calibrations as unknown as {
                    calibration:CameraConfig;
                }
            ).calibration,
        );
    }

    private async getAnalysisId(swingId:number) {
        const { data, error } = await this.supabase
            .from('swing_analysis_link_view')
            .select('full_analysis_id')
            .eq('swing_id', swingId)
            .single();

        if(error || !data.full_analysis_id) {
            return null;
        }

        return data.full_analysis_id;
    }

    private async createNewActivity(
        sendCommunicationMessage?:(message:unknown) => void,
    ) {
        const activityId = await createNewActivity(
            this.supabase as SupabaseClient,
            this.userId,
            this.boothSessionId,
        );
        if(typeof activityId === 'number') {
            if(sendCommunicationMessage) {
                // Activate the beast
                sendCommunicationMessage({
                    type: CommunicationMessageType.INSTRUCTION,
                    content: {
                        action: 'ACTIVATE',
                    },
                });
                sendCommunicationMessage({
                    type: CommunicationMessageType.CURRENT_ACTIVITY_ID,
                    content: {
                        activity_id: activityId,
                    },
                });
            }
        }
        return activityId;
    }

    private async getActivityId(
        boothSessionId:number,
        sendCommunicationMessage?:(message:unknown) => void,
    ) {
        // Get the latest activity ID found for the booth session.
        const { data, error } = await this.supabase
            .from('activities')
            .select('*')
            .eq('booth_session_id', boothSessionId)
            .order('start_time', { ascending: false })
            .limit(1)
            .single();

        if(error || !data?.id) {
            console.error(error);
            return this.createNewActivity(sendCommunicationMessage);
        }
        
        return data?.id;
    }

    private async getVideos(swingId:number):Promise<IVideo[]> {
        if(!this.cameraConfig) {
            return [];
        }

        // TODO: We should be fetching videos with `this.supabase.storage.getBucket('swings')` but it's not working.
        const getVideoUrl = (cameraId:string) => {
            // @ts-expect-error This field is protected
            return `${this.supabase.storage.url}/object/public/swings/${swingId}/frontend_recording/${cameraId}.mp4`;
        };

        const getMetadata = async():Promise<IVideo['metadata']> => {
            const response = await fetch(
                // @ts-expect-error This field is protected
                `${this.supabase.storage.url}/object/public/swings/${swingId}/frontend_recording/metadata.json`,
            );
            const {
                frame_count,
                height,
                width,
            }:{ frame_count:number; height:number; width:number } =
                await response.json();

            return {
                totalFrames: frame_count,
                sourceWidth: width,
                sourceHeight: height,
            };
        };

        const metadata = await getMetadata();

        // Construct video urls
        return _(this.cameraConfig.camera_id_to_name)
            .toPairs()
            .filter(([, cameraName]) => _.includes(CAMERA_NAMES, cameraName))
            .map(([cameraId, cameraName]) => ({
                name: cameraName,
                url: getVideoUrl(cameraId),
                metadata: metadata,
            }))
            .value();
    }
}

export default DataManager;
