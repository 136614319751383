import { type ReactNode, useId, memo } from 'react';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { AnimatePresence, motion } from 'framer-motion';

import { typography } from '../../styles/typography.css';
import { colorVars } from '../../styles/colors.css';
import { useToggle } from '../../utils/hooks/useToggle';

import { Comparison } from '../ui/icons/Comparison';
import { ThreeD } from '../ui/icons/ThreeD';
import { Dots } from '../ui/icons/Dots';
import { useGlobalStore } from '../../state/globalStore';
import { Layout } from '../ui/icons/Layout';
import { useNavigate } from 'react-router-dom';
import { Waveform } from '../ui/icons/Waveform';
import Tag from '../ui/Tag';
import { OpenCloseButton } from '../ui/OpenCloseButton/OpenCloseButton';
import { isFiniteNumber, L10n, Nil } from '@common';

import * as css from './ModuleBase.kiosk.css';
import _ from 'lodash';

type IconName = 'comparison' | '3d' | 'dots' | 'waveform';

export type ModuleBaseButton = {
    label:string;
    onClick:() => void;
    icon?:IconName;
    isActive?:boolean;
};

type ModuleBaseProps = {
    children:ReactNode;
    title:L10n|Nil;
    subtitle?:string;
    buttons?:ModuleBaseButton[];
    isStatic?:boolean;
    isPending?:boolean;
    key?:string;
};

const getIcon = (iconName:IconName, color:string) => {
    switch(iconName) {
        case 'comparison': {
            return <Comparison color={color} width={16} />;
        }
        case '3d': {
            return <ThreeD color={color} width={16} />;
        }
        case 'dots': {
            return <Dots color={color} width={16} />;
        }
        case 'waveform': {
            return <Waveform color={color} width={16} />;
        }
    }
};

function ModuleBase({ key, children, title, subtitle, buttons, isStatic, isPending }:ModuleBaseProps) {
    const navigate = useNavigate();
    const id = useId();
    const [isOpen, toggle] = useToggle(false);
    const shouldOpen = (isStatic || isOpen) && !isPending;

    const [comparisonSwingID] = useGlobalStore((state) => [state.comparisonSwingID]);

    const showComparisonButton = isFiniteNumber(comparisonSwingID);

    const isInteractive = !isPending && !isStatic;

    return (
        <div
            key={key}
            className={css.frame({ isInteractive })}
            style={assignInlineVars({
                [css.borderColor]: !isInteractive
                    ? 'transparent'
                    : colorVars.blueGray200,
            })}
        >
            <div className={css.top} role="button" onClick={() => isInteractive && toggle()}>
                <div className={css.topLeft}>
                    <div className={css.header}>
                        <div className={css.heading}>
                            <h3
                                className={typography({
                                    size: 'h3',
                                    weight: 'bold',
                                    color: 'dark',
                                })}
                            >
                                {title?.value ?? 'My Unnamed Module'}
                            </h3>
                        </div>
                        {
                            subtitle
                            && (
                                <p
                                    className={typography({
                                        size: 'h4',
                                        weight: 'bold',
                                        case: 'uppercase',
                                        color: 'dark',
                                    })}
                                >
                                    {subtitle}
                                </p>
                            )
                        }
                    </div>

                    <div className={css.topButtons}>{
                        !isPending
                        && _.isArray(buttons)
                        && _.map(buttons, (button) => {
                            if(button.label === 'Comparison' && !showComparisonButton)
                                return null;

                            return (
                                <button
                                    key={button.label}
                                    className={css.topButton({
                                        selected: !!button?.isActive,
                                        variant: 'primary',
                                    })}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        button.onClick();
                                    }}
                                    disabled={!shouldOpen}
                                >
                                    {
                                        button?.icon
                                        && getIcon(
                                            button?.icon,
                                            !button?.isActive
                                                ? colorVars.blueGray600
                                                : shouldOpen
                                                    ? colorVars.blue500
                                                    : colorVars.blueGray400,
                                        )
                                    }
                                    <p
                                        className={typography({
                                            size: 'h4',
                                            color: 'dark',
                                            weight: 'bold',
                                            tracking: 'positiveLarge',
                                            case: 'uppercase',
                                        })}
                                    >
                                        {button.label}
                                    </p>
                                </button>
                            );
                        })
                    }</div>
                </div>

                { isStatic
                    ? (
                        <button
                            className={css.topButton({ selected: false, variant: 'secondary' })}
                            onClick={() => {
                                navigate('/kiosk/swing-foundations-editor');
                            }}
                        >
                            <Layout width={16} />
                            <p
                                className={typography({
                                    size: 'h4',
                                    color: 'dark',
                                    weight: 'bold',
                                    tracking: 'positiveLarge',
                                    case: 'uppercase',
                                })}
                            >
                                Parameters
                            </p>
                        </button>
                    )
                    : (
                        <div className={css.actions}>
                            {isPending && <Tag value="Full Analysis Pending" variant="pending" />}

                            <OpenCloseButton
                                iconColor={isPending
                                    ? colorVars.blueGray300
                                    : colorVars.blueGray500}
                                iconBorderColor={isPending
                                    ? colorVars.blueGray200
                                    : colorVars.blueGray300}
                                isOpen={isOpen}
                            />
                        </div>
                    )
                }
            </div>

            <AnimatePresence initial={false}>
                {shouldOpen && (
                    <motion.div
                        key={id}
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        style={{ overflow: 'hidden' }}
                        variants={{
                            open: { height: 'auto' },
                            collapsed: { height: 0 },
                        }}
                        transition={{
                            duration: 0.3,
                            type: 'tween',
                        }}
                    >
                        {children}
                    </motion.div>
                )}

            </AnimatePresence>

        </div>
    );
}

export default memo(ModuleBase);
