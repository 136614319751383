import { Fragment } from 'react';
import _ from 'lodash';

import TopBar from '../components/TopBar';
import { useUIComponentValues, useGlobalStore } from '../state/globalStore';
import { useSyncSocketStore } from '../state/syncSocketStore';
import { VideoReplay } from '../components/VideoReplay/VideoReplay.floor';
import { useUserSettingsStore } from '../state/userSettingsStore';
import UIComponentsLayout from '../components/UIComponentsLayout';
import { Club, Layout, Tag, UINodeTree } from '@common';

import * as css from './FloorScreen.css';
import { IS_DEV } from '../utils/consts';


export default function FloorScreen() {

    document.title = 'Floor';
    
    const userSettings = useUserSettingsStore((state) => [state.activeLayout, state.uiNodes, state.playbackSettings] as [Layout, UINodeTree, {instantReplay:boolean}]);
    const syncPayload = useSyncSocketStore((state) => [state.payload?.activeLayout, state.payload?.activityClub, state.payload?.activityTags, state.payload?.playbackSettings] as [Layout|undefined, Club|undefined, Tag[]|undefined, {instantReplay?:boolean}]);

    const [layout, club, tags, playbackSettings] = syncPayload;
    const [cloudLayout, uiNodeTree, userPlaybackSettings] = userSettings;

    const shouldInstantReplay = IS_DEV
        ? false
        : Boolean(playbackSettings?.instantReplay ?? userPlaybackSettings?.instantReplay);

    const instantReplay = useGlobalStore((state) => state.instantReplay); // TODO: stop using global store
    const values$ = useUIComponentValues();

    const activeLayout = layout || cloudLayout;

    return (
        <Fragment key="floor-screen">
            {shouldInstantReplay && instantReplay.activeId && (
                <VideoReplay
                    videos={instantReplay.videos}
                    onReplayEnded={() => {
                        instantReplay.onReplayEnded();
                    }}
                />
            )}
            <div className={css.root}>
                <TopBar
                    club={club?.short_name || ''}
                    tags={_.map(tags, t => t.name) || []}
                    selectedLayout={layout?.name ?? 'No layout'}
                    progressBar={<> </>}
                />
                {uiNodeTree && activeLayout &&
                    <UIComponentsLayout
                        uiNodeTree={uiNodeTree}
                        layout={activeLayout}
                        currentDevice="floor"
                        values$={values$}
                    />
                }
            </div>
        </Fragment>
    );
}
