import { Nil } from './type-utils';

export type StaticL10nID = keyof typeof l10ns;

// TODO: un-hardcode l10ns and load this from the database
const l10ns = {
    'device.kiosk': { value: 'Tablet' },
    'device.floor': { value: 'Floor Screen' },
    'axis.position.x': { value: 'Thrust' },
    'axis.position.y': { value: 'Sway' },
    'axis.position.z': { value: 'Lift' },
    'axis.position.w': { value: 'Shift' },
    'axis.angle.x': { value: 'Tilt' },
    'axis.angle.y': { value: 'Bend' },
    'axis.angle.z': { value: 'Rotation' },
    'axis.angle.w': { value: 'Angle' },
    'axis.position.x_long_name': { value: 'Thrust (X)' },
    'axis.position.y_long_name': { value: 'Sway (Y)' },
    'axis.position.z_long_name': { value: 'Lift (Z)' },
    'axis.position.w_long_name': { value: 'Shift (W)' },
    'axis.angle.x_long_name': { value: 'Tilt (X)' },
    'axis.angle.y_long_name': { value: 'Bend (Y)' },
    'axis.angle.z_long_name': { value: 'Rotation (Z)' },
    'axis.angle.w_long_name': { value: 'Angle (W)' },

    'swing_position.p1': { value: 'P1 — Setup'},
    'swing_position.p2': { value: 'P2 — Takeaway'},
    'swing_position.p3': { value: 'P3'},
    'swing_position.p4': { value: 'P4 — Top of Backswing'},
    'swing_position.p5': { value: 'P5'},
    'swing_position.p6': { value: 'P6'},
    'swing_position.p7': { value: 'P7 — Impact'},
    'swing_position.p8': { value: 'P8'},
    'swing_position.p9': { value: 'P9'},
    'swing_position.p10': { value: 'P10'},

    'category.metric.linear_displacement_from_p1': { value: 'Linear Displacement' },
    'category.metric.speed': { value: 'Speed' },
    'category.metric.linear_velocity': { value: 'Linear Velocity' },
    'category.metric.angular_position_absolute': { value: 'Angular Position' },
    'category.metric.angle': { value: 'Angle' },
    
    'layout_editor.title': { value: 'Layout Editor' },
    'layout_editor.save_button': { value: 'Save' },
    'layout_editor.cancel_button': { value: 'Cancel' },
    'layout_editor.clear_button': { value: 'Clear' },
    'layout_editor.create_module_button': { value: 'Create' },
    'layout_editor.sequences': { value: 'Sequences' },
    'layout_editor.key_parameter': { value: 'Key Parameter' },

    'layout_editor.panel.swing_foundations': { value: 'Swing Foundations' },
    'layout_editor.panel.module': { value: 'Custom Modules' },
    'layout_editor.panel.key': { value: 'Key Graphs' },
    'layout_editor.panel.linear_position': { value: 'Linear Position Graphs' },
    'layout_editor.panel.angular_position': { value: 'Angular Position Graphs' },
    'layout_editor.panel.linear_displacement_from_p1': { value: 'Linear Displacement Graphs' },
    'layout_editor.panel.angular_displacement_from_p1': { value: 'Angular Displacement Graphs' },
    'layout_editor.panel.linear_velocity': { value: 'Linear Velocity Graphs' },
    'layout_editor.panel.angular_velocity': { value: 'Angular Velocity Graphs' },
    'layout_editor.panel.linear_acceleration': { value: 'Linear Acceleration Graphs' },
    'layout_editor.panel.angular_acceleration': { value: 'Angular Acceleration Graphs' },
    'layout_editor.panel.angle': { value: 'Angle Graphs' },
    'layout_editor.panel.distance': { value: 'Distance Graphs' },
    'layout_editor.panel.speed': { value: 'Speed Graphs' },
    'layout_editor.panel.ratio': { value: 'Ratio Graphs' },

    'layout_editor.key_parameter.save_button': { value: 'Select' },
    'layout_editor.key_parameter.cancel_button': { value: 'Cancel' },
    'layout_editor.custom_module.save_button': { value: 'Done' },
    'layout_editor.custom_module.cancel_button': { value: 'Discard Changes' },

    'activity_navigation.header': {value: 'Activity'},
    'activity_navigation.subheader': {value: 'Swings'},
    'activity_navigation.menu_label': {value: 'Actions'},
    'activity_navigation.item_mark_as_favorite': {value: 'Favorite'},
    'activity_navigation.item_remove_as_favorite': {value: 'Remove favorite'},
    'activity_navigation.item_mark_as_comparison': {value: 'Comparison'},
    'activity_navigation.item_remove_as_comparison': {value: 'Remove comparison'},
    'activity_navigation.item_delete': {value: 'Delete'},
} as const;
export function localize(id:StaticL10nID|Nil):string;
export function localize(id:StaticL10nID, defaultValue:string):string;
export function localize(id:StaticL10nID|Nil, defaultValue:string|null):string|null;
export function localize(id:StaticL10nID|Nil, defaultValue:string|Nil = '') {
    return id && l10ns[id]?.value || defaultValue || id;
}
export const getL10n = (l10n_id:StaticL10nID) => {
    const obj = l10ns[l10n_id];
    return obj
        ? { l10n_id, ...obj }
        : { l10n_id, value: '' };
};
