import _ from 'lodash';
import { AnimatePresence, motion } from 'framer-motion';

import { Menu, MenuItem } from '../../../Menu/Menu';
import { Comparison as ComparisonIcon} from '../../../ui/icons/Comparison';
import { TrashIcon } from '../../../ui/icons/TrashIcon';
import { FolderStar as FolderStarIcon } from '../../../ui/icons/FolderStar';
import { Star as StarIcon } from '../../../ui/icons/Star';
import { IS_DEV } from '../../../../utils/consts';

import * as css from './ActivityNavigationItem.css';
import { localize } from '@common';

export interface ActivityNavigationItemProps {
    readonly header:string;
    readonly swingID:number; // for debugging
    readonly label:string;
    readonly status:'waiting'|'analyzing'|'ready'|'error';
    readonly isSelected?:boolean;
    readonly isComparison?:boolean;
    readonly isFavorite?:boolean;
    readonly onSelect?:(id:number) => void;
    readonly onToggleComparison?:(val:boolean) => void;
    readonly onToggleFavorite?:(val:boolean) => void;
    readonly onDelete?:(id:number) => void;
}

export default function ActivityNavigationItem({
    header,
    swingID,
    label,
    status,
    isSelected,
    isComparison,
    isFavorite,
    onSelect,
    onToggleComparison,
    onToggleFavorite,
    onDelete,
}:ActivityNavigationItemProps) {
    return (
        <li>
            <div
                role="button"
                className={css.item({ isSelected })}
                onClick={(e) => {
                    e.stopPropagation();
                    onSelect?.(swingID);
                }}
            >
                <div className={css.itemLeft}>
                    <div className={css.itemHeader({ status })}>
                        {header}
                    </div>
                    <AnimatePresence>
                        {isComparison && (
                            <motion.div
                                key={`${swingID}-comparisonIcon`}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                <ComparisonIcon
                                    width={16}
                                    color={css.iconColors.comparison}
                                />
                            </motion.div>
                        )}
                    </AnimatePresence>
                    
                    <AnimatePresence>
                        {isFavorite && (
                            <motion.div
                                key={`${swingID}-favoriteIcon`}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                <StarIcon
                                    width={16}
                                    color={css.iconColors.favorite}
                                />
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
                <div className={css.itemValue({ status })}>
                    <p className={css.itemValueLabel({ isLongText: label.length > 6})}>
                        {label ?? '—'}
                    </p>

                    {IS_DEV && (
                        <span
                            data-name="DEBUG"
                            className={css.debuggingData}
                        >
                            #{swingID}
                        </span>
                    )}
                </div>
                <div className={css.menuWrapper}>
                    <Menu
                        label={localize('activity_navigation.menu_label')}
                        align="right"
                        buttonSize={18}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {_.isBoolean(isFavorite) && (
                            <MenuItem
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onToggleFavorite?.(!isFavorite);
                                }}
                                disabled={!onToggleFavorite}
                            >
                                <FolderStarIcon
                                    width={24}
                                    color={
                                        onToggleFavorite
                                            ? css.iconColors.favorite
                                            : css.iconColors.disabled
                                    }
                                />
                                <span>
                                    {isFavorite
                                        ? localize('activity_navigation.item_remove_as_favorite')
                                        : localize('activity_navigation.item_mark_as_favorite')
                                    }
                                </span>
                            </MenuItem>
                        )}
                        {_.isBoolean(isComparison) && (
                            <MenuItem
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onToggleComparison?.(!isComparison);
                                }}
                                disabled={!onToggleComparison || isSelected}
                            >
                                <ComparisonIcon
                                    width={24}
                                    color={
                                        onToggleComparison
                                            ? css.iconColors.comparison
                                            : css.iconColors.disabled
                                    }
                                />
                                <span>
                                    {isComparison
                                        ? localize('activity_navigation.item_remove_as_comparison')
                                        : localize('activity_navigation.item_mark_as_comparison')
                                    }
                                </span>
                            </MenuItem>
                        )}
                        <MenuItem
                            onClick={(e) => {
                                e.stopPropagation();
                                onDelete?.(swingID);
                            }}
                            disabled={!onDelete}
                        >
                            <TrashIcon color={
                                onDelete
                                    ? css.iconColors.delete
                                    : css.iconColors.disabled
                            }/>
                            <span>
                                {localize('activity_navigation.item_delete')}
                            </span>
                        </MenuItem>
                    </Menu>
                </div>
            </div>
        </li>
    );
}
