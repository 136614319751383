/* eslint-disable no-restricted-syntax */
import * as css from './ActivityOverview.css';
import ElvaLogoName from '../../components/ui/Logo/ElvaLogoName';
import { typography } from '../../styles/typography.css';
import { ArrowClockwise } from '../../components/ui/icons/ArrowClockwise';
import { FolderStar } from '../../components/ui/icons/FolderStar';
import { Club as ClubIcon } from '../../components/ui/icons/Club';
import { SearchInput } from '../../components/SearchInput/SearchInput';
import { useState } from 'react';
import { sub } from 'date-fns';

import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { Star } from '../../components/ui/icons/Star';
import { Clock } from '../../components/ui/icons/Clock';
import { useGlobalStore } from '../../state/globalStore';
import { ClubTableModal } from '../../components/ClubTable/ClubTableModal';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/ui/Button';
import { ActivitiesListProps, ActivityOverViewItemProps } from './ActivityOverview.types';
import Spinner from '../../components/Spinner/Spinner';
import DataManager from '../../DataManager';
import { Activity, Club, Swing } from '@common';
import { formatDate, formatTime } from '@common';
import _ from 'lodash';

export function ActivityOverview() {
    const [filter, setFilter] = useState('');
    const initialStartDate = sub(new Date().setHours(0, 0, 0), { years: 1 });
    const [startDate, setStartDate] = useState<Date | null>(initialStartDate);
    const [endDate, setEndDate] = useState<Date | null>(new Date());
    const [favoritesOn, setFavoritesOn] = useState(false);
    const [clubFilter, setClubFilter] = useState<Club | undefined>(undefined);
    const [modalOpen, setModalOpen] = useState(false);
    const activities = useGlobalStore(state => state.activities);
    const numberOfFavoriteSwings = useGlobalStore(state => state.actions.getNumberOfFavoriteSwings());
    const navigate = useNavigate();

    const dateRangeActivities = activities.filter((activity) => {
        const activityDate = new Date(activity.start_time);
        return (
            (!startDate || activityDate >= startDate) &&
      (!endDate || activityDate <= endDate)
        );
    });

    const filteredSwings = dateRangeActivities.filter((activity) => {
        const hasFavorites = favoritesOn
            ? activity.swings?.some((swing) => swing.isFavorite)
            : true;
        const matchesClubFilter = clubFilter
            ? activity.club_id === clubFilter.id
            : true;
        const matchesFilter = filter.length
            ? activity.tags?.some((tag) => tag.tag.name.includes(filter))
            : true;

        return hasFavorites && matchesClubFilter && matchesFilter;
    });

    const resetFilter = () => {
        setClubFilter(undefined);
        setFavoritesOn(false);
        setFilter('');
        setStartDate(initialStartDate);
        setEndDate(new Date());
    };


    const handleActivityClick = async(activity:Activity) => {
    
        if(activity) {
            const dm = DataManager.getInstance();
            if(!dm) {
                return;
            }

            dm.loadActivity(activity.id);
        }
        navigate('/kiosk');
    };

    const handleCloseClick = () => {
        navigate('/kiosk');
    };

    return (
        <div className={css.root}>
            <div className={css.header}>
                <ElvaLogoName />
                <div className={css.rightSide}>
                    <Button variant='secondary' onClick={handleCloseClick}>Close</Button>
                </div>
            </div>
            <div className={css.content}>
                <div className={css.sidebar}>
                    <div className={css.filterBox}>
                        <div className={css.filterBoxTopBottom}>
                            <p
                                className={typography({
                                    size: 'h2',
                                    color: 'dark',
                                    weight: 'bold',
                                    lineHeight: '1.2',
                                })}
                            >
                                Activities
                            </p>
                            <p>{filteredSwings.length}</p>
                        </div>
                        <div className={css.datesRange}>
                            <p
                                className={typography({
                                    size: 'h4',
                                    color: 'dark',
                                    weight: 'bold',
                                    case: 'uppercase',
                                    tracking: 'positiveLarge',
                                })}
                            >
                                Date range
                            </p>
                            <div className={css.dates}>
                                <div className={css.dateBox}>
                                    <p
                                        className={typography({
                                            size: 'h4',
                                            color: 'dark',
                                            weight: 'bold',
                                            case: 'uppercase',
                                            tracking: 'positiveLarge',
                                            lineHeight: '1.2',
                                        })}
                                    >
                                        From
                                    </p>
                                    <DatePicker
                                        className={css.date}
                                        selected={startDate}
                                        onChange={(date) => {
                                            date?.setHours(0, 0, 0);
                                            setStartDate(date);
                                        }}
                                    />
                                </div>
                                <div className={css.dateBox}>
                                    <p
                                        className={typography({
                                            size: 'h4',
                                            color: 'dark',
                                            weight: 'bold',
                                            case: 'uppercase',
                                            tracking: 'positiveLarge',
                                            lineHeight: '1.2',
                                        })}
                                    >
                                        To
                                    </p>
                                    <DatePicker
                                        className={css.date}
                                        selected={endDate}
                                        onChange={(date) => {
                                            date?.setHours(23, 59, 59);
                                            setEndDate(date);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <SearchInput
                            value={filter}
                            onChange={setFilter}
                            placeholder="Filter by keywords"
                        />
                        <div className={css.filterBoxTopBottom}>
                            <button
                                onClick={() => setFavoritesOn(!favoritesOn)}
                                className={css.toggleIconButton({ active: favoritesOn })}
                            >
                                <FolderStar color="inherit" />
                                <p
                                    className={typography({
                                        size: 'h4',
                                        weight: 'bold',
                                        case: 'uppercase',
                                        tracking: 'positiveLarge',
                                    })}
                                >
                                    Favorites
                                </p>
                            </button>
                            <button
                                onClick={() => setModalOpen(true)}
                                className={css.borderIconButton}
                            >
                                <ClubIcon />
                                <p
                                    className={typography({
                                        size: 'h3',
                                        color: 'dark',
                                        weight: 'bold',
                                    })}
                                >
                                    {clubFilter
                                        ? clubFilter.long_name
                                        : 'Filter by club'}
                                </p>
                            </button>
                        </div>
                    </div>
                    <button onClick={resetFilter} className={css.borderIconButton}>
                        <ArrowClockwise />
                        <p
                            className={typography({
                                size: 'h4',
                                color: 'dark',
                                weight: 'bold',
                                case: 'uppercase',
                                tracking: 'positiveLarge',
                            })}
                        >
                            Reset Filters
                        </p>
                    </button>
                </div>
                <div className={css.activities}>
                    <div className={css.favorites}>
                        <div className={css.favoriteCard}>
                            <p
                                className={typography({
                                    size: 'h3',
                                    color: 'dark',
                                    weight: 'bold',
                                })}
                            >
                                All favorite swings
                            </p>
                            <div className={css.tags}>
                                <div className={css.tag}>
                                    <p>Swing</p>
                                </div>
                                <div className={css.tag}>
                                    <p>
                                        {numberOfFavoriteSwings}
                                    </p>
                                    <Star />
                                </div>
                            </div>
                        </div>
                        <div className={css.favoriteCard}>
                            <p
                                className={typography({
                                    size: 'h3',
                                    color: 'dark',
                                    weight: 'bold',
                                })}
                            >
                                ELVA comparison swings
                            </p>
                            <div className={css.tag}>
                                <p>0</p>
                                <Star />
                            </div>
                        </div>
                    </div>

                    <ActivitiesList activities={filteredSwings} onActivityClicked={handleActivityClick} />

                </div>
            </div>
            <ClubTableModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                onClubClick={(club) => {
                    if(clubFilter?.id === club.id) {
                        setClubFilter(undefined);
                    } else {
                        setClubFilter(club);
                    }
                    setModalOpen(false);
                }}
                selectedClub={clubFilter}
            />
        </div>
    );
}


/**
 * The list of activities
 */
export const ActivitiesList = ({ activities, onActivityClicked }:ActivitiesListProps) => {
    return (
        <div className={css.activitiesList}>
            {activities?.length <= 0 && <Spinner size='m' />}
            
            {activities.map((activity, index) => {
                const isCurrentActivity = index === 0
                    ? true
                    : false;
                return (
                    <ActivityOverViewItem
                        key={activity.id}
                        isCurrentActivity={isCurrentActivity}
                        activity={activity}
                        index={index}
                        onClick={() => {
                            onActivityClicked(activity);
                        }}
                        starredSwingsCount={0} // TODO: - Implement
                        noOfSwings={activity.swing_count ?? 0}
                    />
                );
            })}
        </div>
    );
};


const ActivityOverViewItem = ({ isCurrentActivity = false, activity, index, starredSwingsCount, onClick, noOfSwings }:ActivityOverViewItemProps) => {
    return (
        <div key={activity.id} className={`${css.activity} ${css.activityItem}`}
            style={{ animationDelay: `${index * 0.03}s` }}
            onClick={onClick}
        >
            <div className={css.activityHeader}>
                <p
                    className={typography({
                        size: 'h3',
                        color: 'dark',
                        weight: 'bold',
                        lineHeight: '1.2',
                    })}
                >
                    {formatDate(activity.start_time)}
                </p>
                <Clock width={16} variant="border" />
                <p
                    className={typography({
                        size: 'h3',
                        color: 'dark',
                        weight: 'bold',
                        lineHeight: '1.2',
                    })}
                >
                    {formatTime(activity.start_time)}
                </p>
            </div>
            <div className={css.tags}>
                {isCurrentActivity && <div className={css.greenTag}>
                    CURRENT ACTIVITY
                </div>}
                {activity.club_id && (
                    <div className={css.tag}>
                        {activity?.club?.short_name}
                    </div>
                )}
                <div className={css.swingCountTag}>
                    <div className={css.numberOfSwings}>
                        {noOfSwings}
                    </div>
                    <div className={css.numberOfStarredSwings}>
                        {starredSwingsCount}
                        <Star />
                    </div>
                </div>
                {activity.tags?.map((tag) => {
                    const t = tag.tag;
                    return (
                        <div key={tag.tag.id} className={css.tag}>
                            {t.name}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
