import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import _ from 'lodash';
import { Observable } from 'rxjs';

import { localize, isType,  unitConversionMap, Nil, Unit, deepComparison, DEFAULT_KEY_PARAMETER_ID, Swing } from '@common';
import { ImplementationOf, UIComponentValues } from '../../UIComponent.types';
import { useObservable } from '../../../../utils/hooks/useObservable';
import { map, distinctUntilChanged, scan } from 'rxjs/operators';
import ActivityNavigationItem from './ActivityNavigationItem';

import * as css from './ActivityNavigation.css';
import { useGlobalStore } from '../../../../state/globalStore';
import { useContext, useState } from 'react';
import { UserSettingsContext } from '../../../../utils/contexts/UserSettingsContext';
import SwingNameModal from '../../../SwingNameModal';

export const ActivityNavigation:ImplementationOf<'activity_navigation'>
= ({ currentDevice, children, values$ }) => {
    
    const parameterNode = _(children)
        .map(x => x.currentNode)
        .find(x => isType(x, 'parameter'));
    
    const parameterID = parameterNode?.parameter?.id ?? DEFAULT_KEY_PARAMETER_ID;

    const parameterName
        = parameterNode?.name?.value //! disgusting hack because we don't localize anything yet
        ?? parameterID.replace(/_/g, ' '); //! disgusting hack because we don't localize anything yet
    
    const parameterUnit = parameterNode?.parameter?.unit ?? 'm';

    const props:Props = {
        parameterName,
        parameterID,
        parameterUnit,
        values$
    };

    switch(currentDevice) {
        case 'kiosk':
            return <Kiosk {...props} />;
        case 'floor':
            return <Floor {...props} />;
        default:
            return <div></div>;
    }
};

const getDisplayValue = (parameterID:string, parameterUnit:Unit, swing:Swing|Nil) => {
    const parameter
        = swing?.fullAnalysis?.data?.analysis?.parameter_values?.[parameterID]
        ?? swing?.quickAnalysis?.data?.analysis?.parameter_values?.[parameterID];


    if(parameter) {
        const p = unitConversionMap[parameter.unit](parameter.value);
        return `${p.value}${p.symbol}`;
    }

    return '— ' + unitConversionMap[parameterUnit](undefined).symbol;
};

const useAllSwings = (parameterID:string, parameterUnit:Unit, values$:Observable<UIComponentValues>) =>
    useObservable(
        values$,
        map(values => _(values.allSwings)
            .compact() // shallow copy with nil values removed
            .map(swing => ({
                id: swing.id,
                value: getDisplayValue(parameterID, parameterUnit, swing),
                isFavorite: swing.isFavorite,
                swing,
            }))
            .sortBy(x => -x.id)
            .value()
        ),
        distinctUntilChanged((a, b) =>
            _(a).zip(b)
                .every(([x, y]) =>
                    x?.id === y?.id
                    && x?.value === y?.value
                    && x?.isFavorite === y?.isFavorite
                )
        ),
        scan(
            // this is here for so that sorting works when simulating golf swings (ids are all over the place)
            // sort by the order in which the analyses are added (newest first)
            // if multiple analyses are added at the same time they are sorted by id
            (before, after) => _.sortBy(after, x => [_.findIndex(before, y => y.id === x.id), -x.id])
        )
    );


interface Props {
    readonly parameterName:string;
    readonly parameterID:string;
    readonly parameterUnit:Unit;
    readonly values$:Observable<UIComponentValues>;
}

function Kiosk({ parameterName, parameterID, parameterUnit, values$ }:Props) {
    const navigate = useNavigate();
    const { markSwingAsFavorite } = useContext(UserSettingsContext);
    const setComparisonSwingID = useGlobalStore(state => state.actions.setComparisonSwingID);
    const [showSwingNameModal, setShowSwingNameModal] = useState(false);
    const [tempSwingId, setTempSwingId] = useState<number|null>(null);
    const toggleSwingFavorite = useGlobalStore(state => state.actions.toggleSwingFavorite);
    const swings = useGlobalStore(state => state.swings);
    
    const [selectedSwingAnalysisID, comparisonSwingAnalysisID, setValues] = useObservable(
        values$,
        map(values => [
            values.swing?.id,
            values.comparisonSwing?.id,
            values.setValues
        ] as const),
        distinctUntilChanged(deepComparison)
    );

    const onToggleComparison = (val:boolean, id:number) => {
        const swing = _.find(swings, s => s.id === id);
        
        if(!val)
            setComparisonSwingID(null);

        setValues?.({
            comparisonSwing: comparisonSwingAnalysisID === id
                ? undefined
                : swing
        });
    };
    
    const onToggleFavorite = (val:boolean, id:number) => {
        if(val) {
            setTempSwingId(id);
            setShowSwingNameModal(true);
        } else {
            toggleSwingFavorite(false, id, null);
            markSwingAsFavorite({ id, name: null });
        }
    };
    
    const onSwingNameSubmit = (name:string) => {
        if(tempSwingId) {
            toggleSwingFavorite(true, tempSwingId, name);
            markSwingAsFavorite({ id: tempSwingId, name });
            
            // We don't want to mark the selected swing as compoarison
            // If there is only one swing in the activity then that swing is always selected
            if(swings.length > 1) {
                onToggleComparison(true, tempSwingId);
            }
            
            setTempSwingId(null);
        }
    };
    
    const onSelect = (swing:Swing, id:number) => {
        setValues?.({ swing });
        // Comparison swing cannot also be the selected swing
        if(comparisonSwingAnalysisID === id) {
            onToggleComparison(false, id);
        }
    };

    return (
        <div className={css.kiosk.root}>
            <SwingNameModal
                isOpen={showSwingNameModal}
                setIsOpen={(val) => setShowSwingNameModal(val)}
                onSubmit={(name) => onSwingNameSubmit(name)}
            />
            <div className={css.kiosk.header}>
                <h3 className={css.kiosk.headerText}>
                    {localize( 'activity_navigation.header' )}
                </h3>
                <button
                    onClick={() => {
                        // Navigate to LayoutEditor page and open the key-parameter modal.
                        navigate('/kiosk/layout-editor', {state: {isEditingKeyParameter: true}});
                    }}
                    className={css.kiosk.activityNavigationParameter}
                >
                    <h4 className={css.kiosk.activityNavigationParameterText}>
                        {parameterName}
                    </h4>
                </button>
                <div className={css.kiosk.subheader}>
                    <p className={css.kiosk.subheaderText}>
                        {localize('activity_navigation.subheader')}
                    </p>
                </div>
            </div>
            <div className={css.kiosk.sidebar}>
                <motion.ul className={css.kiosk.list}>{
                    _.map(
                        swings,
                        (swing, index) =>
                            <ActivityNavigationItem
                                key={swing.id}
                                header={(swings.length - index) + ''}
                                swingID={swing.id}
                                label={getDisplayValue(parameterID, parameterUnit, swing)}
                                status={swing.fullAnalysis
                                    ? 'ready'
                                    : 'analyzing'
                                }
                                isSelected={selectedSwingAnalysisID === swing.id}
                                isComparison={comparisonSwingAnalysisID === swing.id}
                                isFavorite={swing.isFavorite}
                                onSelect={() => {
                                    onSelect(swing, swing.id);
                                }}
                                onToggleComparison={(val) => onToggleComparison(val, swing.id)}
                                onToggleFavorite={(val) => onToggleFavorite(val, swing.id)}
                                // onDelete
                            />
                    )
                }</motion.ul>
            </div>
        </div>
    );
}

function Floor({ parameterName, parameterID, parameterUnit, values$ }:Props) {

    const defaultDisplayValue = getDisplayValue(parameterID, parameterUnit, null);
    const allSwings = useAllSwings(parameterID, parameterUnit, values$);
    const latestSwing = allSwings[0];

    // GIFLENS-https://media4.giphy.com/media/Ty9Sg8oHghPWg/200.gif
    const isAnalyzing = useGlobalStore((state) => state.lastBeastStatus === 'ANALYZING_SWING');

    return (
        <div className={css.floor.root}>
            <div className={css.floor.header}>
                <p className={css.floor.headerText}>
                    {localize('activity_navigation.header')}
                </p>
                <p className={css.floor.parameterName}>
                    {parameterName}
                </p>
            </div>
            <ul className={css.floor.list}>
                {(isAnalyzing || !latestSwing?.id) && !!latestSwing && (
                    <li>
                        <span
                            className={css.floor.item({ isAnalyzing })}
                        >
                            <div className={css.floor.itemNumber}>
                                <span className={css.floor.itemNumberText} >
                                    {allSwings.length + 1}
                                </span>
                            </div>
                            <div className={css.floor.itemValue}>
                                <p className={css.floor.itemValueText}>
                                    {defaultDisplayValue}
                                </p>
                            </div>
                        </span>
                    </li>
                )}
                {_.map(allSwings, ({ value, id }, index) => {
                    const latestAnalysisID = latestSwing?.id ?? -1;
                    return (
                        <li key={index}>
                            <span
                                className={css.floor.item({ isAnalyzing: id === latestAnalysisID })}
                            >
                                <div className={css.floor.itemNumber}>
                                    <span className={css.floor.itemNumberText}>
                                        {(allSwings.length - index) + 1}
                                    </span>
                                </div>
                                <div className={css.floor.itemValue}>
                                    <p className={css.floor.itemValueText}>
                                        {value}
                                    </p>
                                </div>
                            </span>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}
