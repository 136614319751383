/* eslint-disable no-restricted-syntax */
import _ from 'lodash';
import DynamicGraph from '../../../DynamicGraph/DynamicGraph';
import { Segmentation, Analysis } from '@common';
import {
    ImplementationOf,
    isPropsForType,
    UIComponentProps,
} from '../../UIComponent.types';
import { getL10n, ALL_AXES, UIGraphLine } from '@common';
import { LinesPerUnit } from './Graph.types';
import ModuleBaseKiosk from '../../../ModuleBase/ModuleBase.kiosk';
import ModuleBaseFloor from '../../../ModuleBase/ModuleBase.floor';
import { map, distinctUntilChanged } from 'rxjs';
import { useObservable } from '../../../../utils/hooks/useObservable';

import { frame as ModuleBaseKioskRootCSS } from '../../../ModuleBase/ModuleBase.kiosk.css';

import { root as ModuleBaseFloorRootCSS } from '../../../ModuleBase/ModuleBase.floor.css';

function getMeasurementByName(analysis:Analysis, measurementName:string) {
    return analysis.data.analysis?.measurements.find(
        (m) => m.measurement_name === measurementName,
    );
}

function getSegmentation(analysis:Analysis):Segmentation | null {
    return analysis.data.analysis?.segmentation ?? null;
}

function parseAndGroupLines(children:UIComponentProps[]):LinesPerUnit {
    return _(children)
        .filter((props) => isPropsForType<UIGraphLine>(props, 'graph_line'))
        .filter(({ currentNode: { time_series } }) => !!time_series?.unit)
        .map(({ currentNode: { id, time_series, axis, metadata }, metadataOverride }) => ({
            id,
            axis,
            name: getL10n(
                `axis.${
                    time_series.unit.startsWith('rad')
                        ? 'angle'
                        : 'position'
                }.${axis || 'w'}_long_name`
            ),
            time_series,
            metadata: { ...metadata, ...metadataOverride },
        }))
        .groupBy(({ time_series }) => time_series.unit)
        .value();
}

function constructGraphData(
    linesPerUnit:LinesPerUnit,
    analysis:Analysis,
):{
    dataPoints:Array<Record<string, number | null>>;
    isQuickAnalysis:boolean;
} {
    const lines = _.flatMap(linesPerUnit);

    // Validate and get data length
    const dataLength = lines.reduce((length:number | null, line) => {
        if(line.time_series?.id) {
            const axis = line?.axis || 'x';
            const measurement = getMeasurementByName(analysis, line.time_series.id);
            const axisIndex = ALL_AXES.indexOf(axis as (typeof ALL_AXES)[number]);

            if(axisIndex !== -1 && measurement?.value[axisIndex]) {
                const currentLength = measurement.value[axisIndex].length;

                if(length === null) {
                    return currentLength;
                } else if(length !== currentLength) {
                    throw new Error(
                        `[constructGraphData]: Inconsistent measurement lengths. Expected ${length}, but found ${currentLength} for line ${line.id}`,
                    );
                }
            }
        }
        return length;
    }, null);

    if(dataLength === null) {
        console.warn('[constructGraphData]: No valid measurements found');
        return {
            dataPoints: [],
            isQuickAnalysis: false,
        };
    }

    // Construct data points
    const dataPoints = Array.from({ length: dataLength }, (_, index) => {
        return lines.reduce((dataPoint:Record<string, number | null>, line) => {
            dataPoint['index'] = index;

            if(line.time_series.id && line.axis) {
                const measurement = getMeasurementByName(analysis, line.time_series.id);
                const axisIndex = ALL_AXES.indexOf(
                    line.axis as (typeof ALL_AXES)[number],
                );

                dataPoint[line.id] =
                    axisIndex !== -1 && measurement?.value[axisIndex]
                        ? measurement.value[axisIndex][index] ?? null
                        : null;
            } else {
                console.warn(
                    `[constructGraphData]: Incomplete data for line ${line.id}:`,
                    line,
                );
                dataPoint[line.id] = null;
            }

            return dataPoint;
        }, {});
    });

    return {
        dataPoints,
        isQuickAnalysis: dataLength <= 10,
    };
}

export const Graph:ImplementationOf<'graph'>
= ({ node, children, values$, currentDevice }) => {
    
    const swingAnalysis = useObservable(
        values$,
        map(v => v.swing),
        distinctUntilChanged()
    );

    const rootClass = currentDevice === 'floor'
        ? ModuleBaseFloorRootCSS
        : ModuleBaseKioskRootCSS.name;

    if(!swingAnalysis || children.length === 0) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
    }

    const lines = parseAndGroupLines(children);

    const selectedAnalysis =
        swingAnalysis.fullAnalysis ?? swingAnalysis.quickAnalysis;

    if(!selectedAnalysis) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
    }

    const data = constructGraphData(lines, selectedAnalysis);
    const segmentation = getSegmentation(selectedAnalysis);

    if(!data || !segmentation) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
    }

    switch(currentDevice) {
        case 'kiosk':
            return (
                <ModuleBaseKiosk title={node.name}>
                    <DynamicGraph
                        data={data}
                        lines={lines}
                        segmentation={segmentation}
                        isInteractive={true}
                        device={'kiosk'}
                    />
                </ModuleBaseKiosk>
            );
        case 'floor':
            return (
                <ModuleBaseFloor title={node.name}>
                    <DynamicGraph
                        data={data}
                        lines={lines}
                        segmentation={segmentation}
                        isInteractive={false}
                        device={'floor'}
                    />
                </ModuleBaseFloor>
            );
        default:
            return <div className={rootClass} key={node.id}></div>;
    }
};
