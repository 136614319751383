import { useNavigate } from 'react-router-dom';
import { typography } from '../../styles/typography.css';
import * as css from './Navigation.css';
import Button from '../ui/Button';
import { ReactNode } from 'react';
import _ from 'lodash';

export type BackNavButton = {
    label:string;
    onClick:() => void|Promise<unknown>;
    icon?:ReactNode;
};

export function BackNavigation({
    navigateTo,
    screenTitle,
    onSave,
    buttons,
}:{
    navigateTo?:string;
    screenTitle:string;
    onSave?:() => void|Promise<unknown>;
    buttons?:BackNavButton[];
}) {
    const navigate = useNavigate();

    const goBack = () => {
        if(navigateTo === 'goBack')
            navigate(-1);
        else
            navigate(navigateTo ?? '/kiosk');
    };

    const saveAndGoBack = () => {
        const promise = onSave?.();
        if(typeof(promise?.then) === 'function')
            promise.then(goBack);
        else
            goBack();
    };

    return (
        <div className={css.backNavWrap}>
            <div className={css.backNav}>
                <div className={css.titleAndButtons}>
                    <p
                        className={typography({
                            size: 'h2',
                            weight: 'bold',
                            color: 'dark',
                        })}
                    >
                        {screenTitle}
                    </p>
                    {_.map(buttons, (button) => (
                        <button
                            className={css.backNavButton}
                            key={button.label}
                            onClick={button.onClick}
                        >
                            {button.label}
                            {button.icon}
                        </button>
                    ))}
                </div>
                <div className={css.buttons}>
                    <Button
                        variant="secondary"
                        onClick={goBack}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={saveAndGoBack}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </div>
    );
}
