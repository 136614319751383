import _ from 'lodash';
import { useState, useEffect, useMemo } from 'react';
import { combineLatest, first, Observable, OperatorFunction } from 'rxjs';


export function useObservable<T>(
    observable$:Observable<T>,
):T;
export function useObservable<A, B>(
    observable$:Observable<A>,
    op1:OperatorFunction<A, B>,
):B;
export function useObservable<A, B, C>(
    observable$:Observable<A>,
    op1:OperatorFunction<A, B>,
    op2:OperatorFunction<B, C>,
):C;
export function useObservable<A, B, C, D>(
    observable$:Observable<A>,
    op1:OperatorFunction<A, B>,
    op2:OperatorFunction<B, C>,
    op3:OperatorFunction<C, D>,
):D;
export function useObservable<A, B, C, D, E>(
    observable$:Observable<A>,
    op1:OperatorFunction<A, B>,
    op2:OperatorFunction<B, C>,
    op3:OperatorFunction<C, D>,
    op4:OperatorFunction<D, E>,
):E;

export function useObservable<T1, T2>(
    observables$:[Observable<T1>, Observable<T2>],
):[T1, T2];
export function useObservable<A1, A2, B>(
    observables$:[Observable<A1>, Observable<A2>],
    op1:OperatorFunction<[A1, A2], B>,
):B;
export function useObservable<A1, A2, B, C>(
    observables$:[Observable<A1>, Observable<A2>],
    op1:OperatorFunction<[A1, A2], B>,
    op2:OperatorFunction<B, C>,
):C;
export function useObservable<A1, A2, B, C, D>(
    observables$:[Observable<A1>, Observable<A2>],
    op1:OperatorFunction<[A1, A2], B>,
    op2:OperatorFunction<B, C>,
    op3:OperatorFunction<C, D>,
):D;
export function useObservable<A1, A2, B, C, D, E>(
    observables$:[Observable<A1>, Observable<A2>],
    op1:OperatorFunction<[A1, A2], B>,
    op2:OperatorFunction<B, C>,
    op3:OperatorFunction<C, D>,
    op4:OperatorFunction<D, E>,
):E;

export function useObservable<T1, T2, T3>(
    observables$:[Observable<T1>, Observable<T2>, Observable<T3>],
):[T1, T2, T3];
export function useObservable<A1, A2, A3, B>(
    observables$:[Observable<A1>, Observable<A2>, Observable<A3>],
    op1:OperatorFunction<[A1, A2, A3], B>,
):B;
export function useObservable<A1, A2, A3, B, C>(
    observables$:[Observable<A1>, Observable<A2>, Observable<A3>],
    op1:OperatorFunction<[A1, A2, A3], B>,
    op2:OperatorFunction<B, C>,
):C;
export function useObservable<A1, A2, A3, B, C, D>(
    observables$:[Observable<A1>, Observable<A2>, Observable<A3>],
    op1:OperatorFunction<[A1, A2, A3], B>,
    op2:OperatorFunction<B, C>,
    op3:OperatorFunction<C, D>,
):D;
export function useObservable<A1, A2, A3, B, C, D, E>(
    observables$:[Observable<A1>, Observable<A2>, Observable<A3>],
    op1:OperatorFunction<[A1, A2, A3], B>,
    op2:OperatorFunction<B, C>,
    op3:OperatorFunction<C, D>,
    op4:OperatorFunction<D, E>,
):E;

export function useObservable(
    observables:Observable<unknown>|(Observable<unknown>[]),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...pipes:OperatorFunction<any, unknown>[]
):unknown {

    const [observableToBeSubscribeTo$, initialValue] = useMemo(
        () => {
            const piped$ = _.reduce(
                pipes,
                (obs$, pipe) => pipe(obs$),
                _.isArray(observables)
                    ? combineLatest(observables)
                    : observables
            );

            let initValue:unknown = []; // by default, the initial value is an empty array
            piped$
                .pipe(first())
                .subscribe(value => initValue = value) // but we'll try to get the first value from the observable to use as the initial value
                .unsubscribe();
            
            return [piped$, initValue];
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [] //? we should never dynamically change the input observables nor the pipes.
    );

    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        const subscription = observableToBeSubscribeTo$.subscribe(newValue => setValue(newValue));
        return () => subscription.unsubscribe();
    }, [observableToBeSubscribeTo$]);

    return value;
}
