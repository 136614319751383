import _ from 'lodash';
import {
    motion,
    useMotionValue,
    useTransform,
    useMotionValueEvent,
    useDragControls,
} from 'framer-motion';
import {
    useCallback,
    useRef,
    useTransition,
    type PointerEvent,
    useEffect,
    useMemo,
} from 'react';

import { useResizeObserver } from '../../utils/hooks/useResizeObserver';
import { useGlobalStore, useSelectedAnalysis } from '../../state/globalStore';
import { CaretLeft } from '../ui/icons/CaretLeft';
import { CaretRight } from '../ui/icons/CaretRight';
import { colorVars } from '../../styles/colors.css';
import { calculateFrameRatios } from '../../utils/calculateFrameRatios';
import { ScrubberHandle } from '../ui/icons/ScrubberHandle';

import * as css from './Scrubber.css';

export default function Scrubber() {
    const [, startTransition] = useTransition();
    const activeAnalysis = useSelectedAnalysis();

    const swingSegmentation = _.values(activeAnalysis?.data.analysis?.segmentation);

    const totalFrames = useMemo(() =>
        activeAnalysis?.data.videos?.[0]?.metadata.totalFrames ?? 999 - 1,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeAnalysis?.id]);

    const actions = useGlobalStore((state) => state.actions);
    const currentPosition = useGlobalStore((state) => state.activePosition|0); // '|0' = cast to int for type safety
    const currentPositionIndex = currentPosition - 1;
    const canPressPrev = Boolean(activeAnalysis) && currentPosition > 1;
    const canPressNext = Boolean(activeAnalysis) && currentPosition < 10;

    // get the width of the scrubber (the playback bar)
    const ref = useRef<HTMLDivElement>(null);
    const { width = 1310 } = useResizeObserver({
        ref,
        box: 'border-box',
    });
    const maxHandleX = width - css.HANDLE_INSET * 2;

    // the x position of the scrubber handle
    const handleX = useMotionValue(0);
    const dragControls = useDragControls();

    const handleScrub = useCallback(
        (currentFrame:number) => {
            if(!activeAnalysis) return;

            let activePosition = 1; // Default to position 1

            // Iterate over swingSegmentation starting from the second element
            for(let i = 1; i < swingSegmentation.length; i++) {
                if(currentFrame < swingSegmentation[i]) {
                    break;
                }

                if(swingSegmentation[i] !== null) {
                    // Don't increment if the position is missing
                    activePosition = i + 1; // Increment position as we pass each threshold
                }
            }

            // If currentFrame is greater than or equal to the last element in swingSegmentation
            if(currentFrame >= swingSegmentation[swingSegmentation.length - 1]) {
                activePosition = swingSegmentation.length;
            }


            startTransition(() => {
                actions.setCurrentFrame(~~currentFrame);
                actions.setActivePosition(activePosition);
            });
            
        },
        [actions, swingSegmentation, activeAnalysis],
    );

    const handlePlaybackProgress = useCallback(
        (scrubberProgress:number) => {
            if(!activeAnalysis) return;

            actions.setScrubberProgress(scrubberProgress);
        },
        [actions, activeAnalysis],
    );
    const scrubFrame = useTransform(handleX, [0, maxHandleX], [1, totalFrames]);
    const scrubRatio = useTransform(handleX, [-css.HANDLE_INSET, maxHandleX + css.HANDLE_INSET], [0, 1]);

    useMotionValueEvent(scrubFrame, 'change', handleScrub);
    useMotionValueEvent(scrubRatio, 'change', handlePlaybackProgress);

    const frameRatios = calculateFrameRatios(swingSegmentation, totalFrames);
    const isQuickAnalysis = !!activeAnalysis?.data.analysis?.isQuickAnalysis;
    const activeAnalysisID = activeAnalysis?.id;

    useEffect(() => {
        handleX.set(0, typeof activeAnalysisID === 'number');
    }, [activeAnalysisID, handleX]);

    const onPointerDown = useCallback(
        (e:PointerEvent<HTMLElement>) => {
            e.preventDefault();

            dragControls.start(e, { snapToCursor: true });

            if(isQuickAnalysis) {
                useGlobalStore.setState(() => ({
                    forceHideSkeleton: true,
                }));
            }
        },
        [isQuickAnalysis, dragControls],
    );

    const onPointerUp = useCallback(() => {
        useGlobalStore.setState(() => ({
            forceHideSkeleton: false,
        }));
    }, []);

    return (
        <>
            <motion.div
                className={css.positioner}
                onPointerDown={onPointerDown}
                onPointerUp={onPointerUp}
            >
                <div
                    className={css.scrubber}
                    ref={ref} // used to obtain the scrubber width
                >
                    <div className={css.scrubberContent}>
                        {_.map(_.values(frameRatios), (val, i) => {
                            // Invalid position
                            if(val === -1) return null;

                            const position = parseInt(_.keys(frameRatios)[i]) + 1;

                            // Mark valid position
                            const isImpact = position === 7;
                            return (
                                <div
                                    key={`${val}-${i}`}
                                    style={{
                                        width: isImpact
                                            ? 2
                                            : 1,
                                        height: '100%',
                                        position: 'absolute',
                                        left: `${val * 100}%`,
                                        background: isImpact
                                            ? colorVars.blue500
                                            : colorVars.blueGray400,
                                    }}
                                />
                            );
                        })}
                    </div>
                    
                    <motion.div
                        className={css.handle}
                        drag="x"
                        dragControls={dragControls}
                        dragConstraints={{
                            left: 0,
                            right: maxHandleX,
                        }}
                        dragElastic={false}
                        dragMomentum={false}
                        dragDirectionLock
                        style={{ x: handleX }}
                    >
                        <ScrubberHandle />
                    </motion.div>
                </div>
                <div className={css.progressMask}>
                    <motion.div
                        className={css.progress}
                        style={{ scaleX: scrubRatio }}
                    />
                </div>
            </motion.div>

            <div className={css.picker}>
                <button
                    className={css.prevPositionButton}
                    disabled={!canPressPrev}
                    onClick={() => {
                        if(!canPressPrev) return;

                        if(frameRatios[currentPositionIndex - 1] === -1) {
                            for(let i = currentPositionIndex - 1; i > 0; i--) {
                                if(frameRatios[i] !== -1) {
                                    handleX.set(frameRatios[i] * maxHandleX);
                                    actions.setActivePosition(i + 1);
                                    return;
                                }
                            }
                        }
                        handleX.set(frameRatios[currentPositionIndex - 1] * maxHandleX);
                        actions.setActivePosition(currentPosition - 1);
                    }}
                >
                    <CaretLeft color={canPressPrev
                        ? colorVars.blueGray600
                        : colorVars.blueGray300
                    } />
                </button>
                <p className={css.currentPositionIndicator}>P{currentPosition}</p>
                <button
                    className={css.nextPositionButton}
                    disabled={!canPressNext}
                    onClick={() => {
                        if(!canPressNext) return;

                        if(handleX.get() < frameRatios[0] * maxHandleX) {
                            handleX.set(frameRatios[0] * maxHandleX);
                            return;
                        }

                        if(frameRatios[currentPositionIndex + 1] === -1) {
                            for(
                                let i = currentPositionIndex + 1;
                                i < _.size(frameRatios);
                                i++
                            ) {
                                if(frameRatios[i] !== -1) {
                                    handleX.set(frameRatios[i] * maxHandleX);
                                    actions.setActivePosition(i + 1);
                                    return;
                                }
                            }
                        }
                        handleX.set(frameRatios[currentPositionIndex + 1] * maxHandleX);
                        actions.setActivePosition(currentPosition + 1);
                    }}
                >
                    <CaretRight color={canPressNext
                        ? colorVars.blueGray600
                        : colorVars.blueGray300
                    } />
                </button>
            </div>
        </>
    );
}
